const selectOptionsTime = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "rgb(37, 40, 54)",
    fontSize: "0.75rem",
    fontWeight: "500",
    padding: "10px 10px 10px 5px",
    backgroundColor: state.isSelected ? "#7020D6" : "white",

    ":active": {
      backgroundColor: state.isSelected ? "white" : "#7020D6",
      color: state.isSelected ? "black" : "white",
    },
    ":hover": {
      backgroundColor: state.isSelected ? "#7020D6" : "#f2f3f8",
      color: state.isSelected ? "white" : "black",
    },
  }),
  control: (base, state) => {
    return {
      ...base,
      borderColor: "transparent",
      borderRadius: "0!important",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "transparent!important",
      },
      "&:active": {
        borderColor: "transparent!important",
      },
      backgroundColor: !state.isDisabled ? "white" : "#e9ecef",
      // cursor: !state.isDisabled ? 'not-allowed' : 'not-allowed',
      padding: "0px 0px",
      //   minWidth: "70px",
      //   maxWidth: "70px",
      width: "38px",
      height: "36px",
      minHeight: "36px",
      borderRadius: "36px",
      color: "rgb(37, 40, 54)",
      fontSize: "0.75rem",
      fontWeight: "500",
      "::placholder": {
        fontWeight: "400!important",
        color: "red!important",
      },
      "&.hasError__control": {
        borderColor: "#f0555d",
      },
    };
  },
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    fontWeight: "500!important",
    color: "#9DA3AF ",
    fontSize: "13px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    // padding: "10px",
    marginTop: "0",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (defaultStyles) => ({
    ...defaultStyles,
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "2",
    "::select": {
      fontWeight: "400!important",
      color: "red!important",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    "::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#EDF2F4",
    },
    "::-webkit-scrollbar": {
      width: "10px",
      backgroundColor: "rgb(248, 248, 248)",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#cfdce2",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export default selectOptionsTime;
