import React, { useState, useEffect } from "react";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import selectOptions from "../select/selectOptions";

import { iva } from "../../utils/iva";

const ArticoloForm = ({ searchServizio, updateArticoli, articolo }) => {
  const [qt, setQt] = useState(1);
  const [codice, setCodice] = useState("");
  const [nome, setNome] = useState(null);
  const [unitaMisura, setUnitaMisura] = useState("");
  const [ivaApplicata, setIvaApplicata] = useState(null);
  const [sconto, setSconto] = useState(0);
  const [prezzo, setPrezzo] = useState("");
  const [descrizione, setDescrizione] = useState("");

  useEffect(() => {
    if (articolo) {
      setValues();
    }
  }, [articolo]);

  const handleServizioChange = (value) => {
    if (value) {
      setNome(value);
      setCodice(value.codice);
      setUnitaMisura(value.unitaMisura);
      setIvaApplicata(value.ivaApplicata);
      setPrezzo(value.prezzo);
      setDescrizione(value.descrizione);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setQt(1);
    setCodice("");
    setNome(null);
    setUnitaMisura("");
    setIvaApplicata(null);
    setSconto(0);
    setPrezzo("");
    setDescrizione("");
  };

  const setValues = () => {
    setNome(articolo.articolo);
    setQt(articolo.qt);
    setCodice(articolo.codice);
    setUnitaMisura(articolo.unitaMisura);
    setIvaApplicata(articolo.ivaApplicata);
    setPrezzo(articolo.prezzo);
    setSconto(articolo.sconto);
    setDescrizione(articolo.descrizione);
  };

  const handleUpdate = () => {
    const nuovArticolo = {
      articolo: nome,
      qt: Number.isInteger(parseInt(qt)) ? qt : 1,
      codice,
      unitaMisura,
      ivaApplicata,
      sconto: Number.isInteger(parseFloat(sconto)) ? parseFloat(sconto) : 0,
      prezzo: !Number.isNaN(parseFloat(prezzo)) ? prezzo : 0,
      descrizione,
    };
    updateArticoli(nuovArticolo);
    resetForm();
  };

  return (
    // <div className="bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-8 rounded-md overflow-hidden">
    <div>
      <div className="flex flex-col md:flex-row w-full justify-around">
        <div className="flex flex-col w-full md:w-1/2 md:pr-4">
          <div className="flex md:justify-between flex-col md:flex-row">
            <div className="w-full md:w-1/4 md:pr-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="qt"
              >
                Qt
              </label>
              <input
                value={qt}
                onChange={(e) => setQt(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="qt"
                placeholder=""
              />
            </div>
            <div className="w-full md:w-3/4 md:pl-2 mt-3 md:mt-0">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="denominazione"
              >
                Articolo
              </label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Seleziona articolo"
                onChange={handleServizioChange}
                filterOption={false}
                isClearable
                loadOptions={searchServizio}
                styles={selectOptions}
                className="select"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.nome}
                menuPortalTarget={document.querySelector("body")}
                value={nome}
                noOptionsMessage={(value) =>
                  value.inputValue.length < 3
                    ? "Inserisci almeno 3 caratteri"
                    : "Nessun risultato"
                }
              />
            </div>
          </div>
          <div className="mt-3 flex justify-between">
            <div className="w-1/2 pr-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="codice"
              >
                Codice
              </label>
              <input
                value={codice}
                onChange={(e) => setCodice(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="codice"
                placeholder=""
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="unitaMisura"
              >
                Unità di misura
              </label>
              <input
                value={unitaMisura}
                onChange={(e) => setUnitaMisura(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="unitaMisura"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 md:pr-4 mt-3 md:mt-0">
          <div>
            <label
              className="text-xs font-semibold leading-none text-gray-800"
              id="ivaApplicata"
            >
              IVA applicata
            </label>
            <Select
              options={iva}
              styles={selectOptions}
              className="select"
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.name}
              noOptionsMessage={() => "Nessun risultato"}
              placeholder="Seleziona iva"
              value={ivaApplicata}
              onChange={(value) => setIvaApplicata(value)}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
          <div className="mt-3 flex justify-between">
            <div className="w-1/2 pr-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="sconto"
              >
                Sconto (%)
              </label>
              <input
                value={sconto}
                onChange={(e) => setSconto(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="sconto"
                placeholder="0"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="prezzo"
              >
                Prezzo
              </label>
              <input
                value={prezzo}
                onChange={(e) => setPrezzo(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="prezzo"
                placeholder="0.00"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <label
          className="text-xs font-semibold leading-none text-gray-800"
          id="descrizione"
        >
          Descrizione
        </label>
        <textarea
          value={descrizione}
          onChange={(e) => setDescrizione(e.target.value)}
          rows="7"
          tabIndex="0"
          className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
          aria-labelledby="descrizione"
          placeholder=""
        ></textarea>
      </div>
      <div className="flex mt-6 justify-end">
        <button
          className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full transition-all ease-in-out duration-300"
          disabled={!nome || qt === "" || !ivaApplicata || prezzo === ""}
          onClick={() => {
            handleUpdate();
          }}
        >
          <p className="text-xs font-medium leading-none text-white">
            {articolo ? "Salva modifiche" : "Aggiungi"}
          </p>
        </button>
      </div>
    </div>
    // </div>
  );
};

export default ArticoloForm;
