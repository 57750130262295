import React, { useState, useEffect } from "react";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { localeIT } from "../../utils/localeIT";

const UsciteForm = ({
  toggleForm,
  addUscita,
  uscita,
  updateUscita,
  //   updateServizio,
  //   isSubmitted,
  //   servizio,
}) => {
  const [titolo, setTitolo] = useState("");
  const [importo, setImporto] = useState("0.00");
  const [data, setData] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [beneficiario, setBeneficiario] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    if (uscita) setFormValues();
  }, [uscita]);

  const hanldeSubmit = async (e) => {
    e.preventDefault();
    const nuovaUscita = {
      titolo,
      data: new Date(data.year, data.month - 1, data.day),
      importo:
        isNaN(importo) || importo === ""
          ? parseFloat(0).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : parseFloat(importo).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
      beneficiario,
      note,
    };
    if (uscita) {
      updateUscita(uscita.id, nuovaUscita);
    } else {
      addUscita(nuovaUscita);
    }
    closeForm();
  };

  const resetForm = () => {
    setTitolo("");
    setBeneficiario("");
    setNote("");
    setData({
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    });
  };

  const closeForm = () => {
    resetForm();
    toggleForm(false);
  };

  const handleChangeDate = (date) => {
    setData(date);
  };

  const setFormValues = () => {
    setTitolo(uscita.titolo);
    setNote(uscita.note);
    setImporto(uscita.importo);
    setBeneficiario(uscita.beneficiario);
    const dataUscita = uscita.data.toDate();
    const day = dataUscita.getDate();
    const month = dataUscita.getMonth() + 1;
    const year = dataUscita.getFullYear();
    const d = { day: day, month: month, year: year };
    setData(d);
  };

  //   useEffect(() => {
  //     if (isSubmitted) closeForm();
  //   }, [isSubmitted]);

  return (
    <div className="bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-8 rounded-md mb-6">
      <div className="form-header flex items-center justify-between">
        <div className="header-title text-base font-semibold text-gray-800">
          {uscita ? "Modifica uscita" : " Nuova uscita"}
        </div>
        <button
          onClick={() => closeForm()}
          className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
        >
          <p className="text-xs font-medium leading-none text-white">Chiudi</p>
        </button>
      </div>
      <form action="" className="mt-8" onSubmit={hanldeSubmit}>
        <div className="flex flex-col lg:flex-row w-full justify-around">
          <div className="flex flex-col w-full lg:w-1/2 lg:pr-4">
            <div className="flex justify-between flex-col md:flex-row">
              <div className="w-full lg:w-1/2 md:pr-2 pr-0">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="titolo"
                >
                  Titolo
                </label>
                <input
                  autoFocus
                  id="nome"
                  value={titolo}
                  onChange={(e) => setTitolo(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="titolo"
                  placeholder=""
                />
              </div>
              <div className="w-full lg:w-1/2 md:pl-2 pl-0 mt-3 md:mt-0">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="importo"
                >
                  Importo
                </label>
                <input
                  value={importo}
                  onChange={(e) => setImporto(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="importo"
                  placeholder=""
                />
              </div>
            </div>
            <div className="mt-3 flex justify-between flex-col md:flex-row">
              <div className="w-full lg:w-1/2 md:pr-2 pr-0 flex flex-col mt-2">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="data"
                >
                  Data
                </label>
                <DatePicker
                  value={data}
                  onChange={handleChangeDate}
                  inputPlaceholder="Seleziona giorno"
                  shouldHighlightWeekends
                  formatInputText={() => {
                    if (data)
                      return (
                        ("0" + data.day).slice(-2) +
                        "-" +
                        ("0" + data.month).slice(-2) +
                        "-" +
                        data.year
                      );
                    return null;
                  }}
                  locale={localeIT}
                  inputClassName="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-6 text-gray-800 !text-left"
                  colorPrimary="rgb(76 29 149)"
                />
              </div>
              <div className="w-full lg:w-1/2 md:pl-2 pl-0 mt-3 md:mt-0">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="beneficiario"
                >
                  Beneficiario
                </label>
                <input
                  value={beneficiario}
                  onChange={(e) => setBeneficiario(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="beneficiario"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-1/2 lg:pl-4 mt-3 lg:mt-0">
            <div className="">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="note"
              >
                Note
              </label>
              <textarea
                rows="7"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                tabIndex="0"
                className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="descrizione"
                placeholder=""
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex mt-6 justify-end">
          <button
            disabled={titolo === ""}
            className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">Salva</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default UsciteForm;
