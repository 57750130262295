import React from "react";

import Loading from "./../loading/Loading";

import { BiEdit, BiTrashAlt } from "react-icons/bi";

const UsciteTable = ({
  uscite,
  isLoadingTable,
  setUscitaActive,
  formOpened,
  deleteUscita,
}) => {
  const dateFormat = (date) => {
    const d = date.toDate();
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return `${("0" + day).slice(-2)}-${("0" + month).slice(-2)}-${year}`;
  };

  const totaleUscita = (uscita) => {
    return parseFloat(uscita.importo).toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const totaleUscite = () => {
    return parseFloat(
      uscite.reduce((x, usc) => x + parseFloat(usc.importo), 0)
    ).toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // .toLocaleString("it-IT",{minimumFractionDigits: 2} )

  return (
    <div className="w-full">
      <div className="relative bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-hidden">
        {isLoadingTable && <Loading />}
        <table className="w-full whitespace-nowrap mytable">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-violet-800">
              <th className="font-semibold text-left pl-4">#</th>
              <th className="font-semibold text-left pl-4">Titolo</th>
              <th className="font-semibold text-left pl-24">Beneficiario</th>
              <th className="font-semibold text-left pl-24">Data</th>
              <th className="font-semibold text-right pl-12">Importo</th>
              <th className="font-semibold text-right pl-4">Azioni</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {uscite?.map((uscita, i) => (
              <tr
                className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-slate-100 border-b border-t border-gray-100 relative"
                key={i}
              >
                <td className="pl-4 font-semibold text-orange-500">{i + 1}</td>
                <td>
                  <div className="flex items-center">
                    <div className="pl-4">
                      <p className="font-semibold">{uscita.titolo}</p>
                      <p className="text-xs leading-3 text-gray-600 pt-2">
                        {uscita.note ? (
                          uscita.note
                        ) : (
                          <span className="text-slate-300">Nessuna nota</span>
                        )}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="pl-24">
                  {uscita.beneficiario ? (
                    uscita.beneficiario
                  ) : (
                    <span className="text-slate-300">Nessun beneficiario</span>
                  )}
                </td>
                <td className="pl-24">{dateFormat(uscita.data)}</td>
                <td className="pl-12 text-orange-500 font-bold text-right">
                  {totaleUscita(uscita)}&nbsp;&euro;
                </td>
                <td className="pl-4">
                  <div className="flex items-center justify-end">
                    <div className="group">
                      <button
                        onClick={() => setUscitaActive(uscita)}
                        className="p-1 mx-1 ml-0 group-hover:bg-orange-400 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                      </button>
                    </div>
                    <div className="group ">
                      <button
                        disabled={formOpened}
                        onClick={() => deleteUscita(uscita)}
                        className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            <tr
              className={`h-16 text-sm leading-none ${
                uscite.length > 0 ? "text-violet-800" : "text-orange-500"
              } bg-white hover:bg-slate-100 border-b border-t border-gray-100`}
            >
              <td className="font-semibold pl-4" colSpan={4}>
                {uscite.length}
                {uscite.length === 1 ? " uscita" : " uscite"}
              </td>
              <td className="font-semibold text-right text-lg">
                {totaleUscite()}&nbsp;&euro;&nbsp;
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsciteTable;
