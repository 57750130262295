import React, { useState, useEffect } from "react";

import { BiChevronRight, BiChevronLeft } from "react-icons/bi";

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

const days = ["L", "M", "M", "G", "V", "S", "D"];

const DatePicker = ({ value, onChange, events, getTodosInMonth }) => {
  const today = new Date();
  const [numberOfDaysInMonth, setNumberOfDaysInMonth] = useState(
    new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate()
  );

  const [dayOfWeekFirstDayInMonth, setDayOfWeekFirstDayInMonth] = useState(
    new Date(value.getFullYear(), value.getMonth(), 1).getDay()
  );

  const [selectedDay, setSelectedDay] = useState(value);
  const [currentView, setCurrentView] = useState(value);

  const [view, setView] = useState("calendar");

  // useEffect(() => {
  //   setSelectedDay(
  //     new Date(value.getFullYear(), value.getMonth(), value.getDate())
  //   );
  // }, [value]);

  useEffect(() => {
    setNumberOfDaysInMonth(
      new Date(
        selectedDay.getFullYear(),
        selectedDay.getMonth() + 1,
        0
      ).getDate()
    );
    setDayOfWeekFirstDayInMonth(
      new Date(
        selectedDay.getFullYear(),
        selectedDay.getMonth(),
        1
      ).getDay() === 0
        ? 7
        : new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            1
          ).getDay()
    );
    console.log(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    setNumberOfDaysInMonth(
      new Date(
        currentView.getFullYear(),
        currentView.getMonth() + 1,
        0
      ).getDate()
    );
    setDayOfWeekFirstDayInMonth(
      new Date(
        currentView.getFullYear(),
        currentView.getMonth(),
        1
      ).getDay() === 0
        ? 7
        : new Date(
            currentView.getFullYear(),
            currentView.getMonth(),
            1
          ).getDay()
    );
  }, [currentView]);

  const handleChangeDay = (date) => {
    onChange(date);
    setSelectedDay(date);
    setCurrentView(date);
  };

  const handleChangeMonth = (value) => {
    const date = new Date(currentView.getFullYear(), currentView.getMonth(), 1);
    switch (value) {
      case 0:
        date.setMonth(date.getMonth() - 1, date.getDate());
        setCurrentView(date);
        break;
      case 1:
        date.setMonth(date.getMonth() + 1, date.getDate());
        setCurrentView(date);
        break;
      default:
        break;
    }
    getTodosInMonth(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const changeView = (value) => {
    if (value === view) {
      setView("calendar");
    } else {
      setView(value);
    }
    if (value === "years" && value !== view) {
      setTimeout(() => {
        const element = document.getElementById(currentView.getFullYear());
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const changeMonth = (month) => {
    setCurrentView(new Date(currentView.getFullYear(), month, 1));
    setView("calendar");
    getTodosInMonth(currentView.getFullYear(), month, 1);
  };

  const changeYear = (year) => {
    setCurrentView(new Date(year, currentView.getMonth(), 1));
    setView("calendar");
    getTodosInMonth(year, currentView.getMonth(), 1);
  };

  return (
    <div className="container text-gray-600 pb-9 min-h-[350px]">
      <div
        className={`header flex ${
          view !== "calendar" ? "justify-center" : "justify-between"
        } items-center mb-2`}
      >
        {view === "calendar" && (
          <button
            className="prevMonth w-[30px] h-[30px] flex justify-start items-center"
            onClick={() => handleChangeMonth(0)}
          >
            <BiChevronLeft className="text-2xl" />
          </button>
        )}

        <div className="flex text-[16px]">
          {(view === "calendar" || view === "months") && (
            <div
              className="mese mr-1 px-3 py-1 rounded hover:bg-slate-200 cursor-pointer"
              onClick={() => changeView("months")}
            >
              {months[currentView.getMonth()]}
            </div>
          )}

          {(view === "calendar" || view === "years") && (
            <div
              className="anno ml-1 px-3 py-1 rounded hover:bg-slate-200 cursor-pointer"
              onClick={() => changeView("years")}
            >
              {currentView.getFullYear()}
            </div>
          )}
        </div>
        {view === "calendar" && (
          <button
            className="nextMonth w-[30px] h-[30px] flex justify-end items-center"
            onClick={() => handleChangeMonth(1)}
          >
            <BiChevronRight className="text-2xl" />
          </button>
        )}
      </div>
      <div className="viewContainer">
        {view === "calendar" && (
          <div className="calendarContainer">
            <div className="daysOfWeek flex mb-1">
              {days.map((obj, i) => (
                <div
                  className="w-[14.28571428571429%]  h-[33px] flex items-center justify-center text-xs text-slate-300"
                  key={i}
                >
                  {obj}
                </div>
              ))}
            </div>
            <div className="calendar flex flex-wrap gap-y-1">
              {[
                ...Array(numberOfDaysInMonth + dayOfWeekFirstDayInMonth - 1),
              ].map((e, i) => {
                const date = new Date(
                  currentView.getFullYear(),
                  currentView.getMonth(),
                  i - dayOfWeekFirstDayInMonth + 2
                );
                if (i < dayOfWeekFirstDayInMonth - 1) {
                  return (
                    <div
                      key={i}
                      className="opacity-0 w-[14.28571428571429%]  h-[33px]"
                    ></div>
                  );
                } else {
                  return (
                    <div
                      key={i}
                      onClick={() => handleChangeDay(date)}
                      className={`w-[14.28571428571429%] flex items-center justify-center h-[33px] rounded-full relative ${
                        date.getDay() === 0
                          ? date.getTime() === selectedDay.getTime()
                            ? "!white"
                            : "!text-red-500"
                          : ""
                      } ${
                        date.getTime() !== selectedDay.getTime()
                          ? "hover:!bg-slate-200"
                          : ""
                      } cursor-pointer`}
                      style={{
                        flex: "0 0 14.28571428571429%",
                        backgroundColor:
                          date.getTime() === selectedDay.getTime()
                            ? "rgb(76 29 149)"
                            : "white",
                        color:
                          date.getTime() === selectedDay.getTime()
                            ? "white"
                            : "black",
                        fontWeight:
                          today.getFullYear() === date.getFullYear() &&
                          today.getMonth() === date.getMonth() &&
                          today.getDate() === date.getDate()
                            ? "600"
                            : "",
                      }}
                    >
                      {i - dayOfWeekFirstDayInMonth + 2}
                      {today.getFullYear() === date.getFullYear() &&
                        today.getMonth() === date.getMonth() &&
                        today.getDate() === date.getDate() &&
                        date.getTime() !== selectedDay.getTime() && (
                          <div className="absolute w-[8px] h-[1px] bg-gray-600 top-[5px] left-[calc(50% - 4px)]"></div>
                        )}
                      <div className="absolute flex items-center justify-center bottom-[4px] gap-[2px]">
                        {events.some(
                          (event) =>
                            event["data"].toDate().getTime() ===
                              date.getTime() && event["stato"] === "todo"
                        ) && (
                          <div className=" w-[4px] h-[4px] bg-orange-500 rounded-full"></div>
                        )}
                        {events.some(
                          (event) =>
                            event["data"].toDate().getTime() ===
                              date.getTime() && event["stato"] === "progress"
                        ) && (
                          <div className=" w-[4px] h-[4px] bg-violet-500 rounded-full"></div>
                        )}
                        {events.some(
                          (event) =>
                            event["data"].toDate().getTime() ===
                              date.getTime() && event["stato"] === "completed"
                        ) && (
                          <div className=" w-[4px] h-[4px] bg-emerald-500 rounded-full"></div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
        {view === "months" && (
          <div className="months flex flex-wrap gap-y-8 gap-x-1 text-xs">
            {months.map((obj, i) => (
              <div
                style={{
                  flex: "0 0 31.33333333%",
                  backgroundColor:
                    i === currentView.getMonth() ? "rgb(76 29 149)" : "",
                  color: i === currentView.getMonth() ? "white" : "inherit",
                }}
                className={`flex items-center justify-center py-2 rounded cursor-pointer ${
                  i !== currentView.getMonth() ? "hover:!bg-slate-200" : ""
                }`}
                key={i}
                onClick={() => changeMonth(i)}
              >
                {obj}
              </div>
            ))}
          </div>
        )}
        {view === "years" && (
          <div
            className="years flex flex-wrap gap-y-8 gap-x-1 overflow-auto max-h-[260px] scrollbar-hide"
            id="years"
          >
            {[...Array(101)].map((obj, i) => {
              const year = today.getFullYear() - 50 + i;
              return (
                <div
                  id={year}
                  style={{
                    flex: "0 0 23.33333333%",
                    backgroundColor:
                      year === currentView.getFullYear()
                        ? "rgb(76 29 149)"
                        : "",
                    color:
                      year === currentView.getFullYear() ? "white" : "inherit",
                  }}
                  className={`flex items-center justify-center py-2 rounded cursor-pointer ${
                    year !== currentView.getFullYear()
                      ? "hover:!bg-slate-200"
                      : ""
                  }`}
                  key={i}
                  onClick={() => changeYear(year)}
                >
                  {today.getFullYear() - 50 + i}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
