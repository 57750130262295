export const iva = [
  { value: "22-", name: "22%" },
  { value: "21-", name: "21%" },
  { value: "20-", name: "20%" },
  { value: "10-", name: "10%" },
  { value: "4-", name: "4%" },
  { value: "0-", name: "0%" },
  { value: "0-regime", name: "0% - Regime dei minimi" },
  { value: "0-fuori", name: "0% - Fuori campo IVA" },
  {
    value: "0-Oper. non soggetta, art.7 ter",
    name: "0% - Oper. non soggetta, art.7 ter",
  },
  {
    value: "0-inversione",
    name: "0% - Inversione contabile, art.7 ter",
  },
  { value: "0-Non Imponibile", name: "0% - Non Imponibile" },
  { value: "0-Non Imp. Art.8", name: "0% - Non Imp. Art.8" },
  { value: "0-Non Imp. Art.9 1C", name: "0% - Non Imp. Art.9 1C" },
  {
    value: "0- Non Imp. Art.14 Legge 537/93",
    name: "0% - Non Imp. Art.14 Legge 537/93",
  },
  {
    value: "0-Non Imp. Art.41 D.P.R. 331/93",
    name: "0% - Non Imp. Art.41 D.P.R. 331/93",
  },
  {
    value: "0-Non Imp. Art.72, D.P.R. 633/72",
    name: "0% - Non Imp. Art.72, D.P.R. 633/72",
  },
  {
    value: "0-Non Imp. Art.74 quotidiani/libri",
    name: "0% - Non Imp. Art.74 quotidiani/libri",
  },
  { value: "0-Escluso Art.10", name: "0% - Escluso Art.10" },
  {
    value: "0-Escluso Art.13 5C DPR 633/72",
    name: "0% - Escluso Art.13 5C DPR 633/72",
  },
  { value: "0", name: "0% - Escluso Art.15" },
  {
    value: "0-Escluso Art.74 ter D.P.R. 633/72",
    name: "0% - Escluso Art.74 ter D.P.R. 633/72",
  },
  { value: "0-Escluso Art.10 comma 1", name: "0% - Escluso Art.10 comma 1" },
  { value: "0-Escluso Art.10 comma 20", name: "0% - Escluso Art.10 comma 20" },
  { value: "0-Non Imp. Art.9", name: "0% - Non Imp. Art.9" },
  {
    value: "0-Escluso Art.10 n.27 D.P.R 633/72",
    name: "0% - Escluso Art.10 n.27 D.P.R 633/72",
  },
  { value: "8-", name: "8%" },
  {
    value: "0-Regime del margine art.36 41/95",
    name: "0% - Regime del margine art.36 41/95",
  },
  {
    value: "0-Escluso Art.3 comma 4 D.P.R 633/72",
    name: "0% - Escluso Art.3 comma 4 D.P.R 633/72",
  },
  {
    value: "0-Escluso Art.15/1c D.P.R 633/72",
    name: "0% - Escluso Art.15/1c D.P.R 633/72",
  },
  {
    value: "0-Non imp. Art.8/c D.P.R. 633/72",
    name: "0% - Non imp. Art.8/c D.P.R. 633/72",
  },
  { value: "0-Non Imp. Art.7 ter", name: "0% - Non Imp. Art.7 ter" },
  {
    value: "0-Escluso Art.7 D.P.R 633/72",
    name: "0% - Escluso Art.7 D.P.R 633/72",
  },
  {
    value: "22-Esigibilita differita Art. 6 comma 5 D.P.R 633/72",
    name: "22% - Esigibilita differita Art. 6 comma 5 D.P.R 633/72",
  },
  { value: "0-Escluso Art.10 comma 9", name: "0% - Escluso Art.10 comma 9" },
  {
    value: "0-Non imp. Art.7 quater DPR 633/72",
    name: "0% - Non imp. Art.7 quater DPR 633/72",
  },
  { value: "0-Non Imp. Art.8 comma 1A", name: "0% - Non Imp. Art.8 comma 1A" },
  { value: "23", name: "23%" },
  { value: "24", name: "24%" },
  {
    value: "0-Fuori Campo IVA Art.7 ter D.P.R 633/72",
    name: "0% - Fuori Campo IVA Art.7 ter D.P.R 633/72",
  },
  {
    value: "0-Non Imp. Art.10 n.18 DPR 633/72",
    name: "0% - Non Imp. Art.10 n.18 DPR 633/72",
  },
  {
    value: "0-Esente Art.10 DPR 633/72",
    name: "0% - Esente Art.10 DPR 633/72",
  },
  {
    value: "0-Non imp. art.40 D.L. 427/93",
    name: "0% - Non imp. art.40 D.L. 427/93",
  },
  {
    value: "0-Non imp. art.41 D.L. 427/93",
    name: "0% - Non imp. art.41 D.L. 427/93",
  },
  {
    value: "0-Non imp. art.8 DPR 633/72",
    name: "0% - Non imp. art.8 DPR 633/72",
  },
  {
    value: "0-Non imp. art.9 DPR 633/72",
    name: "0% - Non imp. art.9 DPR 633/72",
  },
  { value: "0-Regime minimi 2015", name: "0% - Regime minimi 2015" },
  { value: "5-", name: "5%" },
  { value: "0-Non soggetta IVA", name: "0% - Non soggetta IVA" },
  {
    value: "0-R.C. art. 74/7-8 rottami e metalli ferrosi e non",
    name: "0% - R.C. art. 74/7-8 rottami e metalli ferrosi e non",
  },
  {
    value: "0-R.C. art. 17/5 materiale oro e argento",
    name: "0% - R.C. art. 17/5 materiale oro e argento",
  },
  {
    value: "0-R.C. art. 17/6/a settore edile subappalto",
    name: "0% - R.C. art. 17/6/a settore edile subappalto",
  },
  {
    value: "0-R.C. art. 17/6/a-bis fabbricati",
    name: "0% - R.C. art. 17/6/a-bis fabbricati",
  },
  {
    value: "0-R.C. art. 17/6/b telefoni cellulari",
    name: "0% - R.C. art. 17/6/b telefoni cellulari",
  },
  {
    value: "0-R.C. art. 17/6/c prodotti elettronici",
    name: "0% - R.C. art. 17/6/c prodotti elettronici",
  },
  {
    value: "0-R.C. art. 17/6/a-ter servizi comparto edile e settori connessi",
    name: "0% - R.C. art. 17/6/a-ter servizi comparto edile e settori connessi",
  },
  {
    value: "0-R.C. art. 17/6/d-bis,d-ter,d-quater gas/energia elettrica",
    name: "0% - R.C. art. 17/6/d-bis,d-ter,d-quater gas/energia elettrica",
  },
  {
    value: "0-Non imp. art.71 DPR 633/72 (Vaticano)",
    name: "0% - Non imp. art.71 DPR 633/72 (Vaticano)",
  },
  {
    value: "0-Non imp. art.71 DPR 633/72 (RSM)",
    name: "0% - Non imp. art.71 DPR 633/72 (RSM)",
  },
  { value: "0-Contribuenti forfettari", name: "0% - Contribuenti forfettari" },
];
