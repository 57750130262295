import "./App.css";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { YearContextProvider } from "./context/YearContext";
import { Routes, Route } from "react-router-dom";
import Clienti from "./pages/Clienti";
import Fatture from "./pages/Fatture";
import Impostazioni from "./pages/Impostazioni";
import Servizi from "./pages/Servizi";
import PageLayout from "./PageLayout";
import Login from "./pages/Login";
import FattureForm from "./components/fatture/FattureForm";
import Cliente from "./pages/Cliente";
import Agenda from "./pages/Agenda";
import Task from "./pages/Task";
import Uscite from "./pages/Uscite";
import Home from "./pages/Home";
import PageLayoutCopy from "./PageLayoutCopy";
import { TodosContextProvider } from "./context/TodosContext";
import { EventsContextProvider } from "./context/EventsContext";
import AgendaCopy from "./pages/AgendaCopy";

function App() {
  return (
    <UserAuthContextProvider>
      <YearContextProvider>
        <TodosContextProvider>
          <EventsContextProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/" element={<PageLayoutCopy />}>
                <Route path="/clienti" element={<Clienti />} />
                <Route path="/clienti/:id" element={<Cliente />} />
                <Route path="/fatture" element={<Fatture />} />
                <Route path="/nuova-fattura" element={<FattureForm />} />
                <Route path="/nuova-fattura/:id" element={<FattureForm />} />
                <Route path="/impostazioni" element={<Impostazioni />} />
                <Route path="/servizi" element={<Servizi />} />
                <Route path="/agenda" element={<AgendaCopy />} />
                <Route path="/task" element={<Task />} />
                <Route path="/uscite" element={<Uscite />} />
                <Route path="/situazione" element={<Home />} />
                {/* <Route path="/agendac" element={<AgendaCopy />} /> */}
              </Route>
            </Routes>
          </EventsContextProvider>
        </TodosContextProvider>
      </YearContextProvider>
    </UserAuthContextProvider>
  );
}

export default App;
