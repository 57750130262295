import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  doc,
  limit,
  startAfter,
} from "firebase/firestore";

const serviziCollectionRef = collection(db, "servizi");

class ServiziDataService {
  addServizio = (nuovoServizio) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovoServizio.user = userLogged;
    return addDoc(serviziCollectionRef, nuovoServizio);
  };

  updateServizio = (id, updateServizio) => {
    const servizioDoc = doc(db, "servizi", id);
    return updateDoc(servizioDoc, updateServizio);
  };

  deleteServizio = (id) => {
    const servizioDoc = doc(db, "servizi", id);
    return deleteDoc(servizioDoc);
  };

  getSize = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(serviziCollectionRef, where("user", "==", userLogged));
    return getDocs(q);
  };

  fetchServizi = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      serviziCollectionRef,
      where("user", "==", userLogged),
      orderBy("nome_sort", "asc")
    );
    return getDocs(q);
  };

  getAllServizi = (offset) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      serviziCollectionRef,
      where("user", "==", userLogged),
      orderBy("nome_sort", "asc"),
      limit(offset)
    );
    return getDocs(q);
  };

  fetchNext = (lastDoc, offset) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      serviziCollectionRef,
      where("user", "==", userLogged),
      orderBy("nome_sort", "asc"),
      limit(offset),
      startAfter(lastDoc)
    );
    return getDocs(q);
  };

  fetchPrev = (firstDoc, offset) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      serviziCollectionRef,
      where("user", "==", userLogged),
      orderBy("nome_sort", "desc"),
      limit(offset),
      startAfter(firstDoc)
    );
    return getDocs(q);
  };

  search = (terms) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      serviziCollectionRef,
      where("user", "==", userLogged),
      where("nome_sort", ">=", terms),
      where("nome_sort", "<=", terms + "\uf8ff"),
      orderBy("nome_sort", "desc")
    );
    return getDocs(q);
  };

  //   getBook = id => {
  //     const bookDoc = doc(db, "books", id);
  //     return getDoc(bookDoc);
  //   };
}

export default new ServiziDataService();
