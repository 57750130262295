import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { BiGridAlt, BiArrowFromRight, BiArrowFromLeft } from "react-icons/bi";

import Select from "react-select";
import selectOptions from "../components/select/selectOptions";

import { useYear } from "../context/YearContext";

import { years } from "../utils/years";

const NavBarCopy = ({ opened, toggle, isMobile }) => {
  const [anno, setAnno] = useState();

  const { changeYear } = useYear();

  const handleChange = (value) => {
    localStorage.setItem("currentAnno", value.name);
    setAnno(value);
    changeYear(value);
  };

  useEffect(() => {
    const anno = localStorage.getItem("currentAnno");
    const y = new Date().getFullYear();
    const current = anno ? anno : y;
    handleChange({ value: current + "", name: current + "" });
  }, []);

  return (
    <nav
      className={`fixed top-0 bg-white w-full h-[50px] shadow lg:px-7 px-4 flex items-center justify-between z-10 transition-all ease-in-out duration-300 ${
        opened
          ? "w-full lg:w-[calc(100vw-250px)] ml-0 lg:ml-[250px]"
          : "w-full lg:w-[calc(100vw-70px)] ml-0 lg:ml-[70px]"
      }`}
    >
      <button
        className="text-violet-800 disabled:text-violet-200 text-xl p-3 pl-0 sm:flex-1"
        onClick={toggle}
        // disabled={opened}
      >
        {isMobile ? (
          <BiGridAlt />
        ) : opened ? (
          <BiArrowFromRight />
        ) : (
          <BiArrowFromLeft />
        )}
      </button>
      {/* <div className="flex items-center"> */}
      <div className="sm:mr-3 sm:ml-0 ml-6">
        <Select
          options={years}
          styles={selectOptions}
          isSearchable={false}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.name}
          noOptionsMessage={() => "Nessun risultato"}
          value={years.filter((i) => i.name == anno?.name)}
          onChange={handleChange}
          menuPortalTarget={document.querySelector("body")}
        />
      </div>
      <Link className="logo flex items-center" to="/situazione">
        <div className="pl-1 font-black text-xl text-gray-800">xion</div>
        <Logo className="w-[35px] h-[35px]" />{" "}
      </Link>
      {years.map((i) => {})}
      {/* </div> */}
    </nav>
  );
};

export default NavBarCopy;
