import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDyZEJpU06Ipahggjc9TbCAPhCn1JVTyl0",
  authDomain: "gestione-clienti-ec20f.firebaseapp.com",
  projectId: "gestione-clienti-ec20f",
  storageBucket: "gestione-clienti-ec20f.appspot.com",
  messagingSenderId: "524335826102",
  appId: "1:524335826102:web:f8e1feeb5c59a8c4d5d217",
  measurementId: "G-JRVPS14VXX",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
