import { createContext, useState, useContext } from "react";

const todosContext = createContext();

export function TodosContextProvider({ children }) {
  const [todos, setTodos] = useState([]);

  function fetchTodos(todos) {
    setTodos(todos);
  }

  return (
    <todosContext.Provider value={{ todos, fetchTodos }}>
      {children}
    </todosContext.Provider>
  );
}

export function useTodos() {
  return useContext(todosContext);
}
