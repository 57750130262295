import React from "react";

import Loading from "./../loading/Loading";

// import { PDFViewer } from "@react-pdf/renderer";
import DropDown from "../dropdown/DropDown";
// import SalvaFatturaPDF from "./../pdf/SalvaFatturaPDF";

const FattureTable = ({
  fatture,
  isLoadingTable,
  deleteFattura,
  /*TOGLIERE*/
  profilo,
  togglePagamentoModal,
}) => {
  const dateFormat = (date) => {
    const d = date.toDate();
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return `${("0" + day).slice(-2)}-${("0" + month).slice(-2)}-${year}`;
  };

  const totaleFattura = (fattura) => {
    let tot = 0;
    for (const articolo of fattura.articoli) {
      const qt = articolo.qt;
      const prezzo = parseFloat(articolo.prezzo) * qt;
      const percent = prezzo * (parseFloat(articolo.sconto) / 100);
      tot += prezzo - percent;
    }
    return tot.toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const totaleFatture = () => {
    return parseFloat(
      fatture
        .map((fat) =>
          fat.articoli.reduce(
            (s, p) =>
              s +
              (parseFloat(p.prezzo) * p.qt -
                (parseFloat(p.prezzo) * p.qt * parseFloat(p.sconto)) / 100),
            0
          )
        )
        .reduce((s, x) => s + x, 0)
    ).toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const totaleIva = () => {
    return parseFloat(
      fatture
        .map((fat) =>
          fat.articoli.reduce(
            (s, p) =>
              s +
              ((parseFloat(p.prezzo) * p.qt -
                (parseFloat(p.prezzo) * p.qt * parseFloat(p.sconto)) / 100 +
                (fat.rivalsa
                  ? (parseFloat(p.prezzo) * p.qt -
                      (parseFloat(p.prezzo) * p.qt * parseFloat(p.sconto)) /
                        100) *
                    0.04
                  : 0)) *
                parseInt(p.ivaApplicata.value.split("-")[0])) /
                100,
            0
          )
        )
        .reduce((s, x) => s + x, 0)
    ).toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const findIva = () => {
    for (const f of fatture) {
      for (const a of f.articoli) {
        if (
          parseFloat(a.prezzo) > 0 &&
          parseInt(a.ivaApplicata.value.split("-")[0]) > 0
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const findIvaFattura = (f) => {
    for (const a of f.articoli) {
      if (
        parseFloat(a.prezzo) > 0 &&
        parseInt(a.ivaApplicata.value.split("-")[0]) > 0
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="w-full">
      {/* <div className="w-[1024px]">
        <PDFViewer className="w-full h-[1024px]">
          <SalvaFatturaPDF fattura={fatture[0]} profilo={profilo} />
        </PDFViewer>
      </div> */}
      <div className="relative bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
        {isLoadingTable && <Loading />}
        <table className="w-full whitespace-nowrap mytable">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-violet-800">
              <th className="font-semibold text-left pl-4">#</th>
              <th className="font-semibold text-left pl-4">Cliente</th>
              <th className="font-semibold text-left pl-24">Oggetto</th>
              <th className="font-semibold text-left pl-24">Data</th>
              <th className="font-semibold text-right pl-12">Importo</th>
              <th className="font-semibold text-right pl-4 pr-4 lg:pr-0">
                Azioni
              </th>
            </tr>
          </thead>
          <tbody className="w-full">
            {fatture?.map((fattura, i) => (
              <tr
                className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-slate-100 border-b border-t border-gray-100 relative"
                key={i}
              >
                <td className="pl-4 font-semibold text-orange-500">
                  {fattura.numero}
                </td>
                <td>
                  <div className="flex items-center">
                    <div className="pl-4">
                      <p className="font-semibold">
                        {fattura.cliente.denominazione}
                      </p>
                      <p className="text-xs leading-3 text-gray-600 pt-2">
                        {fattura.cliente.referente ? (
                          fattura.cliente.referente
                        ) : (
                          <span className="text-slate-300">
                            Nessun referente
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="pl-24">
                  {fattura.noteInterne ? (
                    fattura.noteInterne
                  ) : (
                    <span className="text-slate-300">Nessun oggetto</span>
                  )}
                </td>
                <td className="pl-24">{dateFormat(fattura.data)}</td>
                <td className="pl-12 text-orange-500 font-bold text-right">
                  {totaleFattura(fattura)}&nbsp;&euro;
                  {findIvaFattura(fattura) && (
                    <span className="text-[10px] ml-2 bg-violet-800 text-white rounded px-2 py-1 font-medium">
                      + IVA
                    </span>
                  )}
                </td>
                <td className="pl-4 pr-4 lg:pr-0">
                  <div className="flex justify-end">
                    <DropDown
                      item={fattura}
                      deleteFattura={deleteFattura}
                      profilo={profilo}
                      togglePagamentoModal={togglePagamentoModal}
                    />
                  </div>
                </td>
              </tr>
            ))}
            <tr
              className={`h-16 text-sm leading-none ${
                fatture.length > 0 ? "text-violet-800" : "text-orange-500"
              } bg-white hover:bg-slate-100 border-b border-t border-gray-100`}
            >
              <td className="font-semibold pl-4" colSpan={4}>
                {fatture.length}
                {fatture.length === 1 ? " documento" : " documenti"}
              </td>
              <td className="font-semibold text-right text-lg">
                {totaleFatture()}&nbsp;&euro;&nbsp;
                {findIva() && (
                  <span className="text-[10px] ml-2 bg-violet-800 text-white rounded px-2 py-1 font-medium">
                    + {totaleIva()}&nbsp;&euro;&nbsp;IVA
                  </span>
                )}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FattureTable;
