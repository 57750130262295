import React, { useState, useEffect } from "react";

const ClientiForm = ({
  toggleForm,
  addCliente,
  updateCliente,
  isSubmitted,
  cliente,
}) => {
  const [denominazione, setDenominazione] = useState("");
  const [referente, setReferente] = useState("");
  const [piva, setPiva] = useState("");
  const [codfis, setCodFis] = useState("");

  const [paese, setPaese] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [citta, setCitta] = useState("");
  const [cap, setCap] = useState("");
  const [provincia, setProvincia] = useState("");

  const [codiceInterno, setCodiceInterno] = useState("");
  const [email, setEmail] = useState("");
  const [pec, setPec] = useState("");
  const [telefono, setTelefono] = useState("");

  const [isTouched, setIsTouched] = useState(false);

  const denBlur = () => {
    setIsTouched(denominazione === "");
  };

  const hanldeSubmit = async (e) => {
    e.preventDefault();
    if (isTouched) {
      document.getElementById("den").focus();
      return;
    }
    const nuovoCliente = {
      denominazione,
      denominazione_sort: denominazione.toLowerCase(),
      referente,
      piva,
      codfis,
      paese,
      indirizzo,
      citta,
      cap,
      provincia,
      codiceInterno,
      email,
      pec,
      telefono,
    };
    if (cliente) {
      updateCliente(cliente.id, nuovoCliente);
    } else {
      addCliente(nuovoCliente);
    }
  };

  const resetForm = () => {
    setDenominazione("");
    setReferente("");
    setPiva("");
    setCodFis("");
    setPaese("");
    setIndirizzo("");
    setCitta("");
    setCap("");
    setProvincia("");
    setCodiceInterno("");
    setEmail("");
    setPec("");
    setTelefono("");
    setIsTouched(false);
  };

  const closeForm = () => {
    resetForm();
    toggleForm(false);
  };

  const setFormValues = () => {
    setDenominazione(cliente.denominazione);
    setReferente(cliente.referente);
    setPiva(cliente.piva);
    setCodFis(cliente.codfis);
    setPaese(cliente.paese);
    setIndirizzo(cliente.indirizzo);
    setCitta(cliente.citta);
    setCap(cliente.cap);
    setProvincia(cliente.provincia);
    setCodiceInterno(cliente.codiceInterno);
    setEmail(cliente.email);
    setPec(cliente.pec);
    setTelefono(cliente.telefono);
  };

  useEffect(() => {
    if (isSubmitted) closeForm();
  }, [isSubmitted]);

  useEffect(() => {
    if (cliente) setFormValues();
  }, [cliente]);

  return (
    <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-8 overflow-hidden">
      <div className="form-header flex items-center justify-between">
        <div className="header-title text-base font-semibold text-gray-800">
          {cliente ? "Modifica cliente" : "Nuovo cliente"}
        </div>
        <button
          onClick={() => closeForm()}
          className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none rounded-full  transition-all ease-in-out duration-300 "
        >
          <p className="text-xs font-medium leading-none text-white">Chiudi</p>
        </button>
      </div>
      <form action="" className="mt-8" onSubmit={hanldeSubmit}>
        <div className="flex flex-col md:flex-row w-full justify-around">
          <div className="flex flex-col w-full md:w-1/3 md:pr-4">
            <div className="">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="denominazione"
              >
                Denominazione
              </label>
              <input
                autoFocus
                id="den"
                value={denominazione}
                onChange={(e) => setDenominazione(e.target.value)}
                onBlur={denBlur}
                type="name"
                tabIndex="0"
                className={`w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800 ${
                  isTouched ? "border-red-800" : ""
                }`}
                aria-labelledby="denominazione"
                placeholder="Ragione sociale o nome"
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="referente"
              >
                Referente
              </label>
              <input
                value={referente}
                onChange={(e) => setReferente(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="referente"
                placeholder=""
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="piva"
              >
                Partita IVA
              </label>
              <input
                value={piva}
                onChange={(e) => setPiva(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="piva"
                placeholder=""
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="codfis"
              >
                Codice fiscale
              </label>
              <input
                value={codfis}
                onChange={(e) => setCodFis(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="codfis"
                placeholder=""
              />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/3 md:pr-4 mt-3 md:mt-0">
            <div className="">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="paese"
              >
                Paese
              </label>
              <input
                value={paese}
                onChange={(e) => setPaese(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="paese"
                placeholder=""
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="indirizzo"
              >
                Indirizzo
              </label>
              <input
                value={indirizzo}
                onChange={(e) => setIndirizzo(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="indirizzo"
                placeholder=""
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="citta"
              >
                Città
              </label>
              <input
                value={citta}
                onChange={(e) => setCitta(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="citta"
                placeholder=""
              />
            </div>
            <div className="mt-3 flex justify-between">
              <div className="w-1/2 pr-2">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="cap"
                >
                  CAP
                </label>
                <input
                  value={cap}
                  onChange={(e) => setCap(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="cap"
                  placeholder=""
                />
              </div>
              <div className="w-1/2 pl-2">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="provincia"
                >
                  Provincia
                </label>
                <input
                  value={provincia}
                  onChange={(e) => setProvincia(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="provincia"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/3 mt-3 md:mt-0">
            <div className="">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="codice_interno"
              >
                Codice interno
              </label>
              <input
                value={codiceInterno}
                onChange={(e) => setCodiceInterno(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="codice_interno"
                placeholder="Univico per identificazione"
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="email"
              >
                E-mail
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="email"
                placeholder=""
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="pec"
              >
                PEC
              </label>
              <input
                value={pec}
                onChange={(e) => setPec(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full  border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="pec"
                placeholder=""
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="telefono"
              >
                Telefono
              </label>
              <input
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="telefono"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div className="flex mt-6 justify-end">
          <button
            disabled={denominazione === ""}
            className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full  transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">Salva</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientiForm;
