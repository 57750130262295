import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ArticoloForm from "./ArticoloForm";
import { BiChevronLeft, BiEdit, BiTrashAlt } from "react-icons/bi";

import AsyncSelect from "react-select/async";
import selectOptions from "../select/selectOptions";

import ClientiDataService from "../../services/clienti.services";
import ServiziDataService from "../../services/servizi.services";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";

import { localeIT } from "../../utils/localeIT";

import ProfiloDataService from "../../services/profilo.services";
import FattureDataService from "../../services/fatture.services";

import { Store } from "react-notifications-component";
import alertOptions from "../alert/alertOptions";

import { confirmAlert } from "react-confirm-alert";

const FattureForm = () => {
  const [articoli, setArticoli] = useState([]);
  const [articolo, setArticolo] = useState(null);
  const [index, setIndex] = useState(-1);
  const [cliente, setCliente] = useState(null);
  const [fatture, setFatture] = useState([]);
  const [fattura, setFattura] = useState(null);

  const [numero, setNumero] = useState(1);

  const [note, setNote] = useState("");
  const [pagamento, setPagamento] = useState("");
  const [marca, setMarca] = useState(false);
  const [caricoCliente, setCaricoCliente] = useState(false);
  const [rivalsa, setRivalsa] = useState(false);

  const [noteInterne, setNoteInterne] = useState("");
  const [selectedDay, setSelectedDay] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [ultimaFattura, setUltimaFattura] = useState(null);

  const [riepilogoIva, setRiepilogoIva] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    getProfiloUser();
    getNumero(new Date().getFullYear());
    if (id !== undefined && id !== "") {
      getFattura();
    }
  }, []);

  useEffect(() => {
    checkIva();
  }, [articoli]);

  useEffect(() => {
    checkIva();
    calcolaCompetenze();
  }, [rivalsa]);

  useEffect(() => {
    if (!marca) {
      setCaricoCliente(false);
    }
  }, [marca]);

  useEffect(() => {
    if (fattura) {
      compileFattura();
    }
  }, [fattura]);

  const checkMarca = () => {
    return (
      riepilogoIva.filter((obj) => obj.value === "0" && obj.tot >= 77.47)
        .length > 0
    );
  };

  const getFattura = async () => {
    const data = await FattureDataService.getFattura(id);
    setFattura(data.data());
  };

  const compileFattura = () => {
    const dataFattura = fattura.data.toDate();
    const day = dataFattura.getDate();
    const month = dataFattura.getMonth() + 1;
    const year = dataFattura.getFullYear();
    const d = { day: day, month: month, year: year };
    setArticoli(fattura.articoli);
    setCliente(fattura.cliente);
    setSelectedDay(d);
    setNumero(fattura.numero);
    setNoteInterne(fattura.noteInterne);
    setMarca(fattura.marca);
    setCaricoCliente(fattura.caricoCliente);
    setRivalsa(fattura.rivalsa);
    setPagamento(fattura.pagamento);
    setNote(fattura.note);
  };

  const getProfiloUser = async () => {
    // setLoading(true);
    const data = await ProfiloDataService.getProfilo();
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const prof = docs.length === 1 ? docs[0] : null;
    // setLoading(false);
    if (prof) {
      setNote(prof.pieDiPagina);
      setPagamento(prof.modalitaPagamento);
      setMarca(prof.marcaDaBollo);
      setCaricoCliente(prof.caricoCliente);
      setRivalsa(prof.rivalsa);
    }
    // setProfilo(prof);
  };

  const searchCliente = async (inputValue) => {
    if (inputValue.length < 3) return;
    const data = await ClientiDataService.search(inputValue);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return docs;
  };

  const searchServizio = async (inputValue) => {
    if (inputValue.length < 3) return;
    const data = await ServiziDataService.search(inputValue);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    docs.sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0));
    return docs;
  };

  const handleClienteChange = (value) => {
    setCliente(value);
  };

  const updateArticoli = (art) => {
    if (articolo) {
      const arts = [...articoli];
      arts[index] = art;
      setArticoli(arts);
    } else {
      setArticoli([...articoli, art]);
    }
    setArticolo(null);
    setIndex(-1);
    checkIva();
  };

  const removeArticolo = (articolo) => {
    setArticoli((prev) =>
      prev.filter((el) => el.articolo.id !== articolo.articolo.id)
    );
  };

  const toggleChange = () => {
    setMarca(!marca);
  };

  const toggleChangeCaricoCliente = () => {
    setCaricoCliente(!caricoCliente);
  };

  const toggleChangeRivalsa = (e) => {
    setRivalsa(!rivalsa);
  };

  const handleChangeNote = (value) => {
    setNote(value);
  };

  const handleChangeNoteInterne = (value) => {
    setNoteInterne(value);
  };

  const handleChangePagamento = (value) => {
    setPagamento(value);
  };

  const serachFatturaWithNumero = (numero) => {
    const exists = fatture.filter((f) => f.numero === parseInt(numero));
    if (!fattura) return exists.length > 0;
    return exists.length > 0 && id !== exists[0].id;
  };

  const checkProgressivo = () => {
    let f = [];
    if (fattura) {
      if (fattura.numero === parseInt(numero)) return true;
      const index = fatture.findIndex((obj) => obj.id == id);
      fatture[index].numero = parseInt(numero);
      f = fatture;
    } else {
      f = [...fatture, { numero: parseInt(numero) }];
    }
    for (let i = 0; i < f.length; i++) {
      if (i < f.length - 1) {
        if (f[i + 1].numero - f[i].numero !== 1) {
          return false;
        }
      }
    }
    return true;
  };

  const handleCreaFattura = async () => {
    if (!Number.isInteger(parseInt(numero))) {
      Store.addNotification({
        ...alertOptions,
        message: "Formato della numerazione non corretto",
        type: "danger",
      });
      return;
    }
    if (ultimaFattura) {
      const dataUltima = ultimaFattura.data.toDate();
      const dataFattura = new Date(
        selectedDay.year,
        selectedDay.month - 1,
        selectedDay.day
      );

      if (
        dataUltima > dataFattura &&
        parseInt(ultimaFattura.numero) < parseInt(numero)
      ) {
        Store.addNotification({
          ...alertOptions,
          message:
            "Esiste già una fattura con data successiva ma numero inferiore",
          type: "danger",
        });
        return;
      }
      if (serachFatturaWithNumero(numero)) {
        Store.addNotification({
          ...alertOptions,
          message: "Esiste già un documento con lo stesso numero",
          type: "danger",
        });
        return;
      }
      if (!checkProgressivo()) {
        confirmAlert({
          title: "Attenzione!",
          message:
            "Stai salvando un documento con una numerazione non progressiva. Procedendo genererai un buco nella numerazione.",
          buttons: [
            {
              label: "Procedi e finalizza",
              onClick: () => {
                finalizzaFattura();
              },
            },
            {
              label: "Torna alle modifiche",
              onClick: () => {},
            },
          ],
        });
        return;
      }
      finalizzaFattura();
    } else {
      finalizzaFattura();
    }
  };

  const finalizzaFattura = async () => {
    const nuovaFattura = {
      data: new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day),
      cliente,
      articoli,
      note,
      pagamento,
      marca,
      caricoCliente,
      rivalsa,
      noteInterne,
      numero: parseInt(numero),
    };
    try {
      if (!fattura) {
        await FattureDataService.addFattura(nuovaFattura);
      } else {
        await FattureDataService.upadateFattura(id, nuovaFattura);
      }
      Store.addNotification({
        ...alertOptions,
        message: "Fattura finalizzata con successo",
        type: "success",
      });
      setTimeout(() => {
        document.getElementById("backToFatture").click();
      }, 1500);
    } catch (err) {
      console.log(err);
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const checkIva = () => {
    let ivas = [];
    for (const f of articoli) {
      const i = f.ivaApplicata.name;
      const index = ivas.findIndex((obj) => obj.iva === i);
      const tot =
        f.qt * parseFloat(f.prezzo) -
        f.qt * parseFloat(f.prezzo) * (parseFloat(f.sconto) / 100);
      const riv = rivalsa ? tot * 0.04 : 0;
      if (index === -1) {
        ivas.push({
          value: f.ivaApplicata.value.split("-")[0],
          iva: i,
          tot: tot,
          rivalsa: riv,
        });
      } else {
        ivas[index].tot = ivas[index].tot + tot;
        ivas[index].rivalsa = ivas[index].rivalsa + riv;
      }
    }
    setRiepilogoIva(ivas);
  };

  const handleChangeDate = (date) => {
    setSelectedDay(date);
    getNumero(date.year);
  };

  const getNumero = async (anno) => {
    if (anno !== new Date().getFullYear()) {
    }

    const data = await FattureDataService.fetchFattureYear(anno);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFatture(docs);
    docs.sort((a, b) =>
      a.numero > b.numero ? 1 : b.numero > a.numero ? -1 : 0
    );
    if (docs.length > 0) {
      setNumero(parseInt(docs[docs.length - 1].numero) + 1);
      setUltimaFattura(docs[docs.length - 1]);
    } else {
      setNumero(1);
    }

    if (fattura) {
      setNumero(fattura.numero);
    }
  };

  const updateArticolo = (a, i) => {
    setArticolo(a);
    setIndex(i);
  };

  const calcolaCompetenze = () => {
    return parseFloat(riepilogoIva.reduce((accum, obj) => accum + obj.tot, 0));
  };

  const calcolaRivalsa = () => {
    return calcolaCompetenze() * 0.04;
  };

  const calcolaTotale = () => {
    let tot =
      parseFloat(
        riepilogoIva.reduce(
          (accum, obj) => accum + ((obj.tot + obj.rivalsa) * obj.value) / 100,
          0
        )
      ) +
      parseFloat(
        riepilogoIva.reduce((accum, obj) => accum + (obj.tot + obj.rivalsa), 0)
      );
    if (checkMarca() && caricoCliente) {
      tot += 2;
    }
    return tot;
  };

  return (
    <div className="text-gray-800">
      <div className="w-full">
        <div className="py-4 md:py-7 rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              Nuova fattura
            </p>
            <Link
              className="mr-2 inline-flex items-center justify-between px-4 pl-2 py-3 bg-violet-900 hover:bg-violet-700 disabled:bg-orange-200 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
              to="/fatture"
              id="backToFatture"
            >
              <BiChevronLeft className="text-sm text-white mr-2" />
              <p className="text-xs font-medium leading-none text-white">
                Torna alle fatture
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-5  text-gray-800 rounded-md flex md:items-center flex-col md:flex-row">
        <div className="md:grow md:mr-2 ">
          <label
            className="text-xs font-semibold leading-none text-gray-800"
            id="ivaApplicata"
          >
            Cliente
          </label>
          <AsyncSelect
            cacheOptions
            placeholder="Seleziona cliente"
            defaultOptions
            value={cliente}
            onChange={handleClienteChange}
            filterOption={false}
            isClearable
            loadOptions={searchCliente}
            styles={selectOptions}
            className="select"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) =>
              `${option.denominazione} ${
                option.referente ? `- ${option.referente}` : ""
              }`
            }
            menuPortalTarget={document.querySelector("body")}
            noOptionsMessage={(value) =>
              value.inputValue.length < 3
                ? "Inserisci almeno 3 caratteri"
                : "Nessun risultato"
            }
          />
        </div>
        <div className="flex items-center w-full md:w-auto">
          <div className="flex flex-col md:ml-2 mt-4 md:mt-0 w-1/2 md:w-auto">
            <label
              className="text-xs font-semibold leading-none text-gray-800 mb-1"
              id="ivaApplicata"
            >
              Data fattura
            </label>
            <DatePicker
              value={selectedDay}
              onChange={handleChangeDate}
              inputPlaceholder="Seleziona giorno"
              shouldHighlightWeekends
              formatInputText={() => {
                if (selectedDay)
                  return (
                    ("0" + selectedDay.day).slice(-2) +
                    "-" +
                    ("0" + selectedDay.month).slice(-2) +
                    "-" +
                    selectedDay.year
                  );
                return null;
              }}
              locale={localeIT}
              inputClassName="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-6 text-gray-800 !text-left"
              colorPrimary="rgb(76 29 149)"
            />
          </div>
          <div className="ml-2 mb-0 md:mb-2 mt-3 md:mt-0 w-1/2  md:w-auto">
            <label
              className="text-xs font-semibold leading-none text-gray-800"
              id="numero"
            >
              Numero
            </label>
            <input
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
              type="name"
              tabIndex="0"
              className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
              aria-labelledby="numero"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="grow mr-0 md:mr-3">
          {articoli.length > 0 &&
            articoli.map((a, i) => (
              <div
                className="relative bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-6 pb-5 overflow-y-auto text-gray-800 mt-7 rounded-md flex items-center justify-between"
                key={i}
              >
                <div className="lg:w-[250px]">
                  <div className="font-medium text-black">
                    {a.articolo.nome}
                  </div>
                  <p className="text-slate-500 text-xs">{a.descrizione}</p>
                </div>
                <div className="hidden lg:contents">
                  <div>{a.qt}</div>
                  <div>{a.ivaApplicata.value.split("-")[0]}% IVA</div>
                  <div className="text-right">
                    <div>
                      {parseFloat(a.prezzo).toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      &nbsp;&euro;
                    </div>
                    <div className="font-semibold">
                      {a.ivaApplicata.value.split("-")[0] == 0
                        ? (parseFloat(a.prezzo) * a.qt).toLocaleString(
                            "it-IT",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : (
                            parseFloat(a.prezzo) * a.qt +
                            (parseFloat(a.prezzo) *
                              a.qt *
                              parseInt(a.ivaApplicata.value.split("-")[0])) /
                              100
                          ).toLocaleString("it-IT", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      &nbsp;&euro;
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="group">
                    <button
                      className="p-2 mx-1 group-hover:bg-orange-400 bg-slate-200 rounded-full transition-all ease-in-out duration-300"
                      onClick={() => updateArticolo(a, i)}
                    >
                      <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                    </button>
                  </div>
                  <div className="group ">
                    <button
                      className="p-2 mx-1 mr-0 group-hover:bg-red-600 bg-slate-200 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                      onClick={() => removeArticolo(a)}
                    >
                      <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          <div className="relative bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto text-gray-800 mt-7 rounded-md">
            <ArticoloForm
              searchServizio={searchServizio}
              updateArticoli={updateArticoli}
              articolo={articolo}
            />
          </div>
        </div>
        {articoli.length > 0 && (
          <div className="relative bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-6 overflow-y-auto text-gray-800 mt-7 rounded-md ml-0 md:ml-3 md:min-w-[400px] md:max-w-[400px] pb-5">
            <div className="font-semibold mb-3">Riepilogo</div>
            <div className="flex justify-between">
              <div>
                <p className="mb-2">Competenze</p>
                {rivalsa && <p className="mb-2">Rivalsa (4%)</p>}
                {riepilogoIva.map((obj, i) => (
                  <p key={i} className="mb-2">
                    IVA&nbsp;
                    <span className="text-violet-800 font-medium">
                      {obj.value}%
                    </span>
                    &nbsp;di&nbsp;
                    <span className="text-violet-800 font-medium">
                      {(obj.tot + obj.rivalsa).toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      &nbsp;&euro;
                    </span>
                  </p>
                ))}
                {checkMarca() && caricoCliente && (
                  <p className="mb2">Spese esenti IVA art. 15</p>
                )}

                <p className="font-bold  text-lg">Totale</p>
              </div>
              <div className="text-right font-semibold text-orange-500">
                <p className="mb-2">
                  {calcolaCompetenze().toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </p>
                {rivalsa && (
                  <p className="mb-2">
                    {calcolaRivalsa().toLocaleString("it-IT", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    &nbsp;&euro;
                  </p>
                )}
                {riepilogoIva.map((obj, i) => (
                  <p key={i} className="mb-2">
                    {obj.value != 0
                      ? (
                          ((obj.tot + obj.rivalsa) * obj.value) /
                          100
                        ).toLocaleString("it-IT", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0,00"}
                    &nbsp;&euro;
                  </p>
                ))}
                {checkMarca() && caricoCliente && (
                  <p className="mb2">2,00&nbsp;&euro;</p>
                )}
                <p className="font-bold text-lg">
                  {calcolaTotale().toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </p>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <button
                className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none disabled:bg-orange-300 rounded-full transition-all ease-in-out duration-300"
                disabled={!cliente}
                onClick={handleCreaFattura}
              >
                <p className="text-xs font-medium leading-none text-white">
                  Finalizza fattura
                </p>
              </button>
            </div>
            {checkMarca() && marca && !caricoCliente && (
              <div className="mt-6 text-xs">
                Imposta di bollo da 2 euro assolta sull'originale per importi
                maggiori di 77,47 €
              </div>
            )}
          </div>
        )}
      </div>
      {/* <div className="relative bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto text-gray-800 mt-7 rounded-md">
        <ArticoloForm
          searchServizio={searchServizio}
          updateArticoli={updateArticoli}
          articolo={articolo}
        />
      </div> */}
      <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 mt-7 pb-8 relative text-gray-800 ">
        <label
          className="text-xs font-semibold leading-none text-gray-800"
          id="piedipagina"
        >
          Oggetto (non visibile in fattura)
        </label>
        <textarea
          rows="7"
          value={noteInterne}
          onChange={(e) => handleChangeNoteInterne(e.target.value)}
          tabIndex="0"
          className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-5 text-gray-800"
          aria-labelledby="piedipagina"
          placeholder=""
        ></textarea>
      </div>
      <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 mt-7 pb-8 relative text-gray-800 ">
        <div className="flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/2 mr-0 md:mr-2">
            <label
              className="text-xs font-semibold leading-none text-gray-800"
              id="piedipagina"
            >
              Note a piè di pagina
            </label>
            <textarea
              rows="7"
              value={note}
              onChange={(e) => handleChangeNote(e.target.value)}
              tabIndex="0"
              className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-5 text-gray-800"
              aria-labelledby="piedipagina"
              placeholder=""
            ></textarea>
          </div>
          <div className="mt-6 md:mt-0 w-full md:w-1/2 ml-0 md:ml-2">
            <label
              className="text-xs font-semibold leading-none text-gray-800"
              id="modalitaPagamento"
            >
              Modalità di pagamento
            </label>
            <textarea
              rows="7"
              value={pagamento}
              onChange={(e) => handleChangePagamento(e.target.value)}
              tabIndex="0"
              className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-5 text-gray-800"
              aria-labelledby="modalitaPagamento"
              placeholder=""
            ></textarea>
          </div>
        </div>

        <div className="mt-6">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
            <div className="flex">
              <div className="form-check cursor-pointer">
                <input
                  checked={marca ? marca : false}
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-violet-800 checked:border-violet-800 text-violet-800 focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="marcaDaBollo"
                  onChange={toggleChange}
                />
                <label
                  className="form-check-label inline-block text-gray-800 pt-[3px] cursor-pointer"
                  htmlFor="marcaDaBollo"
                >
                  Marca da bollo (2 &euro;)
                </label>
              </div>
              <div className="form-check cursor-pointer  ml-3">
                <input
                  checked={caricoCliente ? caricoCliente : false}
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-violet-800 checked:border-violet-800 text-violet-800 focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="caricoCliente"
                  disabled={!marca}
                  onChange={toggleChangeCaricoCliente}
                />
                <label
                  className="form-check-label inline-block text-gray-800 pt-[3px] cursor-pointer"
                  htmlFor="caricoCliente"
                >
                  A carico del cliente
                </label>
              </div>
            </div>
            <div className="form-check cursor-pointer mt-4 md:mt-0">
              <input
                checked={rivalsa ? rivalsa : false}
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-violet-800 checked:border-violet-800 text-violet-800 focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                id="rivalsa"
                onChange={(e) => toggleChangeRivalsa(e)}
              />
              <label
                className="form-check-label inline-block text-gray-800 pt-[3px] cursor-pointer"
                htmlFor="rivalsa"
              >
                Rivalsa (4%)
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FattureForm;
