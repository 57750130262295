import { createContext, useEffect, useState, useContext } from "react";

import {
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { auth } from "../firebase";

import UserDataServices from "../services/users.services";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);

  function googleSignIn() {
    const googleAutProvide = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAutProvide);
  }

  function facebookSignIn() {
    const facebookAutProvide = new FacebookAuthProvider();
    return signInWithPopup(auth, facebookAutProvide);
  }

  function logOut() {
    localStorage.removeItem("userLogged");
    localStorage.removeItem("currentAnno");
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        localStorage.setItem("userLogged", currentUser.uid);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      addUser(user);
    }
  }, [user]);

  async function addUser(user) {
    const data = await UserDataServices.getUser(user.email);
    if (data.docs.length === 0) {
      try {
        const newUser = {
          nome: user.displayName,
          email: user.email,
          img: user.photoURL,
        };
        await UserDataServices.addUser(newUser);
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <userAuthContext.Provider
      value={{ user, logOut, googleSignIn, facebookSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
