import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem, _) => {
          return (
            tooltipItem.dataset.label +
            ": " +
            tooltipItem.raw.toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            " €"
          );
        },
      },
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const EntrateUscite = ({ uscite, fatture, year }) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const checkSize = () => {
    const el = document.getElementById("chart");
    const w = el.offsetWidth;
    const h = el.offsetHeight;
    setHeight(h);
    setWidth(w);
  };

  useEffect(() => {
    window.addEventListener("resize", checkSize);
    checkSize();
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: "Uscite",
        data: labels.map((_, i) =>
          parseFloat(
            uscite
              .filter((u) => u.data.toDate().getMonth() === i)
              .reduce((x, usc) => x + parseFloat(usc.importo), 0)
          )
        ),
        borderColor: "rgb(249 115 22)",
        backgroundColor: "rgb(253 186 116)",
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              console.log(tooltipItem);
              return tooltipItem?.value + " test";
            },
          },
        },
      },
      {
        label: "Entrate",
        data: labels.map((_, i) =>
          parseFloat(
            fatture
              .filter((f) => f.data.toDate().getMonth() === i)
              .map((fat) =>
                fat.articoli.reduce(
                  (s, p) =>
                    s +
                    (parseFloat(p.prezzo) * p.qt -
                      (parseFloat(p.prezzo) * p.qt * parseFloat(p.sconto)) /
                        100),
                  0
                )
              )
              .reduce((s, x) => s + x, 0)
          )
        ),
        borderColor: "rgb(76 29 149)",
        backgroundColor: "rgb(139 92 246)",
      },
    ],
  };

  return (
    <div
      className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-5 relative"
      style={{ height: height + 80 + "px", width: "100%" }}
    >
      <div className="font-semibold">Andamento anno {year.value}</div>
      <Line options={options} data={data} id="chart" />
    </div>
  );
};

export default EntrateUscite;
