import React, { useState } from "react";

import Select from "react-select";
import selectOptions from "../select/selectOptions";

import { colors } from "../../utils/colors";

const TipologiaForm = ({ toggleTipologiaForm, addTipologia }) => {
  const [nome, setNome] = useState("");
  const [colore, setColore] = useState({
    nome: "Indaco",
    value: "rgb(67 56 202)",
    valueLight: "rgb(199 210 254)",
  });

  const resetForm = () => {
    setNome("");
    setColore({
      nome: "Indaco",
      value: "rgb(67 56 202)",
      valueLight: "rgb(199 210 254)",
    });
  };

  const closeForm = (e) => {
    e.preventDefault();
    resetForm();
    toggleTipologiaForm(false);
  };

  const hanldeSubmit = (e) => {
    e.preventDefault();
    const nuovaTipologia = {
      nome: nome,
      colore: colore,
    };
    addTipologia(nuovaTipologia);
    closeForm(e);
    toggleTipologiaForm(false);
  };

  return (
    <div className="relative bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-7 pt-4 md:pt-7 pb-6 mt-[25vh] min-w-[350px]">
      <div className="font-semibold">Nuova tipologia</div>
      <form action="" className="mt-3" onSubmit={hanldeSubmit}>
        <div>
          <label
            className="text-xs font-semibold leading-none text-gray-800"
            id="denominazione"
          >
            Nome
          </label>
          <input
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            type="name"
            tabIndex="0"
            className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
            aria-labelledby="referente"
            placeholder=""
          />
        </div>
        <div className="mt-3">
          <label
            className="text-xs font-semibold leading-none text-gray-800"
            id="ivaApplicata"
          >
            Colore
          </label>
          <Select
            options={colors}
            styles={selectOptions}
            className="select"
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => (
              <div className="flex items-center">
                <div
                  className="w-[20px] h-[20px] rounded-full mr-3"
                  style={{ backgroundColor: option.value }}
                ></div>
                <div>{option.nome}</div>
              </div>
            )}
            noOptionsMessage={() => "Nessun risultato"}
            placeholder="Seleziona iva"
            value={colore}
            onChange={(value) => setColore(value)}
            menuPortalTarget={document.querySelector("body")}
          />
        </div>
        <div className="mt-6 flex items-center justify-between">
          <button
            className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full transition-all ease-in-out duration-300"
            disabled={nome === ""}
            //   onClick={() => {
            //     handleUpdate();
            //   }}
          >
            <p className="text-xs font-medium leading-none text-white">Salva</p>
          </button>
          <button
            className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none disabled:bg-orange-200 rounded-full transition-all ease-in-out duration-300"
            type="button"
            onClick={(e) => {
              closeForm(e);
            }}
          >
            <p className="text-xs font-medium leading-none text-white">
              Chiudi
            </p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default TipologiaForm;
