import React, { useState, useEffect } from "react";

import Select from "react-select";
import selectOptions from "../select/selectOptions";

import { iva } from "../../utils/iva";

const ServiziForm = ({
  toggleForm,
  addServizio,
  updateServizio,
  isSubmitted,
  servizio,
}) => {
  const [nome, setNome] = useState("");
  const [codice, setCodice] = useState("");
  const [unitaMisura, setUnitaMisura] = useState("");
  const [ivaApplicata, setIvaApplicata] = useState("");

  const [prezzo, setPrezzo] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [noteInterne, setNoteInterne] = useState("");

  const [isTouched, setIsTouched] = useState(false);

  const denBlur = () => {
    setIsTouched(nome === "");
  };

  const hanldeSubmit = async (e) => {
    e.preventDefault();
    if (isTouched) {
      document.getElementById("nome").focus();
      return;
    }
    const nuovoServizio = {
      nome,
      nome_sort: nome.toLowerCase(),
      codice,
      unitaMisura,
      ivaApplicata,
      prezzo:
        isNaN(prezzo) || prezzo === ""
          ? parseFloat(0).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : parseFloat(prezzo).toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
      descrizione,
      noteInterne,
    };

    if (servizio) {
      updateServizio(servizio.id, nuovoServizio);
    } else {
      addServizio(nuovoServizio);
    }
  };

  const resetForm = () => {
    setNome("");
    setCodice("");
    setUnitaMisura("");
    setIvaApplicata("");
    setPrezzo("");
    setDescrizione("");
    setNoteInterne("");
    setIsTouched(false);
  };

  const closeForm = () => {
    resetForm();
    toggleForm(false);
  };

  const setFormValues = () => {
    setNome(servizio.nome);
    setCodice(servizio.codice);
    setUnitaMisura(servizio.unitaMisura);
    setIvaApplicata(servizio.ivaApplicata);
    setPrezzo(servizio.prezzo);
    setDescrizione(servizio.descrizione);
    setNoteInterne(servizio.noteInterne);
  };

  useEffect(() => {
    if (isSubmitted) closeForm();
  }, [isSubmitted]);

  useEffect(() => {
    if (servizio) setFormValues();
  }, [servizio]);

  return (
    <div className="bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-8 rounded-md overflow-hidden">
      <div className="form-header flex items-center justify-between">
        <div className="header-title text-base font-semibold text-gray-800">
          {servizio ? "Modifica servizio" : "Nuovo servizio"}
        </div>
        <button
          onClick={() => closeForm()}
          className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
        >
          <p className="text-xs font-medium leading-none text-white">Chiudi</p>
        </button>
      </div>
      <form action="" className="mt-8" onSubmit={hanldeSubmit}>
        <div className="flex flex-col md:flex-row w-full justify-around">
          <div className="flex flex-col w-full md:w-1/2 md:pr-4">
            <div className="">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="denominazione"
              >
                Nome
              </label>
              <input
                autoFocus
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                onBlur={denBlur}
                type="name"
                tabIndex="0"
                className={`w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800 ${
                  isTouched ? "border-red-800" : ""
                }`}
                aria-labelledby="nome"
                placeholder=""
              />
            </div>
            <div className="mt-3 flex justify-between">
              <div className="w-1/2 pr-2">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="codice"
                >
                  Codice
                </label>
                <input
                  value={codice}
                  onChange={(e) => setCodice(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="codice"
                  placeholder=""
                />
              </div>
              <div className="w-1/2 pl-2">
                <label
                  className="text-xs font-semibold leading-none text-gray-800"
                  id="unitaMisura"
                >
                  Unità di misura
                </label>
                <input
                  value={unitaMisura}
                  onChange={(e) => setUnitaMisura(e.target.value)}
                  type="name"
                  tabIndex="0"
                  className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                  aria-labelledby="unitaMisura"
                  placeholder=""
                />
              </div>
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="ivaApplicata"
              >
                IVA applicata
              </label>
              <Select
                options={iva}
                styles={selectOptions}
                className="select"
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.name}
                noOptionsMessage={() => "Nessun risultato"}
                placeholder="Seleziona iva"
                value={ivaApplicata}
                onChange={(value) => setIvaApplicata(value)}
                menuPortalTarget={document.querySelector("body")}
              />
            </div>
            <div className="mt-3">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="prezzo"
              >
                Prezzo
              </label>
              <input
                value={prezzo}
                onChange={(e) => setPrezzo(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="prezzo"
                placeholder="0.00"
              />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2 md:pl-4 mt-3 md:mt-0">
            <div className="">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="descrizione"
              >
                Descrizione
              </label>
              <textarea
                rows="7"
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                tabIndex="0"
                className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="descrizione"
                placeholder=""
              ></textarea>
            </div>
            <div className="mt-0">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="noteInterne"
              >
                Note interne
              </label>
              <textarea
                rows="7"
                value={noteInterne}
                onChange={(e) => setNoteInterne(e.target.value)}
                tabIndex="0"
                className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="noteInterne"
                placeholder=""
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex mt-6 justify-end">
          <button
            disabled={nome === ""}
            className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">Salva</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ServiziForm;
