import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  doc,
} from "firebase/firestore";

const eventsCollectionRef = collection(db, "events");

class EventsDataService {
  fetchEvents = (start, end) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      eventsCollectionRef,
      where("user", "==", userLogged),
      where("data", ">=", start),
      where("data", "<=", end),
      orderBy("data", "asc")
    );
    return getDocs(q);
  };

  addEvent = (nuovoEvento) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovoEvento.user = userLogged;
    return addDoc(eventsCollectionRef, nuovoEvento);
  };

  updateEvent = (id, updateEvent) => {
    const eventDoc = doc(db, "events", id);
    return updateDoc(eventDoc, updateEvent);
  };

  deleteEvent = (id) => {
    const eventoDoc = doc(db, "events", id);
    return deleteDoc(eventoDoc);
  };
}

export default new EventsDataService();
