import React, { useState, useEffect } from "react";
import { BiCloudUpload, BiTrashAlt } from "react-icons/bi";
import Loading from "../components/loading/Loading";
import { confirmAlert } from "react-confirm-alert";
import alertOptions from "../components/alert/alertOptions";
import { Store } from "react-notifications-component";

import { useUserAuth } from "../context/UserAuthContext";

import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";

import ProfiloDataService from "../services/profilo.services";

import Background from "../assets/images/bgprofile.webp";
import emptylogo from "../assets/images/emptylogo.gif";

const Impostazioni = () => {
  const [loading, setLoading] = useState(false);

  const { user } = useUserAuth();
  const [profilo, setProfilo] = useState(null);

  const [denominazione, setDenominazione] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");
  const [piva, setPiva] = useState("");
  const [codfis, setCodFis] = useState("");

  const [paese, setPaese] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [citta, setCitta] = useState("");
  const [cap, setCap] = useState("");
  const [provincia, setProvincia] = useState("");

  const [web, setWeb] = useState("");
  const [email, setEmail] = useState("");
  const [pec, setPec] = useState("");
  const [telefono, setTelefono] = useState("");

  const [logo, setLogo] = useState(null);

  const [pieDiPagina, setPieDiPagina] = useState("");
  const [modalitaPagamento, setModalitaPagamento] = useState("");

  const [marcaDaBollo, setMarcaDaBollo] = useState(false);
  const [caricoCliente, setCaricoCliente] = useState(false);
  const [rivalsa, setRivalsa] = useState(false);

  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    function setVariables() {
      if (user) {
        if (denominazione === "") {
          setDenominazione(user.displayName);
        }
        if (email === "") {
          setEmail(user.email);
        }
      }
    }
    setVariables();
  }, [user]);

  useEffect(() => {
    getProfiloUser();
  }, []);

  useEffect(() => {
    if (profilo) {
      setDenominazione(
        profilo.denominazione ? profilo.denominazione : user?.displayName
      );
      setNomeCognome(profilo.nomeCognome);
      setPiva(profilo.piva);
      setCodFis(profilo.codfis);
      setPaese(profilo.paese);
      setIndirizzo(profilo.indirizzo);
      setCitta(profilo.citta);
      setCap(profilo.cap);
      setProvincia(profilo.provincia);
      setWeb(profilo.web);
      setEmail(profilo.email ? profilo.email : user?.email);
      setPec(profilo.pec);
      setTelefono(profilo.telefono);
      setLogo(profilo.logo);
      setPieDiPagina(profilo.pieDiPagina);
      setModalitaPagamento(profilo.modalitaPagamento);
      setMarcaDaBollo(profilo.marcaDaBollo);
      setCaricoCliente(profilo.caricoCliente);
      setRivalsa(profilo.rivalsa);
    }
  }, [profilo]);

  const getProfiloUser = async () => {
    setLoading(true);
    const data = await ProfiloDataService.getProfilo();
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const prof = docs.length === 1 ? docs[0] : null;
    setLoading(false);
    setTimeout(() => document.getElementById("den").focus(), 500);
    setProfilo(prof);
  };

  const handleBlur = () => {
    const nuovoProfilo = {
      denominazione,
      nomeCognome,
      piva,
      codfis,
      paese,
      indirizzo,
      citta,
      cap,
      provincia,
      web,
      email,
      pec,
      telefono,
      pieDiPagina,
      modalitaPagamento,
      marcaDaBollo,
      caricoCliente,
      rivalsa,
    };
    updateProfilo(profilo.id, nuovoProfilo);
    // if (profilo) {
    //   updateProfilo(profilo.id, nuovoProfilo);
    // } else {
    //   addProfilo(nuovoProfilo);
    // }
  };

  // const addProfilo = async (nuovoProfilo) => {
  //   try {
  //     setLoading(true);
  //     await ProfiloDataService.addProfilo(nuovoProfilo);
  //     const data = await ProfiloDataService.getProfilo();
  //     const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     const prof = docs.length === 1 ? docs[0] : null;
  //     setLoading(false);
  //     setTimeout(() => document.getElementById("den").focus(), 500);
  //     setProfilo(prof);
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //   }
  // };

  const updateProfilo = async (id, nuovoProfilo) => {
    try {
      await ProfiloDataService.updateProfilo(id, nuovoProfilo);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeImage = (e) => {
    const logo = e.target.files[0];
    if (logo) {
      setIsLoadingImage(true);
      setTimeout(() => handleUpload(logo), 500);
    }
  };

  const handleUpload = (logoImage) => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `logos/${user.uid}.${logoImage.name.split(".")[1]}`
    );
    const uploadTask = uploadBytesResumable(storageRef, logoImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        setIsLoadingImage(false);
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setLogo(downloadURL);
          setIsLoadingImage(false);
          const nuovoProfilo = {
            denominazione,
            nomeCognome,
            piva,
            codfis,
            paese,
            indirizzo,
            citta,
            cap,
            provincia,
            web,
            email,
            pec,
            telefono,
            pieDiPagina,
            modalitaPagamento,
            marcaDaBollo,
            caricoCliente,
            rivalsa,
            logo: downloadURL,
          };
          updateProfilo(profilo.id, nuovoProfilo);
          // if (profilo) {

          // } else {
          //   addProfilo(nuovoProfilo);
          // }
        });
      }
    );
  };

  const deleteLogo = () => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare il tuo logo?",
      buttons: [
        {
          label: "Elimina",
          onClick: () => {
            const sub = logo.split("?")[0];
            const ext = sub.split(".");
            const storage = getStorage();
            const desertRef = ref(
              storage,
              `logos/${user.uid}.${ext[ext.length - 1]}`
            );
            deleteObject(desertRef)
              .then(async () => {
                try {
                  const nuovoProfilo = {
                    denominazione,
                    nomeCognome,
                    piva,
                    codfis,
                    paese,
                    indirizzo,
                    citta,
                    cap,
                    provincia,
                    web,
                    email,
                    pec,
                    telefono,
                    pieDiPagina,
                    modalitaPagamento,
                    marcaDaBollo,
                    caricoCliente,
                    rivalsa,
                    logo: null,
                  };
                  setIsLoadingImage(true);
                  await ProfiloDataService.updateProfilo(
                    profilo.id,
                    nuovoProfilo
                  );
                  setIsLoadingImage(false);
                  setLogo(null);
                  Store.addNotification({
                    ...alertOptions,
                    message: "Operazione effettuata",
                    type: "success",
                  });
                } catch (err) {
                  Store.addNotification({
                    ...alertOptions,
                    message: "Impossibile completare l'operazione",
                    type: "danger",
                  });
                }
              })
              .catch((error) => {
                Store.addNotification({
                  ...alertOptions,
                  message: "Impossibile completare l'operazione",
                  type: "danger",
                });
              });
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const toggleChange = (event) => {
    setMarcaDaBollo(event.target.checked);
    let newProfilo = {
      ...profilo,
      marcaDaBollo: event.target.checked,
    };
    if (!event.target.checked) {
      setCaricoCliente(false);
      newProfilo.caricoCliente = false;
    }
    if (profilo) {
      updateProfilo(profilo.id, newProfilo);
    }
  };

  const toggleChangeRivalsa = (event) => {
    setRivalsa(event.target.checked);
    if (profilo) {
      updateProfilo(profilo.id, {
        ...profilo,
        rivalsa: event.target.checked,
      });
    }
  };

  const toggleChangeCaricoCliente = (event) => {
    setCaricoCliente(event.target.checked);
    if (profilo) {
      updateProfilo(profilo.id, {
        ...profilo,
        caricoCliente: event.target.checked,
      });
    }
  };

  return (
    <div>
      <div className="relative block h-[500px]">
        <div
          className="absolute -top-5 -left-4 md:-left-7 w-[110%] h-[80%] bg-center bg-cover"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div className="bottom-[120px] -left-4 md:-left-7 h-[70px] right-0 w-[110%] absolute pointer-events-none overflow-hidden">
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-slate-100"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <div className="relative md:pt-10">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg shadow-violet-800/20 rounded-md -mt-[400px]">
          {loading && <Loading />}
          <div className="px-6">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div className="relative">
                  <img
                    alt="..."
                    src={user?.photoURL}
                    className="shadow-xl  h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 rounded-[3rem] w-[150px]"
                    style={{ maxWidth: "150px" }}
                    referrerPolicy="no-referrer"
                  />
                </div>
              </div>
            </div>
            <div className="mt-24 p-6">
              <div action="" className="mb-8">
                <h1 className="mb-8 font-semibold text-lg text-gray-800">
                  Informazioni aziendali
                </h1>
                <div className="flex flex-col md:flex-row w-full justify-around">
                  <div className="flex flex-col w-full md:w-1/3 md:pr-4">
                    <div className="">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="denominazione"
                      >
                        Denominazione
                      </label>
                      <input
                        value={denominazione}
                        onChange={(e) => setDenominazione(e.target.value)}
                        onBlur={() => handleBlur()}
                        id="den"
                        type="name"
                        tabIndex="0"
                        className={`w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800
                        }`}
                        aria-labelledby="denominazione"
                        placeholder="Ragione sociale o nome"
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="nomeCognome"
                      >
                        Nome e cognome
                      </label>
                      <input
                        value={nomeCognome}
                        onChange={(e) => setNomeCognome(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="nomeCognome"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="piva"
                      >
                        Partita IVA
                      </label>
                      <input
                        value={piva}
                        onChange={(e) => setPiva(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="piva"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="codfis"
                      >
                        Codice fiscale
                      </label>
                      <input
                        value={codfis}
                        onChange={(e) => setCodFis(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="codfis"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:w-1/3 md:pr-4 mt-3 md:mt-0">
                    <div className="">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="paese"
                      >
                        Paese
                      </label>
                      <input
                        value={paese}
                        onChange={(e) => setPaese(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="paese"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="indirizzo"
                      >
                        Indirizzo
                      </label>
                      <input
                        value={indirizzo}
                        onChange={(e) => setIndirizzo(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="indirizzo"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="citta"
                      >
                        Città
                      </label>
                      <input
                        value={citta}
                        onChange={(e) => setCitta(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="citta"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3 flex justify-between">
                      <div className="w-1/2 pr-2">
                        <label
                          className="text-xs font-semibold leading-none text-violet-800"
                          id="cap"
                        >
                          CAP
                        </label>
                        <input
                          value={cap}
                          onChange={(e) => setCap(e.target.value)}
                          onBlur={() => handleBlur()}
                          type="name"
                          tabIndex="0"
                          className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                          aria-labelledby="cap"
                          placeholder=""
                          disabled={loading}
                        />
                      </div>
                      <div className="w-1/2 pl-2">
                        <label
                          className="text-xs font-semibold leading-none text-violet-800"
                          id="provincia"
                        >
                          Provincia
                        </label>
                        <input
                          value={provincia}
                          onChange={(e) => setProvincia(e.target.value)}
                          onBlur={() => handleBlur()}
                          type="name"
                          tabIndex="0"
                          className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                          aria-labelledby="provincia"
                          placeholder=""
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:w-1/3 mt-3 md:mt-0">
                    <div className="">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="web"
                      >
                        Sito web
                      </label>
                      <input
                        value={web}
                        onChange={(e) => setWeb(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="web"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="email"
                      >
                        E-mail
                      </label>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="email"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="pec"
                      >
                        PEC
                      </label>
                      <input
                        value={pec}
                        onChange={(e) => setPec(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b   border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="pec"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        className="text-xs font-semibold leading-none text-violet-800"
                        id="telefono"
                      >
                        Telefono
                      </label>
                      <input
                        value={telefono}
                        onChange={(e) => setTelefono(e.target.value)}
                        onBlur={() => handleBlur()}
                        type="name"
                        tabIndex="0"
                        className="w-full p-2 mt-1 border-b  border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-bold leading-none text-gray-800"
                        aria-labelledby="telefono"
                        placeholder=""
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-8 relative">
          {isLoadingImage && <Loading />}
          <div className="flex flex-col sm:flex-row items-center md:mt-0 mt-4">
            <div className="log rounded-full  min-w-[250px] min-h-[250px] max-w-[250px] max-h-[250px] shadow-lg relative">
              {!logo ? (
                <button className="absolute flex items-center justify-center w-14 h-14 bg-violet-800 bottom-0 right-0 rounded-full shadow-md overflow-hidden z-20">
                  <BiCloudUpload className="text-white text-3xl" />
                  <input
                    type="file"
                    className="absolute top-0 left-0 leading-[4] cursor-pointer opacity-0"
                    onChange={handleChangeImage}
                  />
                </button>
              ) : (
                <button
                  className="absolute flex items-center justify-center w-14 h-14 bg-rose-700 bottom-0 right-0 rounded-full shadow-md overflow-hidden  z-20"
                  onClick={deleteLogo}
                >
                  <BiTrashAlt className="text-white text-3xl" />
                </button>
              )}

              <div
                className={`bg-no-repeat bg-center  z-10 ${
                  logo
                    ? "w-[180px] h-[150px] bg-contain "
                    : "w-[250px] h-[250px] rounded-full bg-cover "
                }  absolute`}
                style={{
                  backgroundImage: `url(${logo ? logo : emptylogo})`,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              ></div>
            </div>
            <div className="infologo ml-3 sm:ml-12 md:ml-24 flex flex-col justify-center mt-5 sm:mt-0">
              <h1 className="mb-8 font-semibold text-lg text-gray-800">
                Il tuo logo
              </h1>
              <p className="text-gray-800">
                Carica l'immagine del tuo logo. Sarà visibile nell'intestazioni
                delle tue fature. (opzionale)<br></br>
                <i>La tua fattura verrà generata anche in assenza del logo.</i>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 mt-7 pb-8 relative text-gray-800 ">
          <h1 className="mb-8 font-semibold text-lg ">Fatturazione</h1>
          <p className="mb-8">
            <b>Note a piè di pagina</b>, <b>Modalità di pagamento</b>, e
            <b> Marca da bollo </b> e <b> Rivalsa (4%) </b>
            predefiniti visibili in fattura. Saranno aggiunti in automatico al
            documento. <br /> Puoi cambiarli singolarmente per ogni fattura in
            fase di generazione.
          </p>
          <div className="flex w-full flex-col md:flex-row">
            <div className="w-full md:w-1/2 mr-0 md:mr-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="piedipagina"
              >
                Note a piè di pagina
              </label>
              <textarea
                rows="7"
                value={pieDiPagina}
                onChange={(e) => setPieDiPagina(e.target.value)}
                onBlur={() => handleBlur()}
                tabIndex="0"
                className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-5 text-gray-800"
                aria-labelledby="piedipagina"
                placeholder=""
              ></textarea>
            </div>
            <div className="mt-6 md:mt-0 w-full md:w-1/2 ml-0 md:ml-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="modalitaPagamento"
              >
                Modalità di pagamento
              </label>
              <textarea
                rows="7"
                value={modalitaPagamento}
                onChange={(e) => setModalitaPagamento(e.target.value)}
                onBlur={() => handleBlur()}
                tabIndex="0"
                className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-5 text-gray-800"
                aria-labelledby="modalitaPagamento"
                placeholder=""
              ></textarea>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <div className="flex">
                <div className="form-check cursor-pointer">
                  <input
                    checked={marcaDaBollo ? marcaDaBollo : false}
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-violet-800 checked:border-violet-800 text-violet-800 focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="marcaDaBollo"
                    onChange={toggleChange}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800 pt-[3px] cursor-pointer"
                    htmlFor="marcaDaBollo"
                  >
                    Marca da bollo (2 &euro;)
                  </label>
                </div>
                <div className="form-check cursor-pointer ml-3">
                  <input
                    checked={caricoCliente ? caricoCliente : false}
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-violet-800 checked:border-violet-800 text-violet-800 focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="caricoCliente"
                    onChange={toggleChangeCaricoCliente}
                    disabled={!marcaDaBollo}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800 pt-[3px] cursor-pointer"
                    htmlFor="caricoCliente"
                  >
                    A carico del cliente
                  </label>
                </div>
              </div>

              <div className="form-check cursor-pointer mt-4 md:mt-0">
                <input
                  checked={rivalsa ? rivalsa : false}
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-violet-800 checked:border-violet-800 text-violet-800 focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="Rivalsa"
                  onChange={toggleChangeRivalsa}
                />
                <label
                  className="form-check-label inline-block text-gray-800 pt-[3px] cursor-pointer"
                  htmlFor="Rivalsa"
                >
                  Rivalsa (4%)
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impostazioni;
