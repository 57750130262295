import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CalendarMonth from "../components/fatture/CalendarMonth";
import FattureTable from "../components/fatture/FattureTable";

import FattureDataService from "../services/fatture.services";

import { confirmAlert } from "react-confirm-alert";
import alertOptions from "../components/alert/alertOptions";
import { Store } from "react-notifications-component";

import { useYear } from "../context/YearContext";

/*TOGLIERE*/
import ProfiloDataService from "../services/profilo.services";

import DatePicker from "react-modern-calendar-datepicker";
import { localeIT } from "../utils/localeIT";

const Fatture = () => {
  const [selectedMonth, setselectedMonth] = useState(new Date().getMonth());
  const [fatture, setFatture] = useState([]);
  const [fattureYear, setFattureYear] = useState([]);

  /*TOGLIERE*/
  const [profilo, setProfilo] = useState(null);

  const { year } = useYear();

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const [dataPagamento, setDataPagamento] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [showModal, setShowModal] = useState(false);

  const [fattura, setFattura] = useState(null);
  const [annulla, setAnnulla] = useState(null);

  useEffect(() => {
    fetchFatture(null, null);
    fetchFattureYear();
    /*TOGLIERE*/
    getProfilo();
  }, []);

  useEffect(() => {
    fetchFatture(null, year.value);
    fetchFattureYear();
  }, [year]);

  /*TOGLIERE*/
  const getProfilo = async () => {
    const data = await ProfiloDataService.getProfilo();
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setProfilo(docs[0]);
  };

  const fetchFatture = async (m, a) => {
    setIsLoadingTable(true);
    const year = a !== null ? a : localStorage.getItem("currentAnno");
    const month = m !== null ? m : selectedMonth;
    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 0);
    const data = await FattureDataService.fetchFattureMonth(start, end);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setIsLoadingTable(false);
    docs.sort((a, b) =>
      a.numero > b.numero ? -1 : b.numero > a.numero ? 1 : 0
    );
    setFatture(docs);
  };

  const fetchFattureYear = async () => {
    const year = localStorage.getItem("currentAnno");
    const data = await FattureDataService.fetchFattureYear(year);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFattureYear(docs);
  };

  const handleChangeMonth = (i) => {
    setselectedMonth(i);
    fetchFatture(i, null);
  };

  const deleteFattura = (id, numero) => {
    confirmAlert({
      title: "Elina documento",
      message:
        "Stai cancellando il documento numero " +
        numero +
        " dal tuo account. Vuoi davvero confermare l'eliminazione?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await FattureDataService.deleteFattura(id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              fetchFatture(selectedMonth, localStorage.getItem("currentAnno"));
              fetchFattureYear();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const cambiaPagamento = (data) => {
    setDataPagamento(data);
  };

  const togglePagamentoModal = (value, fattura, annulla = null) => {
    setFattura(fattura);
    setShowModal(value);
    setAnnulla(annulla);
  };

  const setPagamento = async () => {
    if (fattura) {
      if (!annulla) {
        fattura.dataPagamento = new Date(
          dataPagamento.year,
          dataPagamento.month - 1,
          dataPagamento.day
        );
      } else {
        fattura.dataPagamento = null;
      }
      const { id } = fattura;
      delete fattura["id"];
      console.log(fattura);
      try {
        await FattureDataService.upadateFattura(id, fattura);
        setShowModal(false);
        setDataPagamento({
          day: new Date().getDate(),
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        });
        setFattura(null);
        setAnnulla(null);
        Store.addNotification({
          ...alertOptions,
          message: "Operazione effettuata",
          type: "success",
        });
      } catch (er) {
        Store.addNotification({
          ...alertOptions,
          message: "Impossibile completare l'operazione",
          type: "danger",
        });
      }
    } else {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  return (
    <div>
      {showModal && (
        <div className="bg-white/80 fixed top-0 left-0 w-full h-full z-50 flex items-start justify-center">
          <div className="relative bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-7 pt-4 md:pt-7 pb-6 mt-[25vh]">
            <div>
              {annulla
                ? "Vuoi davvero annullare il pagamento?"
                : "Inserisci la data del pagamento"}
            </div>
            {!annulla && (
              <div className="mt-5">
                <DatePicker
                  value={dataPagamento}
                  onChange={cambiaPagamento}
                  inputPlaceholder="Seleziona giorno"
                  shouldHighlightWeekends
                  formatInputText={() => {
                    if (dataPagamento)
                      return (
                        ("0" + dataPagamento.day).slice(-2) +
                        "-" +
                        ("0" + dataPagamento.month).slice(-2) +
                        "-" +
                        dataPagamento.year
                      );
                    return null;
                  }}
                  locale={localeIT}
                  inputClassName="w-[230px] p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-6 text-gray-800 !text-left"
                  colorPrimary="rgb(76 29 149)"
                />
              </div>
            )}

            <div className="flex items-center justify-between mt-6">
              <button
                onClick={() => setPagamento()}
                className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300"
              >
                <p className="text-xs font-medium leading-none text-white">
                  {annulla ? "Si, annulla" : "Salva"}
                </p>
              </button>
              <button
                onClick={() => togglePagamentoModal(false, null)}
                className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none rounded-full  transition-all ease-in-out duration-300 "
              >
                <p className="text-xs font-medium leading-none text-white">
                  {annulla ? "Chiudi" : "Annulla"}
                </p>
              </button>
            </div>
          </div>
        </div>
      )}

      <CalendarMonth
        selectedMonth={selectedMonth}
        handleChangeMonth={handleChangeMonth}
        fatture={fattureYear}
      />
      <div className="w-full pb-5">
        <div className="rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              Fatture {("0" + (selectedMonth + 1)).slice(-2)}/{year.value}
            </p>
            <Link
              // disabled={formOpened}
              // onClick={() => toggleForm(true)}
              to="/nuova-fattura"
              className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300"
            >
              <p className="text-xs font-medium leading-none text-white">
                Nuova fattura
              </p>
            </Link>
          </div>
        </div>
      </div>
      {/* <div>
        <PDFDownloadLink document={<PDF1 />} fileName="TEST">
          {({ loading }) =>
            loading ? <button>Loading...</button> : <button>Download</button>
          }
        </PDFDownloadLink>
        <PDF1 />
      </div> */}
      <FattureTable
        fatture={fatture}
        isLoadingTable={isLoadingTable}
        deleteFattura={deleteFattura}
        /*TOGLIERE*/
        profilo={profilo}
        togglePagamentoModal={togglePagamentoModal}
      />
    </div>
  );
};

export default Fatture;
