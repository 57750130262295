import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  BiDotsVerticalRounded,
  BiEdit,
  BiTrashAlt,
  BiDownload,
  BiMoney,
} from "react-icons/bi";

import { useNavigate } from "react-router-dom";
import SalvaFatturaPDF from "./../pdf/SalvaFatturaPDF";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DropDown = ({ item, deleteFattura, profilo, togglePagamentoModal }) => {
  const navigate = useNavigate();

  const pagamento = () => {};

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-full px-2 py-2  text-sm font-medium text-gray-800  focus:outline-none focus:ring-0 focus:ring-offset-0 ">
          <BiDotsVerticalRounded className="text-xl" />
          {/* <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right fixed right-9 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => navigate(`/nuova-fattura/${item.id}`)}
                  className={classNames(
                    active ? "bg-slate-100 text-gray-800" : "text-gray-800",
                    "px-4 py-2 text-sm cursor-pointer flex items-center"
                  )}
                  style={{ userSelect: "none" }}
                >
                  <BiEdit className="mr-2 text-orange-500" /> Modifica
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => deleteFattura(item.id, item.numero)}
                  className={classNames(
                    active ? "bg-slate-100 text-gray-800" : "text-gray-700",
                    " flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                  style={{ userSelect: "none" }}
                >
                  <BiTrashAlt className="mr-2 text-red-500" /> Elimina
                </div>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => null}
                  className={classNames(
                    active ? "bg-slate-100 text-gray-800" : "text-gray-800",
                    "px-4 py-2 text-sm cursor-pointer flex flex-row items-center"
                  )}
                  style={{ userSelect: "none" }}
                >
                  <PDFDownloadLink
                    document={
                      <SalvaFatturaPDF fattura={item} profilo={profilo} />
                    }
                    fileName={`${profilo?.denominazione} - ${
                      item?.cliente.denominazione
                    } - Fattura n. ${item?.numero} del ${item?.data
                      .toDate()
                      .getDate()}-${
                      item?.data.toDate().getMonth() + 1
                    }-${item?.data.toDate().getFullYear()}.pdf`}
                    className="flex items-center"
                  >
                    <BiDownload className="mr-2 text-sky-400" /> Download
                  </PDFDownloadLink>
                </div>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() =>
                    togglePagamentoModal(
                      true,
                      item,
                      item.dataPagamento ? 1 : null
                    )
                  }
                  className={classNames(
                    active ? "bg-slate-100 text-gray-800" : "text-gray-800",
                    "px-4 py-2 text-sm cursor-pointer flex items-center"
                  )}
                  style={{ userSelect: "none" }}
                >
                  <BiMoney className="mr-2 text-emerald-500" />
                  {!item.dataPagamento || item.dataPagamento === ""
                    ? "Registra pgamento"
                    : "Annulla pagamento"}
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDown;
