import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as Google } from "../assets/images/google.svg";

import ProfiloDataService from "../services/profilo.services";
import TaskDataService from "../services/task.services";

const Login = () => {
  const [error, setError] = useState("");

  const { googleSignIn } = useUserAuth();

  const navigate = useNavigate();

  const { userLogged } = localStorage;

  if (userLogged) {
    return <Navigate to="/situazione" />;
  }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const user = await googleSignIn();
      const data = await ProfiloDataService.getProfilo(user.user.uid);
      const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      if (docs.length === 0) {
        const nuovoProfilo = {
          denominazione: user.user.displayName,
          email: user.user.email,
          nomeCognome: "",
          piva: "",
          codfis: "",
          paese: "",
          indirizzo: "",
          citta: "",
          cap: "",
          provincia: "",
          web: "",
          pec: "",
          telefono: "",
          pieDiPagina: "",
          modalitaPagamento: "",
          marcaDaBollo: false,
          caricoCliente: false,
          rivalsa: false,
          logo: null,
        };
        await ProfiloDataService.addProfilo(nuovoProfilo);
      }
      const dataTask = await TaskDataService.getTask(user.user.email);
      if (dataTask.docs.length === 0) {
        try {
          const newTask = {
            admin: user.user.email,
            img: user.user.photoURL,
            nome: user.user.displayName,
          };
          await TaskDataService.addTask(newTask);
        } catch (err) {
          setError(err.message);
        }
      }
      navigate("/situazione");
    } catch (err) {
      setError(err.message);
    }
  };

  // const handleFacebookSignIn = async (e) => {
  //   try {
  //     const user = await facebookSignIn();
  //     console.log(user);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // e.preventDefault();
  // setError("");
  // try {
  //   const user = await googleSignIn();
  //   const data = await ProfiloDataService.getProfilo(user.user.uid);
  //   const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //   if (docs.length === 0) {
  //     const nuovoProfilo = {
  //       denominazione: user.user.displayName,
  //       email: user.user.email,
  //       nomeCognome: "",
  //       piva: "",
  //       codfis: "",
  //       paese: "",
  //       indirizzo: "",
  //       citta: "",
  //       cap: "",
  //       provincia: "",
  //       web: "",
  //       pec: "",
  //       telefono: "",
  //       pieDiPagina: "",
  //       modalitaPagamento: "",
  //       marcaDaBollo: false,
  //       caricoCliente: false,
  //       rivalsa: false,
  //       logo: null,
  //     };
  //     await ProfiloDataService.addProfilo(nuovoProfilo);
  //   }
  //   const dataTask = await TaskDataService.getTask(user.user.email);
  //   if (dataTask.docs.length === 0) {
  //     try {
  //       const newTask = {
  //         admin: user.user.email,
  //         img: user.user.photoURL,
  //         nome: user.user.displayName,
  //       };
  //       await TaskDataService.addTask(newTask);
  //     } catch (err) {
  //       setError(err.message);
  //     }
  //   }
  //   navigate("/situazione");
  // } catch (err) {
  //   setError(err.message);
  // }
  // };

  // const handleFacebookSignIn = async e => {
  //   e.preventDefault();
  //   try {
  //     await facebookSignIn();
  //     navigate("/clienti");
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  return (
    <div className="bg-sidebar min-h-screen flex items-center justify-center flex-col text-white">
      <div className="absolute w-[350px] h-[350px] rounded-[50%] blur-[80px] bg-shape z-0"></div>
      <div className="z-10 flex items-center justify-center flex-col">
        <div className="logo flex flex-col items-center mb-5">
          <Logo className="w-[45px] h-[45px]" />
          <div className="text-lg font-extrabold">xion</div>
        </div>
        {error ? (
          <div className="mb-2 text-xs text-orange-400">{error}</div>
        ) : (
          <div className="mb-2 text-xs text-orange-400">&nbsp;</div>
        )}
        <div className="p-6 px-10 sm:w-[350px]  mx-auto bg-white rounded-xl shadow-lg flex items-center justify-center flex-col">
          <p className="text-slate-500">Effettua il login</p>
          <div className="flex justify-center items-center mt-4">
            <button onClick={handleGoogleSignIn}>
              <Google className="w-[60px] h-[60px]" />
            </button>
            {/* <button onClick={handleFacebookSignIn} className="text-black">
              facebook
            </button> */}
          </div>
          <div className="text-slate-500 text-xs mt-4 flex flex-col items-center">
            Non hai un accoung Google?
            <div>
              Creane un{" "}
              <a
                className="text-sidebar underline font-semibold"
                href="https://accounts.google.com/signup/v2/webcreateaccount?service=accountsettings&continue=https%3A%2F%2Fmyaccount.google.com%2F&dsh=S1091134711%3A1646763296862995&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp"
                target="_blank"
              >
                qui
              </a>
            </div>
          </div>
        </div>
        <div className="text-xs mt-10 text-sidebarcolor">
          Copyright &copy; 2022 - Tutti i diritti riservati
        </div>
      </div>
    </div>
  );
};

export default Login;
