import React, { useState, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import UsciteForm from "../components/uscite/UsciteForm";
import CalendarMonthUscite from "./../components/uscite/CalendarMonthUscite";

import UsciteDataServices from "../services/uscite.services";

import { useYear } from "../context/YearContext";
import UsciteTable from "./../components/uscite/UsciteTable";

import { confirmAlert } from "react-confirm-alert";
import { Store } from "react-notifications-component";
import alertOptions from "../components/alert/alertOptions";

const Uscite = () => {
  const [selectedMonth, setselectedMonth] = useState(new Date().getMonth());
  const [usciteYear, setUsciteYear] = useState([]);
  const [uscite, setUscite] = useState([]);

  const [uscita, setUscita] = useState(null);

  const [formOpened, setFormOpened] = useState(false);

  const { year } = useYear();

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  useEffect(() => {
    fetchUscite(null, null);
    fetchUsciteYear();
  }, []);

  useEffect(() => {
    fetchUscite(null, year.value);
    fetchUsciteYear();
  }, [year]);

  const fetchUscite = async (m, a) => {
    setIsLoadingTable(true);
    const year = a !== null ? a : localStorage.getItem("currentAnno");
    const month = m !== null ? m : selectedMonth;
    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 0);
    const data = await UsciteDataServices.fetchUsciteMonth(start, end);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setIsLoadingTable(false);
    docs.sort((a, b) => (a.data > b.data ? -1 : b.data > a.data ? 1 : 0));
    setUscite(docs);
  };

  const fetchUsciteYear = async () => {
    const year = localStorage.getItem("currentAnno");
    const data = await UsciteDataServices.fetchUsciteYear(year);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setUsciteYear(docs);
  };

  const handleChangeMonth = (i) => {
    setselectedMonth(i);
    fetchUscite(i, null);
  };

  const toggleForm = (state) => {
    setFormOpened(state);
    if (state) {
      window.scrollTo(0, 0);
    } else {
      setUscita(null);
    }
  };

  const addUscita = async (uscita) => {
    try {
      await UsciteDataServices.addUscita(uscita);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione effettuata",
        type: "success",
      });
      fetchUsciteYear();
      fetchUscite(null, null);
    } catch (e) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const updateUscita = async (id, uscita) => {
    try {
      await UsciteDataServices.updateUscita(id, uscita);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione effettuata",
        type: "success",
      });
      fetchUsciteYear();
      fetchUscite(null, null);
    } catch (e) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const deleteUscita = (uscita) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await UsciteDataServices.deleteUscita(uscita.id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              fetchUsciteYear();
              fetchUscite(null, null);
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const setUscitaActive = (uscita) => {
    setUscita(uscita);
    toggleForm(true);
  };

  return (
    <div>
      <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
        <UsciteForm
          toggleForm={toggleForm}
          addUscita={addUscita}
          updateUscita={updateUscita}
          uscita={uscita}
        />
        {/* <ClientiForm
          toggleForm={toggleForm}
          addCliente={addCliente}
          updateCliente={updateCliente}
          isSubmitted={isSubmitted}
          cliente={cliente}
        /> */}
      </AnimateHeight>
      <CalendarMonthUscite
        selectedMonth={selectedMonth}
        handleChangeMonth={handleChangeMonth}
        uscite={usciteYear}
      />
      <div className="w-full pb-5">
        <div className="rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              Uscite {("0" + (selectedMonth + 1)).slice(-2)}/{year.value}
            </p>
            <button
              disabled={formOpened}
              onClick={() => toggleForm(true)}
              className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300"
            >
              <p className="text-xs font-medium leading-none text-white">
                Nuova uscita
              </p>
            </button>
          </div>
        </div>
      </div>
      <UsciteTable
        uscite={uscite}
        isLoadingTable={isLoadingTable}
        setUscitaActive={setUscitaActive}
        formOpened={formOpened}
        deleteUscita={deleteUscita}
      />
    </div>
  );
};

export default Uscite;
