import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  doc,
  limit,
  startAfter,
  getDoc,
} from "firebase/firestore";

const clientiCollectionRef = collection(db, "clienti");

class ClientiDataService {
  addCliente = (nuovoCliente) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovoCliente.user = userLogged;
    return addDoc(clientiCollectionRef, nuovoCliente);
  };

  updateCliente = (id, updateCliente) => {
    const clienteDoc = doc(db, "clienti", id);
    return updateDoc(clienteDoc, updateCliente);
  };

  deleteCliente = (id) => {
    const clienteDoc = doc(db, "clienti", id);
    return deleteDoc(clienteDoc);
  };

  getSize = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(clientiCollectionRef, where("user", "==", userLogged));
    return getDocs(q);
  };

  fetchClienti = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      clientiCollectionRef,
      where("user", "==", userLogged),
      orderBy("denominazione_sort", "asc")
    );
    return getDocs(q);
  };

  getAllClienti = (offset) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      clientiCollectionRef,
      where("user", "==", userLogged),
      orderBy("denominazione_sort", "asc"),
      limit(offset)
    );
    return getDocs(q);
  };

  fetchNext = (lastDoc, offset) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      clientiCollectionRef,
      where("user", "==", userLogged),
      orderBy("denominazione_sort", "asc"),
      limit(offset),
      startAfter(lastDoc)
    );
    return getDocs(q);
  };

  fetchPrev = (firstDoc, offset) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      clientiCollectionRef,
      where("user", "==", userLogged),
      orderBy("denominazione_sort", "desc"),
      limit(offset),
      startAfter(firstDoc)
    );
    return getDocs(q);
  };

  search = (terms) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      clientiCollectionRef,
      where("user", "==", userLogged),
      where("denominazione_sort", ">=", terms),
      where("denominazione_sort", "<=", terms + "\uf8ff"),
      orderBy("denominazione_sort", "desc")
    );
    return getDocs(q);
  };

  getCliente = (id) => {
    const clienteDoc = doc(db, "clienti", id);
    return getDoc(clienteDoc);
  };
}

export default new ClientiDataService();
