import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import NavigationCopy from "./components/NavigationCopy";
import NavBarCopy from "./components/NavBarCopy";

const PageLayoutCopy = () => {
  const [opened, setOpened] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 1024) {
      setIsMobile(true);
    }
    function handleResize() {
      const width = window.innerWidth;
      if (width < 1024) {
        setIsMobile(true);
        setOpened(false);
      } else {
        setIsMobile(false);
        // setOpened(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggle = () => {
    setOpened(!opened);
  };

  const userLogged = localStorage.getItem("userLogged");

  if (!userLogged) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex">
      <NavigationCopy opened={opened} toggle={toggle} isMobile={isMobile} />
      <NavBarCopy opened={opened} toggle={toggle} isMobile={isMobile} />
      <main
        className={`min-h-screen bg-slate-100 transition-all ease-in-out duration-300 xs:overflow-x-auto md:overflow-x-hidden  ${
          opened
            ? "w-full lg:w-[calc(100vw-250px)] ml-0 lg:ml-[250px]"
            : "w-full lg:w-[calc(100vw-70px)] ml-0 lg:ml-[70px]"
        }`}
      >
        <div className="min-h-screen min-w-screen lg:px-7 px-4 pt-[70px] pb-8 ">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default PageLayoutCopy;
