export const colors = [
  { nome: "Ambra", value: "rgb(180 83 9)", valueLight: "rgb(253 230 138)" },
  {
    nome: "Arancione",
    value: "rgb(194 65 12)",
    valueLight: "rgb(254 215 170)",
  },
  { nome: "Ardesia", value: "rgb(51 65 85)", valueLight: "rgb(226 232 240)" },
  { nome: "Blu", value: "rgb(29 78 216)", valueLight: "rgb(191 219 254)" },
  { nome: "Ciano", value: "rgb(14 116 144)", valueLight: "rgb(165 243 252)" },
  { nome: "Cielo", value: "rgb(3 105 161)", valueLight: "rgb(186 230 253)" },
  { nome: "Fucsia", value: "rgb(162 28 175)", valueLight: "rgb(245 208 254)" },
  { nome: "Giallo", value: "rgb(161 98 7)", valueLight: "rgb(254 240 138)" },
  { nome: "Grigio", value: "rgb(55 65 81)", valueLight: "rgb(229 231 235)" },
  { nome: "Indaco", value: "rgb(67 56 202)", valueLight: "rgb(199 210 254)" },
  { nome: "Lime", value: "rgb(77 124 15)", valueLight: "rgb(217 249 157)" },
  { nome: "Pietra", value: "rgb(68 64 60)", valueLight: "rgb(231 229 228)" },
  { nome: "Porpora", value: "rgb(126 34 206)", valueLight: "rgb(233 213 255)" },
  { nome: "Rosa", value: "rgb(190 18 60)", valueLight: "rgb(254 205 211)" },
  { nome: "Rosso", value: "rgb(185 28 28)", valueLight: "rgb(254 202 202)" },
  { nome: "Verde", value: "rgb(21 128 61)", valueLight: "rgb(187 247 208)" },
  {
    nome: "Verde acqua",
    value: "rgb(15 118 110)",
    valueLight: "rgb(153 246 228)",
  },
  { nome: "Viola", value: "rgb(109 40 217)", valueLight: "rgb(221 214 254)" },
  { nome: "Zinco", value: "rgb(63 63 70)", valueLight: "rgb(228 228 231)" },
];
