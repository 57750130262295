import { db } from "../firebase";

import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const todoCollectionRef = collection(db, "todos");

class TodoDataService {
  addTodo = (nuovoTodo) => {
    return addDoc(todoCollectionRef, nuovoTodo);
  };

  getTodos = (task, data) => {
    const q = query(
      todoCollectionRef,
      where("task", "==", task),
      where("data", "==", data)
    );
    return getDocs(q);
  };

  getTodosInMonth = (task, data) => {
    const start = new Date(data.getFullYear(), data.getMonth(), 1);
    var end = new Date(data.getFullYear(), data.getMonth() + 1, 0);
    const q = query(
      todoCollectionRef,
      where("task", "==", task),
      where("data", ">=", start),
      where("data", "<=", end)
      // where("stato", "==", "todo")
    );
    return getDocs(q);
  };

  updateTodo = (id, updateTodo) => {
    const todoDoc = doc(db, "todos", id);
    return updateDoc(todoDoc, updateTodo);
  };

  deleteTodo = (id) => {
    const todoDoc = doc(db, "todos", id);
    return deleteDoc(todoDoc);
  };

  //   getTask = (email) => {
  //     const q = query(taskCollectionRef, where("admin", "==", email));
  //     return getDocs(q);
  //   };

  //   addTodo = (task, id) => {
  //     const taskDoc = await doc(db, "tasks", id);
  //     const task = await getDoc(taskDoc);
  //     return updateDoc(taskDoc, { tasks: arrayUnion(task) });
  //   };

  //   addUser = async (user, id) => {
  //     const taskDoc = await doc(db, "tasks", id);
  //     const task = await getDoc(taskDoc);
  //     let exists = [];
  //     if (task.data().users) {
  //       exists = task.data().users.filter((obj) => obj.email === user.email);
  //     }
  //     if (exists.length === 0)
  //       return updateDoc(taskDoc, { users: arrayUnion(user) });
  //     throw new Error();
  //   };

  //   removeUser = async (user, id) => {
  //     const taskDoc = await doc(db, "tasks", id);
  //     return updateDoc(taskDoc, { users: arrayRemove(user) });
  //   };
}

export default new TodoDataService();
