import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";

const fattureCollectionRef = collection(db, "fatture");

class FattureDataService {
  addFattura = (nuovaFattura) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovaFattura.user = userLogged;
    return addDoc(fattureCollectionRef, nuovaFattura);
  };

  fetchFattureMonth = (start, end) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      fattureCollectionRef,
      where("user", "==", userLogged),
      where("data", ">=", start),
      where("data", "<=", end),
      orderBy("data", "asc")
    );
    return getDocs(q);
  };

  fetchFattureYear = (year) => {
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      fattureCollectionRef,
      where("user", "==", userLogged),
      where("data", ">=", start),
      where("data", "<=", end),
      orderBy("data", "asc")
    );
    return getDocs(q);
  };

  fetchFattureCliente = (cliente, year) => {
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      fattureCollectionRef,
      where("user", "==", userLogged),
      where("data", ">=", start),
      where("data", "<=", end),
      where("cliente.id", "==", cliente)
      // orderBy("data", "asc")
    );
    return getDocs(q);
  };

  getFattura = (id) => {
    const fatturaDoc = doc(db, "fatture", id);
    return getDoc(fatturaDoc);
  };

  upadateFattura = (id, upadateFattura) => {
    const fatturaDoc = doc(db, "fatture", id);
    return updateDoc(fatturaDoc, upadateFattura);
  };

  deleteFattura = (id) => {
    const fatturaDoc = doc(db, "fatture", id);
    return deleteDoc(fatturaDoc);
  };

  //   getSize = () => {
  //     const userLogged = localStorage.getItem("userLogged");
  //     const q = query(clientiCollectionRef, where("user", "==", userLogged));
  //     return getDocs(q);
  //   };

  //   fetchClienti = () => {
  //     const userLogged = localStorage.getItem("userLogged");
  //     const q = query(
  //       clientiCollectionRef,
  //       where("user", "==", userLogged),
  //       orderBy("denominazione_sort", "asc")
  //     );
  //     return getDocs(q);
  //   };

  //   getAllClienti = (offset) => {
  //     const userLogged = localStorage.getItem("userLogged");
  //     const q = query(
  //       clientiCollectionRef,
  //       where("user", "==", userLogged),
  //       orderBy("denominazione_sort", "asc"),
  //       limit(offset)
  //     );
  //     return getDocs(q);
  //   };

  //   fetchNext = (lastDoc, offset) => {
  //     const userLogged = localStorage.getItem("userLogged");
  //     const q = query(
  //       clientiCollectionRef,
  //       where("user", "==", userLogged),
  //       orderBy("denominazione_sort", "asc"),
  //       limit(offset),
  //       startAfter(lastDoc)
  //     );
  //     return getDocs(q);
  //   };

  //   fetchPrev = (firstDoc, offset) => {
  //     const userLogged = localStorage.getItem("userLogged");
  //     const q = query(
  //       clientiCollectionRef,
  //       where("user", "==", userLogged),
  //       orderBy("denominazione_sort", "desc"),
  //       limit(offset),
  //       startAfter(firstDoc)
  //     );
  //     return getDocs(q);
  //   };

  //   search = (terms) => {
  //     const userLogged = localStorage.getItem("userLogged");
  //     const q = query(
  //       clientiCollectionRef,
  //       where("user", "==", userLogged),
  //       where("denominazione_sort", ">=", terms),
  //       where("denominazione_sort", "<=", terms + "\uf8ff"),
  //       orderBy("denominazione_sort", "desc")
  //     );
  //     return getDocs(q);
  //   };

  //   getBook = id => {
  //     const bookDoc = doc(db, "books", id);
  //     return getDoc(bookDoc);
  //   };
}

export default new FattureDataService();
