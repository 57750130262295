import React, { useState, useEffect, useRef } from "react";
import FullCalendar, { CalendarApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import itLocale from "@fullcalendar/core/locales/it";
import interactionPlugin from "@fullcalendar/interaction";

import alertOptions from "../components/alert/alertOptions";
import { Store } from "react-notifications-component";
import { confirmAlert } from "react-confirm-alert";

import EventsDataService from "../services/events.services";
import TipologieDataService from "../services/tipologie.services";

import EventsForm from "./../components/events/EventsForm";
import AnimateHeight from "react-animate-height";
import TipologiaForm from "./../components/events/TipologiaForm";

import { BiTime, BiTrashAlt, BiEditAlt } from "react-icons/bi";

import { useEvents } from "../context/EventsContext";

const Agenda = () => {
  const [formOpened, setFormOpened] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);

  const [tipologie, setTipologie] = useState([]);
  const [formTipologiaOpened, setFormTipologiaOpened] = useState(false);

  const [addedTipologia, setAddedTipologia] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  const calendarRef = useRef();

  const { fetchEventsC } = useEvents();

  useEffect(() => {
    getTipologie();
  }, []);

  useEffect(() => {
    console.log(events);
  }, [events]);

  // useEffect(() => {
  //   fetchEventsC(
  //     events.filter(
  //       (ev) =>
  //         ev.data.getFullYear() === new Date().getFullYear() &&
  //         ev.data.getMonth() === new Date().getMonth() &&
  //         ev.data.getDate() === new Date().getDate()
  //     )
  //   );
  // }, [events]);

  const getTipologie = async () => {
    const data = await TipologieDataService.getTipologie();
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTipologie(docs);
  };

  const toggleTipologiaForm = (state) => {
    setFormTipologiaOpened(state);
    if (!state) {
      setAddedTipologia(null);
    }
  };

  const handleDateClick = (date) => {
    setFormOpened(true);
    setDateSelected(date);
    window.scrollTo(0, 0);
  };

  const updateEvent = (eventInfo) => {
    setSelectedEvent(eventInfo);
    toggleForm(true);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div
        className="p-1 w-full rounded text-gray-50 relative"
        style={{ backgroundColor: eventInfo.event.extendedProps.colore }}
      >
        <div>{eventInfo.event.title}</div>
        <div className="text-[10px] mt-1 whitespace-normal">
          {eventInfo.event.extendedProps.descrizione}
        </div>
        <div className="flex mt-2 items-center">
          <BiTime className="mr-1 -mt-[3px]" />
          <div>{eventInfo.event.extendedProps.time}</div>
        </div>
        <div className="absolute bottom-[2px] right-[2px]  flex items-center justify-center">
          <div
            className="p-2 cursor-pointer"
            onClick={() => updateEvent(eventInfo)}
          >
            <BiEditAlt />
          </div>
          <div
            className="p-2 cursor-pointer"
            onClick={() => onRemoveEvent(eventInfo)}
          >
            <BiTrashAlt />
          </div>
        </div>
      </div>
    );
  };

  const onRemoveEvent = (event) => {
    if (formOpened) return;

    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            let calendarApi = calendarRef.current.getApi();
            const ev = calendarApi.getEventById(event.event.id);
            ev.remove();
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const handleRemoveEvent = async (eventInfo) => {
    try {
      await EventsDataService.deleteEvent(eventInfo.event.id);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione effettuata",
        type: "success",
      });
    } catch (err) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  // const fetchEvents = async (fetchInfo, callback) => {
  //   try {
  //     const data = await EventsDataService.fetchEvents(
  //       fetchInfo.start,
  //       fetchInfo.end
  //     );

  //     // const ev = data.docs.filter(
  //     //   (obj) =>
  //     //     obj.data().data.toDate().getFullYear() === new Date().getFullYear() &&
  //     //     obj.data().data.toDate().getMonth() === new Date().getMonth() &&
  //     //     obj.data().data.toDate().getDate() === new Date().getDate()
  //     // );
  //     // fetchEventsC(ev);
  //     callback(
  //       data.docs.map((doc) => {
  //         const evento = {
  //           start: doc.data().data.toDate(),
  //           data: doc.data().data,
  //           title: doc.data().tipologia.nome,
  //           descrizione: doc.data().descrizione,
  //           colore: doc.data().tipologia.colore.value,
  //           tipologia: doc.data().tipologia,
  //           id: doc.id,
  //           allDay: true,
  //           time: `${("0" + doc.data().data.toDate().getHours()).slice(-2)}:${(
  //             "0" + doc.data().data.toDate().getMinutes()
  //           ).slice(-2)}`,
  //         };
  //         return evento;
  //       })
  //     );
  //   } catch (error) {}
  // };

  const toggleForm = (state) => {
    setFormOpened(state);
    if (state) {
      window.scrollTo(0, 0);
    } else {
      setSelectedEvent(null);
    }
  };

  // const addEvent = async (event) => {
  //   try {
  //     if (selectedEvent) {
  //       await EventsDataService.updateEvent(event.id, event);
  //     } else {
  //       await EventsDataService.addEvent(event);
  //     }

  //     // setActiveTodo(null);
  //     toggleForm(false);
  //     Store.addNotification({
  //       ...alertOptions,
  //       message: "Operazione eseguita con successo!",
  //       type: "success",
  //     });
  //   } catch (e) {
  //     console.log(e);
  //     Store.addNotification({
  //       ...alertOptions,
  //       message: "Impossibile completare l'operazione",
  //       type: "danger",
  //     });
  //   }
  // };

  const addTipologia = async (tipologia) => {
    try {
      await TipologieDataService.addTipologia(tipologia);
      getTipologie();
      setAddedTipologia(tipologia);
    } catch (e) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const deleteTipologia = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await TipologieDataService.deleteTipologia(id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              getTipologie();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDateSet = async (data) => {
    const d = await EventsDataService.fetchEvents(data.start, data.end);
    const events = d.docs.map((doc) => {
      const evento = {
        start: doc.data().data.toDate(),
        data: doc.data().data,
        title: doc.data().tipologia.nome,
        descrizione: doc.data().descrizione,
        colore: doc.data().tipologia.colore.value,
        tipologia: doc.data().tipologia,
        id: doc.id,
        allDay: true,
        time: `${("0" + doc.data().data.toDate().getHours()).slice(-2)}:${(
          "0" + doc.data().data.toDate().getMinutes()
        ).slice(-2)}`,
      };
      return evento;
    });
    // let calendarApi = calendarRef.current.getApi();
    // const evs = calendarApi.getEvents();
    // for (const ev of evs) {
    //   ev.remove();
    // }
    // console.log(events);
    setEvents(events);
  };

  const onEventAdded = (event) => {
    let calendarApi = calendarRef.current.getApi();
    const newEvent = {
      start: event.data,
      data: event.data,
      title: event.tipologia.nome,
      descrizione: event.descrizione,
      colore: event.tipologia.colore.value,
      tipologia: event.tipologia,
      allDay: true,
      time: `${("0" + event.data.getHours()).slice(-2)}:${(
        "0" + event.data.getMinutes()
      ).slice(-2)}`,
    };
    if (selectedEvent) {
      newEvent.id = selectedEvent.event.id;
      const newEvents = events.map((ev) =>
        ev.id === newEvent.id ? newEvent : ev
      );
      setEvents(newEvents);
      handleEventAdd(newEvent);
    } else {
      // setEvents([...events, newEvent]);
      calendarApi.addEvent(newEvent);
    }
  };

  const handleEventAdd = async (event) => {
    let newEvent = {};
    if (selectedEvent) {
      newEvent = {
        data: event.data,
        tipologia: event.tipologia,
        descrizione: event.descrizione,
        id: event.id,
      };
    } else {
      newEvent = {
        data: event.event.extendedProps.data,
        tipologia: event.event.extendedProps.tipologia,
        descrizione: event.event.extendedProps.descrizione,
      };
    }
    try {
      if (selectedEvent) {
        await EventsDataService.updateEvent(newEvent.id, newEvent);
      } else {
        await EventsDataService.addEvent(newEvent);
      }

      // setActiveTodo(null);
      toggleForm(false);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione eseguita con successo!",
        type: "success",
      });
    } catch (e) {
      console.log(e);
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  return (
    <div>
      {formTipologiaOpened && (
        <div className="bg-white/80 fixed top-0 left-0 w-full h-full z-50 flex items-start justify-center">
          <TipologiaForm
            toggleTipologiaForm={toggleTipologiaForm}
            addTipologia={addTipologia}
          />
        </div>
      )}
      <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
        <EventsForm
          toggleForm={toggleForm}
          toggleTipologiaForm={toggleTipologiaForm}
          tipologie={tipologie}
          addEvent={onEventAdded}
          dateSelected={dateSelected}
          deleteTipologia={deleteTipologia}
          addedTipologia={addedTipologia}
          selectedEvent={selectedEvent}
        />
      </AnimateHeight>
      <div className="w-full">
        <div className="py-4 md:py-7 rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              Agenda
            </p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        Clicca su una data del calendario per aggiungere un evento.
      </div>
      <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-9 overflow-y-auto ">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dateClick={handleDateClick}
          locale={itLocale}
          events={events}
          eventAdd={(event) => handleEventAdd(event)}
          eventRemove={(event) => handleRemoveEvent(event)}
          eventChange={(event) => handleEventAdd(event)}
          datesSet={(date) => handleDateSet(date)}
          eventContent={renderEventContent}
          ref={calendarRef}
          eventOrder={"start"}
        />
      </div>
    </div>
  );
};

export default Agenda;
