import React, { useState, useEffect } from "react";

const months16 = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu"];

const months712 = ["Lug", "Ago", "Set", "Ott", "Nov", "Dic"];

const CalendarMonth = ({ selectedMonth, handleChangeMonth, fatture }) => {
  const [docsAndTot, setDocsAndTot] = useState([
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
    { docs: 0, tot: 0 },
  ]);

  useEffect(() => {
    creaValues();
  }, [fatture]);

  const creaValues = () => {
    let newValues = [];
    for (let i = 0; i < docsAndTot.length; i++) {
      const docs = filterFatture(i);
      const tot = reduceFatture(i);
      newValues.push({ docs: docs, tot: tot });
    }
    setDocsAndTot(newValues);
  };

  const filterFatture = (month) => {
    return fatture.filter((f) => f.data.toDate().getMonth() === month).length;
  };

  const reduceFatture = (month) => {
    return parseFloat(
      fatture
        .filter((f) => f.data.toDate().getMonth() === month)
        .map((fat) =>
          fat.articoli.reduce(
            (s, p) =>
              s +
              (parseFloat(p.prezzo) * p.qt -
                (parseFloat(p.prezzo) * p.qt * parseFloat(p.sconto)) / 100),
            0
          )
        )
        .reduce((s, x) => s + x, 0)
    ).toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="flex mb-7 shadow-lg shadow-violet-800/20 flex-col md:flex-row text-gray-800 rounded-md overflow-hidden">
      <div className="flex w-full items-center justify-center text-xs ">
        {months16.map((m, i) => (
          <div
            key={i}
            className={`bg-white min-h-[90px] p-3 border-r flex-col text-center cursor-pointer relative border-b-4 hover:bg-orange-50 transition-all ease-in-out duration-300 ${
              i === selectedMonth
                ? "border-b-orange-500 bg-orange-50"
                : "border-b-transparent"
            }`}
            style={{
              flex: "1 1 0px",
              userSelect: "none",
            }}
            onClick={() => handleChangeMonth(i)}
          >
            <div className="font-semibold">{m}</div>
            <div className="absolute bottom-0 left-0 flex items-center flex-col w-full">
              <div className="pb-1">doc {docsAndTot[i].docs}</div>
              <div className="pb-1 font-semibold text-violet-800 text-[0.7rem] md:text-xs">
                {docsAndTot[i].tot} &euro;
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full items-center justify-center text-xs border-t md:border-0">
        {months712.map((m, i) => (
          <div
            key={i}
            className={`bg-white min-h-[90px] p-3 border-r flex-col text-center cursor-pointer relative border-b-4 hover:bg-orange-50 transition-all ease-in-out duration-300 ${
              i + 6 === selectedMonth
                ? "border-b-4 border-b-orange-500  bg-orange-50"
                : "border-b-transparent"
            }`}
            style={{ flex: "1 1 0px", userSelect: "none" }}
            onClick={() => handleChangeMonth(i + 6)}
          >
            <div className="font-semibold">{m}</div>
            <div className="absolute bottom-0 left-0 flex items-center flex-col w-full">
              <div className="pb-1">doc {docsAndTot[i + 6].docs}</div>
              <div className="pb-1 font-semibold text-violet-800 text-[0.7rem] md:text-xs">
                {docsAndTot[i + 6].tot} &euro;
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarMonth;
