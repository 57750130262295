import React from "react";

import Loading from "../loading/Loading";

import {
  BiTrashAlt,
  BiEdit,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";

const ServiziTable = ({
  servizi,
  toggleForm,
  formOpened,
  setServizioActive,
  deleteServizio,
  paginaSeguente,
  paginaPrecedente,
  offset,
  page,
  size,
  isLoadingTable,
}) => {
  return (
    <div className="w-full">
      <div className="relative bg-white shadow-lg shadow-violet-800/20 px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto rounded-md">
        {isLoadingTable && <Loading />}
        <table className="w-full whitespace-nowrap mytable">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-violet-800">
              <th className="font-semibold text-left pl-4">Nome</th>
              <th className="font-semibold text-left pl-24">Codice</th>
              <th className="font-semibold text-left pl-4">Descrizione</th>
              <th className="font-semibold text-left pl-24">Prezzo</th>
              <th className="font-semibold text-left pl-4">Azioni</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {servizi?.map((servizio, i) => (
              <tr
                className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-slate-100 border-b border-t border-gray-100 relative"
                key={i}
              >
                <td className="pl-4 whitespace-normal leading-5 font-semibold">
                  {servizio.nome}
                </td>
                <td className="pl-24">{servizio.codice}</td>
                <td className="pl-4 whitespace-normal leading-5">
                  {servizio.descrizione ? (
                    servizio.descrizione
                  ) : (
                    <span className="text-slate-300">Nessuna descrizione</span>
                  )}
                </td>
                <td className="pl-24">{servizio.prezzo}</td>
                <td className="pl-4">
                  <div className="flex">
                    <div className="group">
                      <button
                        onClick={() => setServizioActive(servizio)}
                        className="p-1 mx-1 ml-0 group-hover:bg-orange-400 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                      </button>
                    </div>
                    <div className="group ">
                      <button
                        disabled={formOpened}
                        onClick={() => deleteServizio(servizio)}
                        className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-7 flex items-center justify-center w-full mb-3">
          <button
            disabled={page === 1}
            onClick={() => paginaPrecedente()}
            className="mr-2 inline-flex items-center justify-between px-4 pl-2 py-3 bg-orange-500 hover:bg-orange-400 disabled:bg-orange-200 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
          >
            <BiChevronLeft className="text-sm text-white mr-2" />
            <p className="text-xs font-medium leading-none text-white">
              Precedente
            </p>
          </button>
          <button
            disabled={page * offset >= size}
            onClick={() => paginaSeguente()}
            className="ml-2 inline-flex items-center justify-between px-4 pr-2 py-3 bg-orange-500 hover:bg-orange-400 disabled:bg-orange-200 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">
              Successivo
            </p>
            <BiChevronRight className="text-sm text-white ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiziTable;
