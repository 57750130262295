import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  doc,
} from "firebase/firestore";

const usciteCollectionRef = collection(db, "uscite");

class UsciteDataService {
  addUscita = (nuovaUscita) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovaUscita.user = userLogged;
    return addDoc(usciteCollectionRef, nuovaUscita);
  };

  fetchUsciteMonth = (start, end) => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      usciteCollectionRef,
      where("user", "==", userLogged),
      where("data", ">=", start),
      where("data", "<=", end),
      orderBy("data", "asc")
    );
    return getDocs(q);
  };

  fetchUsciteYear = (year) => {
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      usciteCollectionRef,
      where("user", "==", userLogged),
      where("data", ">=", start),
      where("data", "<=", end),
      orderBy("data", "asc")
    );
    return getDocs(q);
  };

  updateUscita = (id, updateUscita) => {
    const uscitaDoc = doc(db, "uscite", id);
    return updateDoc(uscitaDoc, updateUscita);
  };

  deleteUscita = (id) => {
    const uscitaDoc = doc(db, "uscite", id);
    return deleteDoc(uscitaDoc);
  };
}

export default new UsciteDataService();
