import React, { useState, useEffect } from "react";

import Select from "react-select";
import selectOptions from "../select/selectOptions";
import selectOptionsTime from "../select/selectOptionsTime";

import { BiPlus, BiTrashAlt } from "react-icons/bi";

const hours = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

const minutes = [
  { value: "00", label: "00" },
  { value: "05", label: "05" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "55", label: "55" },
];

const EventsForm = ({
  toggleForm,
  tipologie,
  toggleTipologiaForm,
  addEvent,
  dateSelected,
  deleteTipologia,
  addedTipologia,
  selectedEvent,
}) => {
  const [tipologia, setTipologia] = useState(null);
  const [descrizione, setDescrizione] = useState("");

  const [ora, setOra] = useState({
    value: ("0" + new Date().getHours()).slice(-2),
    label: ("0" + new Date().getHours()).slice(-2),
  });

  const [minuti, setMinuti] = useState(null);

  useEffect(() => {
    rounded();
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      setValues();
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (addedTipologia) {
      setTipologia(addedTipologia);
    }
  }, [addedTipologia]);

  const setValues = () => {
    setTipologia(selectedEvent.tipologia);
    setDescrizione(selectedEvent.descrizione);
    const hours = ("0" + selectedEvent.data.toDate().getHours()).slice(-2);
    const minutes = ("0" + selectedEvent.data.toDate().getMinutes()).slice(-2);
    setOra({ value: hours, label: hours });
    setMinuti({ value: minutes, label: minutes });
  };

  const resetForm = () => {
    setTipologia("");
    setDescrizione("");
    setOra({
      value: ("0" + new Date().getHours()).slice(-2),
      label: ("0" + new Date().getHours()).slice(-2),
    });
    rounded();
  };

  const rounded = () => {
    var coeff = 1000 * 60 * 5;
    var date = new Date(); //or use any other date
    var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    setMinuti({
      value: ("0" + rounded.getMinutes()).slice(-2),
      label: ("0" + rounded.getMinutes()).slice(-2),
    });
  };

  const openTipologiaForm = (e) => {
    e.preventDefault();
    toggleTipologiaForm(true);
  };

  const creadata = () => {
    if (dateSelected) {
      // const dataArray = dateSelected.dateStr.split("-");
      // return `${dataArray[2]}-${dataArray[1]}-${dataArray[0]}`;
      return `${("0" + dateSelected.getDate()).slice(-2)}-${(
        "0" + dateSelected.getMonth()
      ).slice(-2)}-${dateSelected.getFullYear()}`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataEvento = selectedEvent
      ? selectedEvent.data.toDate()
      : // : dateSelected.date;
        dateSelected;
    dataEvento.setMinutes(minuti.value);
    dataEvento.setHours(ora.value);
    const nuovoEvento = {
      data: dataEvento,
      tipologia: tipologia,
      descrizione: descrizione,
    };
    if (selectedEvent) {
      nuovoEvento.id = selectedEvent.id;
    }
    resetForm();
    addEvent(nuovoEvento);
  };

  const closeForm = () => {
    resetForm();
    toggleForm(false);
  };

  const handleDeleteTipologia = (id) => {
    setTimeout(() => {
      setTipologia(null);
      deleteTipologia(id);
    }, 100);
  };

  return (
    <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-8 overflow-hidden">
      <div className="form-header flex items-center justify-between">
        <div className="header-title text-base font-semibold text-gray-800">
          Nuovo evento {creadata()}
        </div>
        <button
          onClick={() => closeForm()}
          className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none rounded-full  transition-all ease-in-out duration-300 "
        >
          <p className="text-xs font-medium leading-none text-white">Chiudi</p>
        </button>
      </div>
      <form action="" className="mt-8" onSubmit={handleSubmit}>
        <div className="flex items-end">
          <div className="grow">
            <label
              className="text-xs font-semibold leading-none text-gray-800"
              id="tag"
            >
              Tipologia
            </label>
            <Select
              options={tipologie}
              styles={selectOptions}
              className="select"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center grow">
                    <div
                      className="w-[20px] h-[20px] rounded-full mr-3"
                      style={{ backgroundColor: option.colore.value }}
                    ></div>
                    <div>{option.nome}</div>
                  </div>
                  <div onClick={() => handleDeleteTipologia(option.id)}>
                    <BiTrashAlt className="text-red-500 p-1 text-2xl cursor-pointer" />
                  </div>
                </div>
              )}
              noOptionsMessage={() => "Nessun risultato"}
              placeholder="Seleziona tipologia"
              value={tipologia}
              onChange={(value) => setTipologia(value)}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
          <button
            className="bg-violet-800 w-[36px] h-[36px] rounded-full flex items-center justify-center ml-2"
            type="button"
            onClick={(e) => openTipologiaForm(e)}
          >
            <BiPlus className="text-white text-lg" />
          </button>
        </div>
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <div className="grow mr-2">
              <label
                className="text-xs font-semibold leading-none text-gray-800"
                id="descrizione"
              >
                Descrizione
              </label>
              <input
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                type="name"
                tabIndex="0"
                className="w-full p-2 mt-1 bg-slate-100 border rounded-full border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
                aria-labelledby="descrizione"
                placeholder=""
              />
            </div>
            <div className="ml-2 ">
              <label className="text-xs font-semibold leading-none text-gray-800">
                Orario
              </label>
              <div className="flex items-center justify-center border border-gray-200 rounded-full">
                <Select
                  options={hours}
                  isClearable={false}
                  isOptionSelected={false}
                  styles={selectOptionsTime}
                  className="select"
                  placeholder=""
                  value={ora}
                  onChange={(value) => setOra(value)}
                  menuPortalTarget={document.querySelector("body")}
                />
                <div>:</div>
                <Select
                  options={minutes}
                  isClearable={false}
                  isOptionSelected={false}
                  styles={selectOptionsTime}
                  className="select"
                  placeholder=""
                  value={minuti}
                  onChange={(value) => setMinuti(value)}
                  menuPortalTarget={document.querySelector("body")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-6 justify-end">
          <button
            disabled={descrizione === "" || !tipologia}
            className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full  transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">Salva</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventsForm;
