import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { BiChevronLeft } from "react-icons/bi";

import ClientiDataService from "../services/clienti.services";
import FattureDataService from "../services/fatture.services";
import Loading from "./../components/loading/Loading";

import { useYear } from "../context/YearContext";

const Cliente = () => {
  const [cliente, setCliente] = useState(null);
  const [fatture, setFatture] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(null);
  const [totaleImponibilePagato, setTotaleImponibilePagato] = useState(0);
  const [totaleImportoPagato, setTotaleImportoPagato] = useState(0);
  const [totaleImponibileNonPagato, setTotaleImponibileNonPagato] = useState(0);
  const [totaleImportoNonPagato, setTotaleImportoNonPagato] = useState(0);

  const { id } = useParams();

  const { year } = useYear();

  useEffect(() => {
    if (id !== undefined && id !== "") {
      getCliente();
    }
  }, []);

  useEffect(() => {
    fetchFatture();
  }, [year]);

  useEffect(() => {
    if (cliente) {
      fetchFatture();
    }
  }, [cliente]);

  useEffect(() => {
    if (fatture) {
      calcolaTotali();
    }
  }, [fatture]);

  const getCliente = async () => {
    setIsLoadingTable(true);
    const data = await ClientiDataService.getCliente(id);
    setCliente(data.data());
    setIsLoadingTable(false);
  };

  const fetchFatture = async () => {
    setIsLoadingTable(true);
    const data = await FattureDataService.fetchFattureCliente(id, year.value);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    docs.sort((a, b) => (a.data > b.data ? -1 : b.data > a.data ? 1 : 0));
    setFatture(docs);
    setIsLoadingTable(false);
  };

  const creaData = (data) => {
    return `${("0" + data.toDate().getDate()).slice(-2)}-${(
      "0" +
      (data.toDate().getMonth() + 1)
    ).slice(-2)}-${data.toDate().getFullYear()}`;
  };

  const calcolaPercentuale = (numero, percentuale) => {
    return parseFloat(numero - (numero * percentuale) / 100);
  };

  const imponibile = (fattura) => {
    let imponibile = 0;
    for (const articolo of fattura.articoli) {
      const prezzo = parseFloat(articolo.prezzo);
      const qt = articolo.qt;
      const sconto = parseFloat(articolo.sconto);
      imponibile += parseFloat(
        calcolaPercentuale(parseFloat(prezzo) * qt, parseFloat(sconto))
      );
    }
    return imponibile;
  };

  const calcoloIva = (fattura) => {
    let iva = 0;
    const rivalsa = fattura.rivalsa;
    for (const articolo of fattura.articoli) {
      const ivaApplicata = parseFloat(
        articolo.ivaApplicata.value.split("-")[0]
      );
      const prezzo = parseFloat(articolo.prezzo);
      const qt = articolo.qt;
      const sconto = parseFloat(articolo.sconto);
      let imponibile = parseFloat(
        calcolaPercentuale(parseFloat(prezzo) * qt, parseFloat(sconto))
      );
      if (rivalsa) {
        imponibile = imponibile + imponibile * 0.04;
      }
      iva += parseFloat((imponibile * ivaApplicata) / 100);
    }
    return iva;
  };

  const calcoloTotale = (fattura) => {
    const rivalsa = fattura.rivalsa;
    let imp = parseFloat(imponibile(fattura));
    if (rivalsa) {
      imp = imp + imp * 0.04;
    }
    let totale = imp + parseFloat(calcoloIva(fattura));
    if (fattura.caricoCliente) {
      totale += 2;
    }
    return totale;
  };

  const calcolaTotali = () => {
    let impPagato = 0;
    let totalePagato = 0;
    let impNonPagato = 0;
    let totaleNonPagato = 0;
    for (const fattura of fatture) {
      const imp = parseFloat(imponibile(fattura));
      const tot = parseFloat(calcoloTotale(fattura));
      if (!fattura.dataPagamento || fattura.dataPagamento === "") {
        impNonPagato += imp;
        totaleNonPagato += tot;
      } else {
        impPagato += imp;
        totalePagato += tot;
      }
    }
    setTotaleImponibilePagato(impPagato);
    setTotaleImportoPagato(totalePagato);
    setTotaleImponibileNonPagato(impNonPagato);
    setTotaleImportoNonPagato(totaleNonPagato);
  };

  return (
    <div className="text-gray-800">
      <div className="w-full">
        <div className="py-4 md:py-7 rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              {cliente?.denominazione} - {year.value}
            </p>
            <Link
              className="mr-2 inline-flex items-center justify-between px-4 pl-2 py-3 bg-violet-900 hover:bg-violet-700 disabled:bg-orange-200 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
              to="/clienti"
              id="backToFatture"
            >
              <BiChevronLeft className="text-sm text-white mr-2" />
              <p className="text-xs font-medium leading-none text-white">
                Torna ai clienti
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="relative bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto ">
          {isLoadingTable && <Loading />}
          <table className="w-full whitespace-nowrap mytable">
            <thead>
              <tr className="h-16 w-full text-sm leading-none text-violet-800">
                <th className="font-semibold text-left pl-4">Data</th>
                <th className="font-semibold text-left pl-10">Stato</th>
                <th className="font-semibold text-left pl-16">Descrizione</th>
                <th className="font-semibold text-right pl-28">Imponibile</th>
                <th className="font-semibold text-right pl-4 pr-2">
                  Importo totale
                </th>
              </tr>
            </thead>
            <tbody className="w-full">
              {fatture.length > 0 ? (
                fatture.map((fattura, i) => (
                  <tr
                    className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-slate-100 border-b border-t border-gray-100 relative"
                    key={i}
                  >
                    <td className="pl-4">{creaData(fattura.data)}</td>
                    <td className="pl-10">
                      {!fattura.dataPagamento ||
                      fattura.dataPagamento === "" ? (
                        <span className="font-semibold text-[10px] px-4 py-1 bg-orange-200 text-orange-500 rounded-full">
                          DA SALDARE
                        </span>
                      ) : (
                        <span className="font-semibold text-[10px] px-4 py-1 bg-emerald-100 text-emerald-700 rounded-full">
                          SALDATO {creaData(fattura.dataPagamento)}
                        </span>
                      )}
                    </td>
                    <td className="pl-16">{`Fattura n. ${
                      fattura.numero
                    }/${fattura.data.toDate().getFullYear()}`}</td>
                    <td className="pl-28 text-right">
                      {imponibile(fattura).toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      &euro;
                    </td>
                    <td className="pl-4 text-right  pr-2">
                      {calcoloTotale(fattura).toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      &euro;
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-slate-100 border-b border-t border-gray-100">
                  <td colSpan="5" className="pl-4">
                    Nessun documento
                  </td>
                </tr>
              )}
              {fatture.length > 0 && (
                <>
                  <tr className="h-16 text-sm leading-none text-gray-800 bg-emerald-100  border-b border-t border-gray-100">
                    <td className="pl-4"></td>
                    <td className="font-semibold text-left pl-10"></td>
                    <td className="font-semibold text-left pl-16 text-violet-800">
                      Totale pagato
                    </td>
                    <td className="font-semibold text-right pl-28">
                      {totaleImponibilePagato.toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </td>
                    <td className="font-semibold text-right pl-4  pr-2">
                      {totaleImportoPagato.toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      &euro;
                    </td>
                  </tr>
                  <tr className="h-16 text-sm leading-none text-gray-800 bg-orange-100  border-b border-t border-gray-100">
                    <td className="pl-4"></td>
                    <td className="font-semibold text-left pl-10"></td>
                    <td className="font-semibold text-left pl-16 text-violet-800">
                      Totale da pagare
                    </td>
                    <td className="font-semibold text-right pl-28">
                      {totaleImponibileNonPagato.toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      &euro;
                    </td>
                    <td className="font-semibold text-right pl-4  pr-2">
                      {totaleImportoNonPagato.toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      &euro;
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Cliente;
