import React, { useState, useEffect } from "react";

import ClientiForm from "../components/clienti/ClientiForm";
import ClientiTable from "./../components/clienti/ClientiTable";

import ClientiDataService from "../services/clienti.services";

import AnimateHeight from "react-animate-height";

import { confirmAlert } from "react-confirm-alert";
import alertOptions from "../components/alert/alertOptions";

import { Store } from "react-notifications-component";

const offset = 8;

const Clienti = () => {
  const [clienti, setClienti] = useState(null);
  const [formOpened, setFormOpened] = useState(false);

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [cliente, setCliente] = useState(null);

  const [lastDoc, setLastDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);

  const [size, setSize] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getClienti();
  }, []);

  const toggleForm = (state) => {
    setFormOpened(state);
    if (state) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        document.getElementById("den").focus();
      }, 100);
    } else {
      setCliente(null);
    }
  };

  const addCliente = async (nuovoCliente) => {
    try {
      await ClientiDataService.addCliente(nuovoCliente);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione effettuata",
        type: "success",
      });
      getSize();
      getClienti();
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
      }, 100);
    } catch (err) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const updateCliente = async (id, nuovoCliente) => {
    try {
      await ClientiDataService.updateCliente(id, nuovoCliente);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione effettuata",
        type: "success",
      });
      getClienti();
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
      }, 100);
    } catch (err) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const getSize = async () => {
    const data = await ClientiDataService.getSize();
    setSize(data.docs.length);
  };

  const getClienti = async () => {
    setIsLoadingTable(true);
    getSize();
    setPage(1);
    const data = await ClientiDataService.getAllClienti(offset);
    setIsLoadingTable(false);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    setClienti(docs);
    setLastDoc(data.docs[data.docs.length - 1]);
    setFirstDoc(data.docs[0]);
  };

  const paginaSeguente = async () => {
    setIsLoadingTable(true);
    const data = await ClientiDataService.fetchNext(lastDoc, offset);
    setIsLoadingTable(false);
    setPage(page + 1);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setClienti(docs);
    setLastDoc(data.docs[data.docs.length - 1]);
    setFirstDoc(data.docs[0]);
  };

  const paginaPrecedente = async () => {
    setIsLoadingTable(true);
    setPage(page - 1);
    const data = await ClientiDataService.fetchPrev(firstDoc, offset);
    setIsLoadingTable(false);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setClienti(docs.reverse());
    setFirstDoc(data.docs[data.docs.length - 1]);
    setLastDoc(data.docs[0]);
  };

  const setClienteActive = (cliente) => {
    setCliente(cliente);
    toggleForm(true);
  };

  const deleteCliente = (cliente) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await ClientiDataService.deleteCliente(cliente.id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              getSize();
              getClienti();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const searchData = async (term) => {
    if (term.length > 2) {
      const data = await ClientiDataService.searchCliente(term);
      setClienti(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
  };

  return (
    <div>
      <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
        <ClientiForm
          toggleForm={toggleForm}
          addCliente={addCliente}
          updateCliente={updateCliente}
          isSubmitted={isSubmitted}
          cliente={cliente}
        />
      </AnimateHeight>
      <div className="w-full">
        <div className="py-4 md:py-7 rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              Clienti
            </p>
            <button
              disabled={formOpened}
              onClick={() => toggleForm(true)}
              className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300"
            >
              <p className="text-xs font-medium leading-none text-white">
                Nuovo cliente
              </p>
            </button>
          </div>
        </div>
      </div>
      {!isLoadingTable && size === 0 ? (
        <div className="relative bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto rounded-md text-gray-800">
          <div className="font-semibold  mb-3">Nessun cliente</div>
          <div>
            Puoi aggiungere un cliente cliccando sul pulsante{" "}
            <div className="scale-75 inline-flex items-start justify-start px-6 py-3 bg-violet-900 rounded-full">
              <p className="text-xs font-medium leading-none text-white">
                Nuovo cliente
              </p>
            </div>
            in alto a destra.
          </div>
        </div>
      ) : (
        <ClientiTable
          clienti={clienti}
          toggleForm={toggleForm}
          formOpened={formOpened}
          setClienteActive={setClienteActive}
          deleteCliente={deleteCliente}
          searchData={searchData}
          paginaSeguente={paginaSeguente}
          paginaPrecedente={paginaPrecedente}
          isLoadingTable={isLoadingTable}
          page={page}
          offset={offset}
          size={size}
        />
      )}
    </div>
  );
};

export default Clienti;
