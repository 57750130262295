import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  BiX,
  BiUser,
  BiMessageSquareDetail,
  BiBadgeCheck,
  BiLogOut,
  BiBox,
  BiCalendarAlt,
  BiListCheck,
  BiCartAlt,
  BiHomeSmile,
} from "react-icons/bi";

import EventsDataService from "../services/events.services";
import TaskDataService from "../services/task.services";
import TodoDataService from "../services/todo.services";

import { useUserAuth } from "../context/UserAuthContext";
import { useTodos } from "../context/TodosContext";
import { useEvents } from "../context/EventsContext";

const menus = [
  { name: "Home", icon: BiHomeSmile, link: "/situazione" },
  { name: "Clienti", icon: BiUser, link: "/clienti" },
  { name: "Servizi", icon: BiBox, link: "/servizi" },
  { name: "Fatture", icon: BiMessageSquareDetail, link: "/fatture" },
  { name: "Uscite", icon: BiCartAlt, link: "/uscite" },
  { name: "Task", icon: BiListCheck, link: "/task" },
  { name: "Agenda", icon: BiCalendarAlt, link: "/agenda" },
  { name: "Impostazioni", icon: BiBadgeCheck, link: "/impostazioni" },
];

const NavigationCopy = ({ opened, toggle, isMobile }) => {
  const location = useLocation();

  const [active, setActive] = useState("Clienti");

  const [task, setTask] = useState(null);
  const [todosStart, setTodosStart] = useState([]);
  const [events, setEvents] = useState([]);

  const { user, logOut } = useUserAuth();
  const { todos } = useTodos();
  const { eventsC } = useEvents();

  const navigate = useNavigate();

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (user) getTask();
  }, [user]);

  useEffect(() => {
    setEvents(eventsC);
  }, [eventsC]);

  useEffect(() => {
    if (task) {
      fetchTodos();
    }
  }, [task]);

  useEffect(() => {
    setTodosStart(todos);
  }, [todos]);

  useEffect(() => {
    const { pathname } = location;
    setActive(pathname);
  }, [location]);

  const getTask = async () => {
    const data = await TaskDataService.getTask(user.email);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTask(docs[0]);
  };

  const fetchTodos = async () => {
    const data = await TodoDataService.getTodos(
      task.id,
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTodosStart(docs.filter((doc) => doc.stato === "todo"));
  };

  const fetchEvents = async () => {
    const start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    const end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    const data = await EventsDataService.fetchEvents(start, end);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setEvents(docs);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {}
  };

  return (
    <div
      className={`sidebar fixed overflow-hidden overflow-y-auto h-[100vh]  flex  justify-start flex-col  left-0 top-0 bg-sidebar transition-all ease-in-out duration-300  text-sidebarcolor z-30 ${
        opened
          ? isMobile
            ? "ml-0 w-[250px] items-center"
            : "ml-0 w-[250px] items-center"
          : isMobile
          ? "-ml-[250px]"
          : "ml-0 w-[70px] items-center"
      }`}
      style={{ maxHeight: "-webkit-fill-available" }}
    >
      {isMobile && (
        <button className="absolute right-0 top-0 p-2" onClick={() => toggle()}>
          <BiX className="text-3xl" />
        </button>
      )}

      <div className="absolute -top-[2rem] -left-[2rem] lg:w-[350px] lg:h-[350px] w-[250px] h-[250px]  rounded-[50%] blur-[50px] lg:blur-[80px] bg-shape z-0"></div>
      <div
        className={`flex ${
          isMobile ? "py-[30px]" : "py-[70px]"
        } z-10 flex-col justify-between ${
          opened ? "items-start" : "items-center"
        } h-full`}
      >
        <div className={`h-1/4 `}>
          <div className="">
            <div
              className={`flex flex-col items-center justify-end transition-all ease-in-out duration-300 ${
                opened
                  ? isMobile
                    ? "rounded-full w-[50px] h-[50px]"
                    : "rounded-[1.5rem] w-[80px] h-[80px]"
                  : isMobile
                  ? "rounded-[1.5rem] w-[80px] h-[80px]"
                  : "rounded-full w-[40px] h-[40px]"
              } overflow-hidden `}
            >
              <img
                src={user?.photoURL}
                alt=""
                className="w-[80px] object-cover object-center"
                referrerPolicy="no-referrer"
              />
            </div>
            {opened && (
              <div className={`${isMobile ? "mt-2" : "mt-4"}`}>
                {!isMobile && <div className="text-xs">Ciao</div>}
                <h1
                  className={`text-white ${
                    isMobile ? "text-lg" : "text-2xl"
                  } font-semibold break-word w-[150px]`}
                >
                  {user?.displayName}
                </h1>
              </div>
            )}
          </div>
        </div>
        <div className="h-2/4 flex items-center">
          <ul className="font-semibold ">
            {menus.map((menu, i) => (
              <li
                className={`${i === menus.length - 1 ? "" : ""} ${
                  menu.link === active ? "text-white" : ""
                } flex items-center justify-start hover:text-white transition-all ease-in-out duration-300 menu-item`}
                key={i}
                data-tip={menu.name}
              >
                {!opened && !isMobile && (
                  <ReactTooltip
                    place="right"
                    effect="solid"
                    offset={{ bottom: -15 }}
                  />
                )}
                <Link
                  to={menu.link}
                  className={`${
                    opened ? "pb-[0.65rem]" : "pb-[1.45rem]"
                  } flex items-center`}
                  href="#"
                  onClick={() => {
                    setActive(menu.link);
                    if (isMobile) toggle();
                  }}
                >
                  <div
                    className={`flex items-center   h-9 ${
                      !opened && menu.link === active
                        ? "bg-[#4d3262] rounded-lg"
                        : ""
                    } ${
                      !opened
                        ? "justify-center w-9 hover:bg-[#4d3262] hover:rounded-lg"
                        : "justify-start w-8"
                    }`}
                  >
                    <menu.icon
                      className={`text-xl   ${opened ? "mb-[0.1rem]" : ""}`}
                    />
                  </div>
                  <span className={`${!opened ? "hidden " : ""}`}>
                    {menu.name}
                  </span>
                  {menu.name === "Task" && (
                    <div
                      className={`absolute right-3 ${opened ? "" : "-mt-7"}`}
                    >
                      {todosStart.length > 0 && (
                        <div className="bg-orange-500 text-[8px] text-white flex justify-center items-center w-[15px] h-[15px] rounded-full">
                          {todosStart.length}
                        </div>
                      )}
                    </div>
                  )}
                  {menu.name === "Agenda" && (
                    <div
                      className={`absolute right-3 ${opened ? "" : "-mt-7"}`}
                    >
                      {events.length > 0 && (
                        <div className="bg-emerald-500 text-[8px] text-white flex justify-center items-center w-[15px] h-[15px] rounded-full">
                          {events.length}
                        </div>
                      )}
                    </div>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="h-1/4 flex items-end ">
          <ul className="font-semibold ">
            <li
              className="flex items-center justify-start hover:text-white transition-all ease-in-out duration-300 menu-item"
              // data-tip="Logout"
            >
              {/* {!opened && !isMobile && (
                <ReactTooltip
                  place="right"
                  effect="solid"
                  // offset="{'bottom': -15}"
                />
              )} */}
              <a
                href="#"
                className="flex items-center p-3 pl-0"
                onClick={handleLogout}
              >
                <BiLogOut
                  className={`text-xl mb-[0.1rem] ${opened ? "mr-3" : ""}`}
                />
                <span className={`${!opened ? "hidden" : ""}`}>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationCopy;
