import React, { useEffect, useState } from "react";
import UsersDataService from "../services/users.services";
import TaskDataService from "../services/task.services";

import AsyncSelect from "react-select/async";
import selectOptions from "../components/select/selectOptions";

import alertOptions from "../components/alert/alertOptions";
import { Store } from "react-notifications-component";
import { confirmAlert } from "react-confirm-alert";

import AnimateHeight from "react-animate-height";

import {
  BiX,
  BiChevronRight,
  BiTrashAlt,
  BiEdit,
  BiArrowFromRight,
  BiArrowFromLeft,
} from "react-icons/bi";

// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar } from "react-modern-calendar-datepicker";

// import { localeIT } from "../utils/localeIT";

import { useUserAuth } from "../context/UserAuthContext";
import { useTodos } from "../context/TodosContext";
import TaskForm from "../components/tasks/TaskForm";
import TagForm from "./../components/tasks/TagForm";

import TagsDataSertvice from "../services/tags.services";
import TodoDataService from "../services/todo.services";
import Loading from "../components/loading/Loading";
import DatePicker from "./DatePicker";

const today = new Date();

const Task = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState(null);
  const [myTask, setMyTask] = useState(null);
  const [otherTasks, setOtherTasks] = useState([]);
  const [collab, setCollab] = useState(null);
  const [collabs, setCollabs] = useState([]);

  const [activeTodo, setActiveTodo] = useState(null);

  const [todosInMonth, setTodosInMonth] = useState([]);

  const [todos, setTodos] = useState([]);

  const [progress, setProgress] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [addedTag, setAddedTag] = useState(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [selectedDay, setSelectedDay] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [formOpened, setFormOpened] = useState(false);
  const [formTagOpened, setFormTagOpened] = useState(false);

  const [tags, setTags] = useState([]);

  const { user } = useUserAuth();
  const { fetchTodos } = useTodos();

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    fetchTotodosInMonth();
  }, [todosInMonth]);

  const fetchTotodosInMonth = () => {
    if (
      todosInMonth.length > 0 &&
      todosInMonth[0].data.toDate().getMonth() === today.getMonth()
    ) {
      fetchTodos(
        todosInMonth.filter(
          (obj) => sameDate(obj.data.toDate(), today) && obj.stato === "todo"
        )
      );
    }
  };

  useEffect(() => {
    setCollabs(task?.users ? task.users : []);
    if (task) {
      getTodos();
      getTodosInMonth();
    }
  }, [task]);

  useEffect(() => {
    getTodos();
  }, [selectedDay]);

  useEffect(() => {
    if (user) {
      getTask();
      getOtherTasks();
    }
  }, [user]);

  const getOtherTasks = async () => {
    const data = await TaskDataService.getOtherTasks(user.email);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setOtherTasks(docs);
  };

  const getTodos = async () => {
    if (task) {
      setIsLoading(true);
      const data = await TodoDataService.getTodos(
        task.id,
        new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day)
      );
      const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const taskTodos = docs.filter((obj) => obj.stato === "todo");
      const taskProgress = docs.filter((obj) => obj.stato === "progress");
      const taskCompleted = docs.filter((obj) => obj.stato === "completed");
      setTodos(taskTodos);
      setProgress(taskProgress);
      setCompleted(taskCompleted);
      setIsLoading(false);
    }
  };

  const getTodosInMonth = async (year, month, day) => {
    const y = year || selectedDay.year;
    const m = month || selectedDay.month - 1;
    const d = day || selectedDay.day;
    if (task) {
      const data = await TodoDataService.getTodosInMonth(
        task.id,
        new Date(y, m, d)
      );
      const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTodosInMonth(docs);
    }
  };

  const getTags = async () => {
    const data = await TagsDataSertvice.getTags();
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTags(docs);
  };

  const getTask = async () => {
    if (user) {
      const data = await TaskDataService.getTask(user.email);
      const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setTask(docs[0]);
      setMyTask(docs[0]);
      setCollabs(docs[0]?.users ? docs[0].users : []);
    }
  };

  const searchCollabs = async (inputValue) => {
    if (inputValue.length < 3) return;
    const data = await UsersDataService.search(inputValue);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return docs;
  };

  const handleChangeCollabs = async (data) => {
    if (data) {
      try {
        await TaskDataService.addUser(data, task.id, user);
        const exists = collabs.filter((obj) => obj.email === data.email);
        if (exists.length === 0) {
          setCollabs((collabs) => [...collabs, data]);
        }
        setCollab(null);
      } catch (err) {
        Store.addNotification({
          ...alertOptions,
          message: err.message,
          type: "danger",
        });
      }
    }
  };

  const removeCollabs = async (user) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await TaskDataService.removeUser(user, task.id);
              setCollabs(collabs.filter((obj) => obj.email !== user.email));
              Store.addNotification({
                ...alertOptions,
                message: "Utente rimosso",
                type: "success",
              });
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const toggleForm = (state) => {
    const w = window.innerWidth;
    setFormOpened(state);
    if (state) {
      window.scrollTo(0, 0);
      if (w < 1024) {
        setSidebarOpen(false);
      }
    } else {
      setActiveTodo(null);
    }
  };

  const toggleTagForm = (state) => {
    setFormTagOpened(state);
    if (!state) {
      setAddedTag(null);
    }
  };

  const addTag = async (tag) => {
    try {
      await TagsDataSertvice.addTag(tag);
      setAddedTag(tag);
      getTags();
    } catch (e) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const addTodo = async (todo) => {
    todo.task = task.id;
    try {
      if (activeTodo) {
        await TodoDataService.updateTodo(todo.id, todo);
      } else {
        await TodoDataService.addTodo(todo);
      }
      getTodos();
      getTodosInMonth();
      setActiveTodo(null);
      toggleForm(false);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione eseguita con successo!",
        type: "success",
      });
    } catch (e) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const sameDate = (d1, d2) => {
    return (
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate() &&
      d1.getFullYear() === d2.getFullYear()
    );
  };

  const spostaTodo = async (todo, stato) => {
    const { id } = todo;
    todo.stato = stato;
    try {
      setIsLoading(true);
      await TodoDataService.updateTodo(id, todo);
      switch (stato) {
        case "progress":
          setTodos(todos.filter((obj) => obj.id !== id));
          setProgress([...progress, { ...todo, id: id }]);
          getTodosInMonth();
          break;
        case "completed":
          setProgress(progress.filter((obj) => obj.id !== id));
          setCompleted([...completed, { ...todo, id: id }]);
          getTodosInMonth();
          break;
        default:
          break;
      }

      setIsLoading(false);
    } catch (err) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const switchTask = (task) => {
    setTask(task);
  };

  const switchToMyTask = () => {
    setTask(myTask);
  };

  const handleSelectDay = (date) => {
    setSelectedDay({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
    const w = window.innerWidth;
    if (w < 1024) {
      setSidebarOpen(false);
    }
  };

  const handleActiveTodo = (todo) => {
    setActiveTodo(todo);
    toggleForm(true);
  };

  const deleteTodo = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await TodoDataService.deleteTodo(id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              getTodos();
              getTodosInMonth();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteTag = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await TagsDataSertvice.deleteTag(id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              getTags();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      {formTagOpened && (
        <div className="bg-white/80 fixed top-0 left-0 w-full h-full z-50 flex items-start justify-center">
          <TagForm toggleTagForm={toggleTagForm} addTag={addTag} />
        </div>
      )}
      <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
        <TaskForm
          toggleForm={toggleForm}
          toggleTagForm={toggleTagForm}
          tags={tags}
          addTodo={addTodo}
          selectedDay={selectedDay}
          user={user}
          todo={activeTodo}
          deleteTag={deleteTag}
          addedTag={addedTag}
        />
      </AnimateHeight>
      <div className="w-full relative">
        <button
          className={`fixed bg-white w-[30px] h-[30px] top-[60px] ${
            sidebarOpen ? "left-[330px]" : "left-0"
          } flex lg:hidden items-center justify-center rounded-r-md shadow-md  transition-all ease-in-out duration-300`}
          onClick={() => toggleSidebar()}
        >
          {sidebarOpen ? (
            <BiArrowFromRight className="text-lg text-violet-900" />
          ) : (
            <BiArrowFromLeft className="text-lg text-violet-900" />
          )}
        </button>
        <div className="py-4 md:py-7 rounded-tl-lg rounded-tr-lg">
          <div className="flex items-start md:items-center justify-between flex-col md:flex-row min-h-[40px]">
            <div className="flex items-center justify-between w-full md:w-1/3">
              <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800 w-full">
                Task Manager
              </p>
              <div className="collabs flex items-center justify-end  w-1/3 md:hidden">
                {collabs.length > 0 ? (
                  collabs.map((c, i) => (
                    <div className="-ml-1 relative" key={i}>
                      <div
                        className="absolute -left-[5px] -bottom-[5px] w-[20px] h-[20px] bg-rose-600 rounded-full border border-white flex items-center justify-center cursor-pointer"
                        onClick={() => removeCollabs(c)}
                      >
                        <BiX className="text-white" />
                      </div>
                      <img
                        src={c.img}
                        alt=""
                        className="w-[40px] h-[40px] rounded-full shadow-md shadow-violet-800/20 border-2 border-white"
                        referrerPolicy="no-referrer"
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-slate-400 text-xs">
                    Nessuna condivisione
                  </div>
                )}
              </div>
            </div>
            {task?.admin === user?.email && (
              <div className="w-full mt-3 md:mt-0  md:w-1/3">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  onChange={handleChangeCollabs}
                  filterOption={false}
                  isClearable
                  placeholder="Condividi con..."
                  loadOptions={searchCollabs}
                  styles={selectOptions}
                  className="select"
                  value={collab}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => (
                    <div className="flex items-center">
                      <img
                        className="w-[30px] h-[30px] rounded-full"
                        src={option.img}
                        referrerPolicy="no-referrer"
                      />
                      <div className="ml-2">{option.email}</div>
                    </div>
                  )}
                  menuPortalTarget={document.querySelector("body")}
                  noOptionsMessage={(value) =>
                    value.inputValue.length < 3
                      ? "Inserisci almeno 3 caratteri"
                      : "Nessun risultato"
                  }
                />
              </div>
            )}
            {task?.admin === user?.email && (
              <div className="collabs md:flex items-center justify-end  w-1/3 hidden">
                {collabs.length > 0 ? (
                  collabs.map((c, i) => (
                    <div className="-ml-1 relative" key={i}>
                      <div
                        className="absolute -left-[5px] -bottom-[5px] w-[20px] h-[20px] bg-rose-600 rounded-full border border-white flex items-center justify-center cursor-pointer"
                        onClick={() => removeCollabs(c)}
                      >
                        <BiX className="text-white" />
                      </div>
                      <img
                        src={c.img}
                        alt=""
                        className="w-[40px] h-[40px] rounded-full shadow-md shadow-violet-800/20 border-2 border-white"
                        referrerPolicy="no-referrer"
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-slate-400 text-xs">
                    Nessuna condivisione
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex">
        <div
          className={`fixed bg-white lg:bg-transparent p-3 pr-2 lg:p-0 lg:pr-0 h-full lg:h-auto left-0 lg:relative top-[45px] lg:top-[inherit] lg:flex flex-col  transition-all ease-in-out duration-300 lg:shadow-none shadow-lg ${
            sidebarOpen ? "-ml-0" : "-ml-[350px] lg:-ml-0"
          }`}
        >
          <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md pt-4 md:pt-7 overflow-hidden mr-3 w-[300px] h-fit flex flex-col items-center px-8">
            {task?.admin === user?.email && (
              <button
                disabled={formOpened}
                onClick={() => toggleForm(true)}
                className="inline-flex items-center justify-center px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300 w-full mb-4"
              >
                <p className="text-xs font-medium leading-none text-white">
                  Nuovo task
                </p>
              </button>
            )}
            <DatePicker
              onChange={(value) => handleSelectDay(value)}
              events={todosInMonth}
              getTodosInMonth={getTodosInMonth}
              value={
                new Date(
                  selectedDay.year,
                  selectedDay.month - 1,
                  selectedDay.day
                )
              }
            />
          </div>
          <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md pt-4 md:pt-7 overflow-hidden mr-3 w-[300px] h-fit flex flex-col items-center mt-3">
            <div className="flex items-start flex-col w-full pb-6">
              <div
                className="flex h-10 bg-white items-center cursor-pointer transition-all ease-in-out duration-300 hover:!bg-slate-100 w-full px-6"
                style={{
                  backgroundColor:
                    myTask?.admin === task?.admin
                      ? "rgb(241 245 249)"
                      : "white",
                }}
                onClick={() => switchToMyTask()}
              >
                Il mio task
              </div>
              <div className="text-xs font-semibold  px-6 mt-3 mb-4">
                Condivisi con me
              </div>
              {otherTasks.length > 0 ? (
                otherTasks.map((obj, i) => (
                  <div
                    className="flex h-10 bg-white items-center cursor-pointer transition-all ease-in-out duration-300 hover:!bg-slate-100 w-full px-6 border-b border-b-slate-200"
                    style={{
                      backgroundColor:
                        obj.admin === task?.admin
                          ? "rgb(241 245 249)"
                          : "white",
                    }}
                    key={i}
                    onClick={() => switchTask(obj)}
                  >
                    <img
                      src={obj.img}
                      alt=""
                      className="w-[25px] h-[25px] rounded-full"
                      referrerPolicy="no-referrer"
                    />
                    <div className="text-xs ml-3">{obj.nome}</div>
                  </div>
                ))
              ) : (
                <div className="text-slate-400 font-semibold text-xs  px-6">
                  Nessuna condivisione
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[calc(100%-300px)] flex flex-col md:flex-row h-auto lg:h-[calc(100vh-200px)] lg:overflow-y-auto lg:scrollbar-hide">
          {isLoading && <Loading />}
          <div className="todo w-full md:w-1/3">
            <div className="font-semibold text-[12px]">Da fare</div>
            {todos.length === 0 ? (
              <div className="text-slate-400 text-xs mt-3">
                Nessun task in programma
              </div>
            ) : (
              todos.map((todo, i) => (
                <div
                  className="flex bg-white shadow-md rounded-md px-4 py-4 flex-col mt-3"
                  key={i}
                >
                  <div
                    className="self-start px-7 py-2 mb-4 md:mb-0 rounded-md flex items-center justify-center"
                    style={{ backgroundColor: todo.tag.colore.valueLight }}
                  >
                    <div className="flex items-center">
                      <span
                        className="text-xs font-semibold uppercase"
                        style={{ color: todo.tag.colore.value }}
                      >
                        {todo.tag.nome}
                      </span>
                    </div>
                  </div>
                  <p className="mt-3 text-[13px]">{todo.descrizione}</p>
                  <div className="flex mt-3 items-center justify-between">
                    <button
                      className="text-violet-600 font-semibold text-xs rounded-full bg-violet-200 hover:bg-violet-300 px-3 py-1 transition-all ease-in-out duration-300 flex items-center"
                      onClick={() => spostaTodo(todo, "progress")}
                    >
                      <div>in corso</div>
                      <BiChevronRight className="ml-1" />
                    </button>
                    {task?.admin === user?.email && (
                      <div className="flex items-center justify-end">
                        <div className="group">
                          <button
                            disabled={formOpened}
                            onClick={() => handleActiveTodo(todo)}
                            className="p-1 mx-1 group-hover:bg-orange-400 rounded-full disabled:opacity-25 transition-all ease-in-out duration-300"
                          >
                            <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                          </button>
                        </div>
                        <div className="group ">
                          <button
                            disabled={formOpened}
                            onClick={() => deleteTodo(todo.id)}
                            className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                          >
                            <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="inprogress  w-full md:w-1/3  ml-0 md:ml-3 mt-5 md:mt-0">
            <div className="font-semibold text-[12px]">In corso</div>
            {progress.length === 0 ? (
              <div className="text-slate-400 text-xs mt-3">
                Nessun task in corso
              </div>
            ) : (
              progress.map((todo, i) => (
                <div
                  className="flex bg-white shadow-md rounded-md px-4 py-4 flex-col mt-3"
                  key={i}
                >
                  <div
                    className="self-start px-7 py-2  mb-4 md:mb-0 rounded-md flex items-center justify-center"
                    style={{ backgroundColor: todo.tag.colore.valueLight }}
                  >
                    <div className="flex items-center">
                      <span
                        className="text-xs font-semibold uppercase"
                        style={{ color: todo.tag.colore.value }}
                      >
                        {todo.tag.nome}
                      </span>
                    </div>
                  </div>
                  <p className="mt-3 text-[13px]">{todo.descrizione}</p>
                  <div className="flex mt-3 items-center justify-between">
                    <button
                      className="text-emerald-600 font-semibold text-xs rounded-full bg-emerald-200 hover:bg-emerald-300 px-3 py-1 transition-all ease-in-out duration-300 flex items-center"
                      onClick={() => spostaTodo(todo, "completed")}
                    >
                      <div>completato</div>
                      <BiChevronRight className="ml-1" />
                    </button>
                    {task?.admin === user?.email && (
                      <div className="flex items-center justify-end">
                        <div className="group">
                          <button
                            disabled={formOpened}
                            onClick={() => handleActiveTodo(todo)}
                            className="p-1 mx-1 group-hover:bg-orange-400 rounded-full disabled:opacity-25 transition-all ease-in-out duration-300"
                          >
                            <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                          </button>
                        </div>
                        <div className="group ">
                          <button
                            disabled={formOpened}
                            onClick={() => deleteTodo(todo.id)}
                            className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                          >
                            <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="completed  w-full md:w-1/3  ml-0 md:ml-3 mt-5 md:mt-0">
            <div className="font-semibold text-[12px]">Completati</div>
            {completed.length === 0 ? (
              <div className="text-slate-400 text-xs mt-3">
                Nessun task completato
              </div>
            ) : (
              completed.map((todo, i) => (
                <div
                  className="flex bg-white shadow-md rounded-md px-4 py-4 flex-col mt-3"
                  key={i}
                >
                  <div
                    className="self-start px-7 py-2  mb-4 md:mb-0 rounded-md flex items-center justify-center"
                    style={{ backgroundColor: todo.tag.colore.valueLight }}
                  >
                    <div className="flex items-center">
                      <span
                        className="text-xs font-semibold uppercase"
                        style={{ color: todo.tag.colore.value }}
                      >
                        {todo.tag.nome}
                      </span>
                    </div>
                  </div>
                  <p className="mt-3 text-[13px]">{todo.descrizione}</p>
                  {task?.admin === user?.email && (
                    <div className="flex items-center justify-end">
                      <div className="group">
                        <button
                          disabled={formOpened}
                          onClick={() => handleActiveTodo(todo)}
                          className="p-1 mx-1 group-hover:bg-orange-400 rounded-full disabled:opacity-25 transition-all ease-in-out duration-300"
                        >
                          <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                        </button>
                      </div>
                      <div className="group ">
                        <button
                          disabled={formOpened}
                          onClick={() => deleteTodo(todo.id)}
                          className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                        >
                          <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
        {/* <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-8 overflow-hidden grow">
          <div className="form-header flex items-center justify-between">
            <div className="header-title text-base font-semibold text-gray-800"></div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Task;
