import { db } from "../firebase";

import { collection, getDocs, addDoc, query, where } from "firebase/firestore";

const usersCollectionRef = collection(db, "users");

class UsersDataService {
  addUser = (nuovoUser) => {
    return addDoc(usersCollectionRef, nuovoUser);
  };
  getUser = (email) => {
    const q = query(usersCollectionRef, where("email", "==", email));
    return getDocs(q);
  };
  search = (terms) => {
    const q = query(
      usersCollectionRef,
      where("email", ">=", terms),
      where("email", "<=", terms + "\uf8ff")
    );
    return getDocs(q);
  };
}

export default new UsersDataService();
