import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  updateDoc,
  doc,
  limit,
} from "firebase/firestore";

const profiloCollectionRef = collection(db, "profilo");

class ProfiloDataService {
  addProfilo = (nuovoProfilo) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovoProfilo.user = userLogged;
    return addDoc(profiloCollectionRef, nuovoProfilo);
  };

  updateProfilo = (id, nuovoProfilo) => {
    const profiloDoc = doc(db, "profilo", id);
    return updateDoc(profiloDoc, nuovoProfilo);
  };

  getProfilo = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(
      profiloCollectionRef,
      where("user", "==", userLogged),
      limit(1)
    );
    return getDocs(q);
  };
}

export default new ProfiloDataService();
