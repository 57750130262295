import { createContext, useState, useContext } from "react";

const eventsContext = createContext();

export function EventsContextProvider({ children }) {
  const [eventsC, setEventsC] = useState([]);

  function fetchEventsC(events) {
    setEventsC(events);
  }

  return (
    <eventsContext.Provider value={{ eventsC, fetchEventsC }}>
      {children}
    </eventsContext.Provider>
  );
}

export function useEvents() {
  return useContext(eventsContext);
}
