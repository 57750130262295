import React, { useState, useEffect } from "react";

import Select from "react-select";
import selectOptions from "../select/selectOptions";

import { BiPlus, BiTrashAlt } from "react-icons/bi";

const TaskForm = ({
  toggleForm,
  toggleTagForm,
  tags,
  addTodo,
  selectedDay,
  user,
  todo,
  deleteTag,
  addedTag,
}) => {
  const [tag, setTag] = useState(null);
  const [descrizione, setDescrizione] = useState("");

  useEffect(() => {
    if (todo) {
      setValues();
    }
  }, [todo]);

  useEffect(() => {
    if (addedTag) {
      setTag(addedTag);
    }
  }, [addedTag]);

  const setValues = () => {
    setTag(todo.tag);
    setDescrizione(todo.descrizione);
  };

  const closeForm = () => {
    resetForm();
    toggleForm(false);
  };

  const resetForm = () => {
    setTag(null);
    setDescrizione("");
  };

  const openTagForm = (e) => {
    e.preventDefault();
    toggleTagForm(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const nuovoTodo = {
      stato: "todo",
      data: new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day),
      apertoDa: {
        nome: user.displayName,
        img: user.photoURL,
        email: user.email,
      },
      descrizione: descrizione,
      tag: tag,
    };
    if (todo) {
      nuovoTodo.id = todo.id;
    }
    resetForm();
    addTodo(nuovoTodo);
  };

  const handleDeleteTag = (id) => {
    setTimeout(() => {
      setTag(null);
      deleteTag(id);
    }, 100);
  };

  return (
    <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-8 overflow-hidden">
      <div className="form-header flex items-center justify-between">
        <div className="header-title text-base font-semibold text-gray-800">
          {todo
            ? "Modifica Task"
            : "Nuovo Task " +
              ("0" + selectedDay.day).slice(-2) +
              "-" +
              ("0" + selectedDay.month).slice(-2) +
              "-" +
              selectedDay.year}
        </div>
        <button
          onClick={() => closeForm()}
          className="inline-flex items-start justify-start px-6 py-3 bg-orange-500 hover:bg-orange-400 focus:outline-none rounded-full  transition-all ease-in-out duration-300 "
        >
          <p className="text-xs font-medium leading-none text-white">Chiudi</p>
        </button>
      </div>
      <form action="" className="mt-8" onSubmit={handleSubmit}>
        <div className="flex items-end">
          <div className="grow">
            <label
              className="text-xs font-semibold leading-none text-gray-800"
              id="tag"
            >
              Tag
            </label>
            <Select
              options={tags}
              styles={selectOptions}
              className="select"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center grow">
                    <div
                      className="w-[20px] h-[20px] rounded-full mr-3"
                      style={{ backgroundColor: option.colore.value }}
                    ></div>
                    <div>{option.nome}</div>
                  </div>
                  <div onClick={() => handleDeleteTag(option.id)}>
                    <BiTrashAlt className="text-red-500 p-1 text-2xl cursor-pointer" />
                  </div>
                </div>
              )}
              noOptionsMessage={() => "Nessun risultato"}
              placeholder="Seleziona tag"
              value={tag}
              onChange={(value) => setTag(value)}
              menuPortalTarget={document.querySelector("body")}
            />
          </div>
          <button
            className="bg-violet-800 w-[36px] h-[36px] rounded-full flex items-center justify-center ml-2"
            type="button"
            onClick={(e) => openTagForm(e)}
          >
            <BiPlus className="text-white text-lg" />
          </button>
        </div>
        <div className="mt-3">
          <label
            className="text-xs font-semibold leading-none text-gray-800"
            id="descrizione"
          >
            Descrizione
          </label>
          <textarea
            value={descrizione}
            onChange={(e) => setDescrizione(e.target.value)}
            rows="7"
            tabIndex="0"
            className="min-h-[110px] resize-none w-full p-2 mt-1 bg-slate-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-xs font-medium leading-none text-gray-800"
            aria-labelledby="descrizione"
            placeholder=""
          ></textarea>
        </div>
        <div className="flex mt-6 justify-end">
          <button
            disabled={descrizione === "" || !tag}
            className="inline-flex items-start justify-start px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none disabled:bg-violet-300 rounded-full  transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">
              {todo ? "Salva modifiche" : "Salva"}
            </p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default TaskForm;
