import React from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../loading/Loading";

import {
  BiTrendingUp,
  BiTrashAlt,
  BiEdit,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";

const ClientiTable = ({
  clienti,
  toggleForm,
  formOpened,
  setClienteActive,
  deleteCliente,
  paginaSeguente,
  paginaPrecedente,
  offset,
  page,
  size,
  isLoadingTable,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="relative bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto ">
        {isLoadingTable && <Loading />}
        <table className="w-full whitespace-nowrap mytable">
          <thead>
            <tr className="h-16 w-full text-sm leading-none text-violet-800">
              <th className="font-semibold text-left pl-4">Cliente</th>
              <th className="font-semibold text-left pl-12">Codice</th>
              <th className="font-semibold text-left pl-12">Partia IVA</th>
              <th className="font-semibold text-left pl-20">Codice fiscale</th>
              <th className="font-semibold text-left pl-20">Email</th>
              <th className="font-semibold text-left pl-16">Azioni</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {clienti?.map((cliente, i) => (
              <tr
                className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-slate-100 border-b border-t border-gray-100 relative"
                key={i}
              >
                {/* <td className="pl-4"> */}
                <td>
                  <div className="flex items-center">
                    {/* <div className="w-10 h-10">
                      <img
                        className="w-full h-full rounded-full"
                        src={customer}
                      />
                    </div> */}
                    <div className="pl-4">
                      <p className="font-semibold">{cliente.denominazione}</p>
                      <p className="text-xs leading-3 text-gray-600 pt-2">
                        {cliente.referente ? (
                          cliente.referente
                        ) : (
                          <span className="text-slate-300">
                            Nessun referente
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="pl-12">
                  {cliente.codiceInterno ? (
                    cliente.codiceInterno
                  ) : (
                    <span className="text-slate-300">Nessun codice</span>
                  )}
                </td>
                <td className="pl-12">
                  {cliente.piva ? (
                    cliente.piva
                  ) : (
                    <span className="text-slate-300">Nessuna partita iva</span>
                  )}
                </td>
                <td className="pl-20">
                  {cliente.codfis ? (
                    cliente.codfis
                  ) : (
                    <span className="text-slate-300">
                      Nessun codice fiscale
                    </span>
                  )}
                </td>
                <td className="pl-20">
                  {cliente.email ? (
                    cliente.email
                  ) : (
                    <span className="text-slate-300">Nessuna email</span>
                  )}
                </td>
                <td className="pl-4">
                  <div className="flex">
                    <div className="group">
                      <button
                        className="p-1 mx-1 ml-0 group-hover:bg-violet-600 rounded-full transition-all ease-in-out duration-300"
                        onClick={() => navigate(`/clienti/${cliente.id}`)}
                      >
                        <BiTrendingUp className="text-lg text-violet-600 group-hover:text-white  transition-all ease-in-out duration-300" />
                      </button>
                    </div>
                    <div className="group">
                      <button
                        onClick={() => setClienteActive(cliente)}
                        className="p-1 mx-1 group-hover:bg-orange-400 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiEdit className="text-lg text-orange-400 group-hover:text-white" />
                      </button>
                    </div>
                    <div className="group ">
                      <button
                        disabled={formOpened}
                        onClick={() => deleteCliente(cliente)}
                        className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-7 flex items-center justify-center w-full mb-3">
          <button
            disabled={page === 1}
            onClick={() => paginaPrecedente()}
            className="mr-2 inline-flex items-center justify-between px-4 pl-2 py-3 bg-orange-500 hover:bg-orange-400 disabled:bg-orange-200 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
          >
            <BiChevronLeft className="text-sm text-white mr-2" />
            <p className="text-xs font-medium leading-none text-white">
              Precedente
            </p>
          </button>
          <button
            disabled={page * offset >= size}
            onClick={() => paginaSeguente()}
            className="ml-2 inline-flex items-center justify-between px-4 pr-2 py-3 bg-orange-500 hover:bg-orange-400 disabled:bg-orange-200 focus:outline-none rounded-full transition-all ease-in-out duration-300 "
          >
            <p className="text-xs font-medium leading-none text-white">
              Successivo
            </p>
            <BiChevronRight className="text-sm text-white ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientiTable;
