import React, { useEffect, useState } from "react";
import EntrateUscite from "../components/situazione/EntrateUscite";

import { Link } from "react-router-dom";

import { useYear } from "../context/YearContext";
import { useUserAuth } from "../context/UserAuthContext";

import FattureDataService from "../services/fatture.services";
import UsciteDataService from "../services/uscite.services";
import EventsDataService from "../services/events.services";
import TaskDataService from "../services/task.services";
import TodoDataService from "../services/todo.services";

import { BiDollarCircle, BiExit, BiData } from "react-icons/bi";

import Loading from "./../components/loading/Loading";

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

const Home = () => {
  const [fatture, setFatture] = useState([]);
  const [uscite, setUscite] = useState([]);

  const [entrateAnnue, setEntrateAnnue] = useState(0);
  const [usciteAnnue, setUsciteAnnue] = useState(0);

  const [isLoadingTodo, setIsLoadingTodo] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingFatture, setIsLoadingFatture] = useState(false);
  const [isLoadingUscite, setIsLoadingUscite] = useState(false);

  const [events, setEvents] = useState([]);
  const [task, setTask] = useState(null);
  const [todos, setTodos] = useState([]);

  const { year } = useYear();
  const { user } = useUserAuth();

  useEffect(() => {
    fetchFatture();
    fetchUscite();
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchFatture();
    fetchUscite();
  }, [year]);

  useEffect(() => {
    calcolaEntrate();
  }, [fatture]);

  useEffect(() => {
    calcolaUscite();
  }, [uscite]);

  useEffect(() => {
    if (user) {
      getTask();
    }
  }, [user]);

  useEffect(() => {
    if (task) {
      fetchTodos();
    }
  }, [task]);

  const fetchEvents = async () => {
    const start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    const end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    setIsLoadingEvents(true);
    const data = await EventsDataService.fetchEvents(start, end);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setEvents(docs);
    setIsLoadingEvents(false);
  };

  const getTask = async () => {
    const data = await TaskDataService.getTask(user.email);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTask(docs[0]);
  };

  const fetchTodos = async () => {
    setIsLoadingTodo(true);
    const data = await TodoDataService.getTodos(
      task.id,
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTodos(docs.filter((doc) => doc.stato === "todo"));
    setIsLoadingTodo(false);
  };

  const calcolaEntrate = () => {
    const entrate = parseFloat(
      fatture
        .map((fat) =>
          fat.articoli.reduce(
            (s, p) =>
              s +
              (parseFloat(p.prezzo) * p.qt -
                (parseFloat(p.prezzo) * p.qt * parseFloat(p.sconto)) / 100),
            0
          )
        )
        .reduce((s, x) => s + x, 0)
    );
    setEntrateAnnue(entrate);
  };

  const calcolaUscite = () => {
    const usciteA = parseFloat(
      uscite.reduce((x, usc) => x + parseFloat(usc.importo), 0)
    );
    setUsciteAnnue(usciteA);
  };

  const fetchFatture = async () => {
    setIsLoadingFatture(true);
    const data = await FattureDataService.fetchFattureYear(
      localStorage.getItem("currentAnno")
    );
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFatture(docs);
    setIsLoadingFatture(false);
  };

  const fetchUscite = async () => {
    setIsLoadingUscite(true);
    const data = await UsciteDataService.fetchUsciteYear(
      localStorage.getItem("currentAnno")
    );
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setUscite(docs);
    setIsLoadingUscite(false);
  };

  const creaData = () => {
    const today = new Date();
    return `${("0" + today.getDate()).slice(-2)} ${
      months[today.getMonth()]
    } ${today.getFullYear()}`;
  };

  return (
    <div className="">
      {(isLoadingTodo ||
        isLoadingEvents ||
        isLoadingFatture ||
        isLoadingUscite) && <Loading />}
      <div className="flex gap-4 lg:gap-8 flex-col-reverse xl:flex-row">
        <div className="containerLeft grow flex gap-4 lg:gap-8 flex-col">
          <div className="row1 flex gap-4 lg:gap-8">
            <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md w-auto flex-1 items-center justify-center flex lg:p-9 p-2 flex-col lg:flex-row py-5 lg:py-9">
              <div className="flex items-center justify-center bg-orange-100 p-2 lg:p-3 rounded-xl">
                <BiDollarCircle className="text-[40px] text-orange-800" />
              </div>
              <div className="flex flex-col items-center lg:items-start ml-0 lg:ml-4 mt-3 lg:mt-0">
                <div className="text-slate-400 mb-1 text-center text-xs lg:text-normal max-w-[67px] md:max-w-[100px] lg:max-w-full ">
                  TOTALE ENTRATE ANNUE
                </div>
                <div className="text-md lg:text-3xl font-semibold ">
                  {entrateAnnue.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md w-auto flex-1 items-center justify-center flex lg:p-9 p-2 flex-col lg:flex-row py-5 lg:py-9">
              <div className="flex items-center justify-center bg-rose-100 p-2 lg:p-3 rounded-xl">
                <BiExit className="text-[40px] text-rose-800" />
              </div>
              <div className="flex flex-col items-center lg:items-start ml-0 lg:ml-4 mt-3 lg:mt-0">
                <div className="text-slate-400 mb-1 text-center text-xs lg:text-normal max-w-[67px] md:max-w-[100px] lg:max-w-full ">
                  TOTALE USCITE ANNUE
                </div>
                <div className="text-md lg:text-3xl font-semibold ">
                  {usciteAnnue.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md w-auto flex-1 items-center justify-center flex lg:p-9 p-2 flex-col lg:flex-row py-5 lg:py-9">
              <div className="flex items-center justify-center bg-emerald-100 p-2 lg:p-3 rounded-xl">
                <BiData className="text-[40px] text-emerald-800" />
              </div>
              <div className="flex flex-col items-center lg:items-start ml-0 lg:ml-4 mt-3 lg:mt-0">
                <div className="text-slate-400 mb-1 text-center text-xs lg:text-normal max-w-[67px] md:max-w-[100px] lg:max-w-full ">
                  TOTALE UTILE ANNUO
                </div>
                <div className="text-md lg:text-3xl font-semibold ">
                  {(entrateAnnue - usciteAnnue).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </div>
              </div>
            </div>
          </div>
          <div className="row2 flex gap-4 lg:gap-8">
            <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md w-auto flex-1 items-center justify-center flex lg:p-9 p-2 flex-col lg:flex-row py-5 lg:py-9">
              <div className="flex items-center justify-center bg-orange-100 p-2 lg:p-3 rounded-xl">
                <BiDollarCircle className="text-[40px] text-orange-800" />
              </div>
              <div className="flex flex-col items-center lg:items-start ml-0 lg:ml-4 mt-3 lg:mt-0">
                <div className="text-slate-400 mb-1 text-center text-xs lg:text-normal max-w-[67px] md:max-w-[100px] lg:max-w-full ">
                  MEDIA ENTRATE MENSILI
                </div>
                <div className="text-md lg:text-3xl font-semibold ">
                  {(
                    entrateAnnue / parseInt(new Date().getMonth() + 1)
                  ).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md w-auto flex-1 items-center justify-center flex lg:p-9 p-2 flex-col lg:flex-row py-5 lg:py-9">
              <div className="flex items-center justify-center bg-rose-100 p-2 lg:p-3 rounded-xl">
                <BiExit className="text-[40px] text-rose-800" />
              </div>
              <div className="flex flex-col items-center lg:items-start ml-0 lg:ml-4 mt-3 lg:mt-0">
                <div className="text-slate-400 mb-1 text-center text-xs lg:text-normal max-w-[67px] md:max-w-[100px] lg:max-w-full ">
                  MEDIA USCITE MENSILI
                </div>
                <div className="text-md lg:text-3xl font-semibold ">
                  {(
                    usciteAnnue / parseInt(new Date().getMonth() + 1)
                  ).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </div>
              </div>
            </div>
            <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md w-auto flex-1 items-center justify-center flex lg:p-9 p-2 flex-col lg:flex-row py-5 lg:py-9">
              <div className="flex items-center justify-center bg-emerald-100 p-2 lg:p-3 rounded-xl">
                <BiData className="text-[40px] text-emerald-800" />
              </div>
              <div className="flex flex-col items-center lg:items-start ml-0 lg:ml-4 mt-3 lg:mt-0">
                <div className="text-slate-400 mb-1 text-center text-xs lg:text-normal max-w-[67px] md:max-w-[100px] lg:max-w-full ">
                  MEDIA UTILE MENSILE
                </div>
                <div className="text-md lg:text-3xl font-semibold ">
                  {(
                    (entrateAnnue - usciteAnnue) /
                    parseInt(new Date().getMonth() + 1)
                  ).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;&euro;
                </div>
              </div>
            </div>
          </div>
          <div className="chart">
            <EntrateUscite uscite={uscite} fatture={fatture} year={year} />
          </div>
        </div>
        <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-5 min-w-auto lg:min-w-[400px] flex flex-col">
          <div className="font-semibold mb-4">
            Appuntamenti di oggi {creaData()}
          </div>
          {events.length > 0 ? (
            events.map((e, i) => (
              <div key={i} className="flex border-b items-center">
                <div className="flex py-6 px-4 border-r text-violet-900 font-semibold">
                  {("0" + e.data.toDate().getHours()).slice(-2)}:
                  {("0" + e.data.toDate().getMinutes()).slice(-2)}
                </div>
                <div className="px-4 py-4">
                  <div
                    className="font-semibold"
                    style={{ color: e.tipologia.colore.value }}
                  >
                    {e.tipologia.nome}
                  </div>
                  <div className="text-slate-500 text-xs">{e.descrizione}</div>
                </div>
              </div>
            ))
          ) : (
            <div>Nessun appuntamento in programma</div>
          )}
          <Link
            className="inline-flex items-center justify-center px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300 mt-6 mb-9"
            to="/agenda"
          >
            <p className="text-xs font-medium leading-none text-white">
              {events.length > 0
                ? "Vai agli appuntamenti"
                : "Aggiungi appuntamento"}
            </p>
          </Link>
          <div className="font-semibold mb-2 mt-9">
            Da fare oggi {creaData()}
          </div>
          {todos.length > 0 ? (
            todos.map((todo, i) => (
              <div
                key={i}
                className="border-b py-4 flex flex-col items-start justify-center"
              >
                <div
                  className="self-start px-7  py-2  mb-0 rounded-md flex items-center justify-center"
                  style={{ backgroundColor: todo.tag.colore.valueLight }}
                >
                  <div className="flex items-center">
                    <span
                      className="text-xs font-semibold uppercase"
                      style={{ color: todo.tag.colore.value }}
                    >
                      {todo.tag.nome}
                    </span>
                  </div>
                </div>
                <p className="mt-3 text-[13px]">{todo.descrizione}</p>
              </div>
            ))
          ) : (
            <div>Niente da fare oggi</div>
          )}
          <Link
            className="inline-flex items-center justify-center px-6 py-3 bg-violet-900 hover:bg-violet-700 focus:outline-none rounded-full transition-all ease-in-out duration-300 disabled:bg-violet-300 mt-6 mb-9"
            to="/task"
          >
            <p className="text-xs font-medium leading-none text-white">
              {events.length > 0
                ? "Vai alle cose da fare"
                : "Aggiungi qualcosa da fare"}
            </p>
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

export default Home;
