import { db } from "../firebase";

import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  arrayRemove,
} from "firebase/firestore";

const taskCollectionRef = collection(db, "tasks");

class TaskDataService {
  addTask = (nuovoTask) => {
    return addDoc(taskCollectionRef, nuovoTask);
  };

  getTask = (email) => {
    const q = query(taskCollectionRef, where("admin", "==", email));
    return getDocs(q);
  };

  getOtherTasks = (email) => {
    const q = query(
      taskCollectionRef,
      where("usersEmail", "array-contains", email)
    );
    return getDocs(q);
  };

  addUser = async (newUser, id, user) => {
    if (newUser.email === user.email) {
      throw new Error("Nuon puoi inserire te stesso");
    }
    const taskDoc = await doc(db, "tasks", id);
    const task = await getDoc(taskDoc);
    let exists = [];
    if (task.data().users) {
      exists = task.data().users.filter((obj) => obj.email === newUser.email);
    }
    if (exists.length === 0)
      return updateDoc(taskDoc, {
        users: arrayUnion(newUser),
        usersEmail: arrayUnion(newUser.email),
      });
    throw new Error("Utente già inserito");
  };

  removeUser = async (user, id) => {
    const taskDoc = await doc(db, "tasks", id);
    return updateDoc(taskDoc, {
      users: arrayRemove(user),
      usersEmail: arrayRemove(user.email),
    });
  };
}

export default new TaskDataService();
