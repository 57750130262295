import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const tagsCollectionRef = collection(db, "tags");

class TagsDataService {
  addTag = (nuovoTag) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovoTag.user = userLogged;
    return addDoc(tagsCollectionRef, nuovoTag);
  };

  getTags = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(tagsCollectionRef, where("user", "==", userLogged));
    return getDocs(q);
  };

  deleteTag = (id) => {
    const tagaDoc = doc(db, "tags", id);
    return deleteDoc(tagaDoc);
  };
}

export default new TagsDataService();
