import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    margin: 20,
    fontSize: "9px",
    fontFamily: "Open Sans",
    width: "100%",
  },
  bold: {
    fontWeight: 600,
  },
  upper: {
    textTransform: "uppercase",
  },
  sectionProfilo: {
    flexGrow: 1,
  },
  sectionLogo: {
    justifyContent: "flex-end",
    marginRight: "20px",
  },
  intestazione: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  denominazioneProfilo: {
    fontWeight: 600,
    fontSize: "12px",
  },
  logo: {
    width: "150px",
  },
  docAndClient: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  doc: {},
  client: {
    display: "flex",
    alignItems: "flex-end",
    marginRight: "40px",
    textAlign: "right",
  },
  fatturaText: {
    backgroundColor: "#f6f6f6",
    padding: "7px 65px ",
    textAlign: "center",
    fontWeight: 600,
    textTransform: "uppercase",
    marginBottom: "15px",
  },
  table: {
    width: "93%",
    marginTop: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
    backgroundColor: "#f6f6f6",
  },
  header: {
    borderTop: "none",
    fontWeight: 600,
  },
  row1: {
    width: "10%",
    height: "30px",
    textTransform: "uppercase",
    padding: "10px 5px 0 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row2: {
    width: "40%",
    height: "30px",
    textTransform: "uppercase",
    padding: "10px 5px 0 5px",
    fontSize: "8px",
  },
  row3: {
    width: "15%",
    height: "30px",
    textTransform: "uppercase",
    padding: "10px 5px 0 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row4: {
    width: "15%",
    height: "30px",
    textTransform: "uppercase",
    padding: "10px 5px 0 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row5: {
    width: "10%",
    height: "30px",
    textTransform: "uppercase",
    padding: "10px 5px 0 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row6: {
    width: "10%",
    height: "30px",
    textTransform: "uppercase",
    padding: "10px 5px 0 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row1t: {
    width: "10%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row2t: {
    width: "40%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    whiteSpace: "normal",
  },
  row3t: {
    width: "15%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "right",
  },
  row3tdoppia: {
    width: "15%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "right",
  },
  row4t: {
    width: "15%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "right",
  },
  row4tdoppia: {
    width: "15%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "right",
  },
  row5t: {
    width: "10%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  row6t: {
    width: "10%",
    height: "auto",
    padding: "7px 5px",
    fontSize: "8px",
    textAlign: "center",
  },
  textBarrato: {
    textDecoration: "line-through",
  },
  riepilogo: {
    width: "93%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1px solid #cccccc",
    minHeight: "100px",
    // paddingTop: "20px",
  },
  riepilogoIvaContainer: {
    borderRight: "1px solid #cccccc",
    borderBottom: "1px solid #cccccc",

    width: "50%",
    padding: "20px 0",
  },
  riepilogoIva: {
    display: "flex",
    flexDirection: "row",
    fontSize: "8px",
    justifyContent: "space-between",
    marginBottom: "5px",
    paddingRight: "20px",
  },
  riepilogoTotale: {
    width: "50%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    borderBottom: "1px solid #cccccc",
    paddingBottom: "20px",
  },
});

const SalvaFatturaPDF = ({ fattura, profilo }) => {
  const creaData = (data) => {
    if (data) {
      const d = data.toDate();
      return (
        ("0" + d.getDate()).slice(-2) +
        "-" +
        ("0" + (parseInt(d.getMonth()) + 1)).slice(-2) +
        "-" +
        d.getFullYear()
      );
    }
    return "";
  };

  const componiIndirizzo = (cap, citta, provincia, x) => {
    let result = x === 1 ? " - " : "";
    if (cap) {
      result += cap + " ";
    }
    result += citta;
    if (provincia) {
      result += " (" + provincia + ")";
    }
    return result;
  };

  const calcolaPercentuale = (numero, percentuale) => {
    return parseFloat(numero - (numero * percentuale) / 100);
  };

  const riepilogoIva = () => {
    let res = [];
    if (fattura) {
      const rivalsa = fattura.rivalsa;
      for (const articolo of fattura.articoli) {
        const ivaApplicata = parseFloat(
          articolo.ivaApplicata.value.split("-")[0]
        );
        const exists = res.filter((obj) => obj.value === ivaApplicata);
        let imponibile = parseFloat(
          calcolaPercentuale(
            parseFloat(articolo.prezzo) * articolo.qt,
            parseFloat(articolo.sconto)
          )
        );
        if (rivalsa) {
          imponibile = imponibile + imponibile * 0.04;
        }
        const imposte = parseFloat((imponibile * ivaApplicata) / 100);
        if (exists.length === 0) {
          const iva = {
            value: ivaApplicata,
            imponibile: imponibile,
            imposte: imposte,
          };
          res.push(iva);
        } else {
          const index = res.findIndex((obj) => obj.value === ivaApplicata);
          res[index] = {
            value: ivaApplicata,
            imponibile: res[index].imponibile + imponibile,
            imposte: res[index].imposte + imposte,
          };
        }
      }
    }
    return res;
  };

  const riepilogoIvaSenzaRivalsa = () => {
    let res = [];
    if (fattura) {
      for (const articolo of fattura.articoli) {
        const ivaApplicata = parseFloat(
          articolo.ivaApplicata.value.split("-")[0]
        );
        const exists = res.filter((obj) => obj.value === ivaApplicata);
        let imponibile = parseFloat(
          calcolaPercentuale(
            parseFloat(articolo.prezzo) * articolo.qt,
            parseFloat(articolo.sconto)
          )
        );
        const imposte = parseFloat((imponibile * ivaApplicata) / 100);
        if (exists.length === 0) {
          const iva = {
            value: ivaApplicata,
            imponibile: imponibile,
            imposte: imposte,
          };
          res.push(iva);
        } else {
          const index = res.findIndex((obj) => obj.value === ivaApplicata);
          res[index] = {
            value: ivaApplicata,
            imponibile: res[index].imponibile + imponibile,
            imposte: res[index].imposte + imposte,
          };
        }
      }
    }
    return res;
  };

  const checkMarca = () => {
    let result = false;
    if (fattura) {
      result =
        riepilogoIvaSenzaRivalsa().filter(
          (obj) => obj.value === 0 && obj.imponibile >= 77.47
        ).length > 0;
    }
    return result;
  };

  const calcoloImponibile = () => {
    let imponibile = 0;
    if (fattura) {
      for (const articolo of fattura.articoli) {
        const prezzo = parseFloat(articolo.prezzo);
        const qt = articolo.qt;
        const sconto = parseFloat(articolo.sconto);
        imponibile += parseFloat(
          calcolaPercentuale(parseFloat(prezzo) * qt, parseFloat(sconto))
        );
      }
    }
    return imponibile;
  };

  const calcoloIva = () => {
    let iva = 0;
    if (fattura) {
      const rivalsa = fattura.rivalsa;
      for (const articolo of fattura.articoli) {
        const ivaApplicata = parseFloat(
          articolo.ivaApplicata.value.split("-")[0]
        );
        const prezzo = parseFloat(articolo.prezzo);
        const qt = articolo.qt;
        const sconto = parseFloat(articolo.sconto);
        let imponibile = parseFloat(
          calcolaPercentuale(parseFloat(prezzo) * qt, parseFloat(sconto))
        );
        if (rivalsa) {
          imponibile = imponibile + imponibile * 0.04;
        }
        iva += parseFloat((imponibile * ivaApplicata) / 100);
      }
    }
    return iva;
    // let iva = 0;
    // if (fattura) {
    //   for (const articolo of fattura.articoli) {
    //     const ivaApplicata = parseFloat(
    //       articolo.ivaApplicata.value.split("-")[0]
    //     );
    //     const prezzo = parseFloat(articolo.prezzo);
    //     const qt = articolo.qt;
    //     const sconto = parseFloat(articolo.sconto);
    //     let imponibile = parseFloat(
    //       calcolaPercentuale(parseFloat(prezzo) * qt, parseFloat(sconto))
    //     );
    //     iva += parseFloat((imponibile * ivaApplicata) / 100);
    //   }
    // }
    // return iva.toLocaleString("it-IT", { minimumFractionDigits: 2 });
  };

  const calcoloTotale = () => {
    const rivalsa = fattura.rivalsa;
    let imp = parseFloat(calcoloImponibile(fattura));
    if (rivalsa) {
      imp = imp + imp * 0.04;
    }
    let totale = imp + parseFloat(calcoloIva());
    if (checkMarca() && fattura.caricoCliente) {
      totale += 2;
    }
    return totale;
    // let tot = parseFloat(calcoloImponibile()) + parseFloat(calcoloIva());
    // // if (fattura && fattura.marca) {
    // //   tot += 2;
    // // }
    // return tot.toLocaleString("it-IT", { minimumFractionDigits: 2 });
  };

  const calcolaRivalsa = () => {
    return (
      parseFloat(
        riepilogoIvaSenzaRivalsa().reduce(
          (accum, obj) => accum + obj.imponibile,
          0
        )
      ) * 0.04
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.intestazione}>
          <View style={styles.sectionProfilo}>
            <Text style={styles.denominazioneProfilo}>
              {profilo?.denominazione}
            </Text>
            <Text>
              {profilo?.indirizzo !== "" && profilo?.indirizzo}
              {profilo?.citta !== ""
                ? componiIndirizzo(
                    profilo?.cap,
                    profilo?.citta,
                    profilo?.provincia,
                    1
                  )
                : ""}
            </Text>
            <Text>
              {profilo?.email !== "" ? "Email: " + profilo?.email : ""}
            </Text>
            <Text>
              {profilo?.telefono !== "" ? "Tel. " + profilo?.telefono : ""}
            </Text>
            <Text>{profilo?.piva !== "" ? `P.IVA ${profilo?.piva}` : ""}</Text>
            <Text>
              {profilo?.codfis !== "" ? `C.F. ${profilo?.codfis}` : ""}
            </Text>
            <Text>{profilo?.web !== "" ? profilo?.web : ""}</Text>
          </View>
          {profilo?.logo && profilo?.logo !== "" && (
            <View style={styles.sectionLogo}>
              <Image source={profilo?.logo} style={styles.logo} />
            </View>
          )}
        </View>
        <View style={styles.docAndClient}>
          <View style={styles.doc}>
            <Text style={styles.fatturaText}>Fattura</Text>
            <Text>
              Numero:{" "}
              <Text style={styles.bold}>
                {fattura?.numero}/{fattura?.data.toDate().getFullYear()}
              </Text>
            </Text>
            <Text>
              Data: <Text style={styles.bold}>{creaData(fattura?.data)}</Text>
            </Text>
          </View>
          <View style={styles.client}>
            <Text style={styles.bold}>Destinatario</Text>
            <Text style={[styles.bold, styles.upper]}>
              {fattura?.cliente.denominazione}
            </Text>
            {fattura?.cliente.indirizzo !== "" && (
              <Text style={styles.upper}>{fattura?.cliente.indirizzo}</Text>
            )}
            {fattura?.cliente.citta !== "" && (
              <Text style={styles.upper}>
                {componiIndirizzo(
                  fattura?.cliente.cap,
                  fattura?.cliente.citta,
                  fattura?.cliente.provincia,
                  0
                )}
              </Text>
            )}
            {fattura?.cliente.piva !== "" && (
              <Text style={styles.upper}>P.IVA {fattura?.cliente.piva}</Text>
            )}
            {fattura?.cliente.codfis !== "" && (
              <Text style={styles.upper}>C.F. {fattura?.cliente.codfis}</Text>
            )}
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={styles.row1}>Qt</Text>
            <Text style={styles.row2}>Descrizione</Text>
            <Text style={styles.row5}>Sconto</Text>
            <Text style={styles.row3}>Prezzo unitario</Text>
            <Text style={styles.row4}>Importo</Text>
            <Text style={styles.row6}>% Iva</Text>
          </View>
          {fattura?.articoli.map((row, i) => (
            <View
              key={i}
              style={[
                styles.row,
                i % 2 === 0
                  ? { backgroundColor: "white" }
                  : { backgroundColor: "#f6f6f6" },
              ]}
              wrap={false}
            >
              <Text style={styles.row1t}>{row.qt}</Text>
              <Text style={styles.row2t}>{row.articolo.nome}</Text>
              <Text style={styles.row5t}>
                {parseFloat(row?.sconto) !== 0 ? row?.sconto + "%" : ""}
              </Text>
              {parseFloat(row?.sconto) !== 0 ? (
                <View style={styles.row3tdoppia}>
                  <Text style={styles.textBarrato}>
                    &euro;{" "}
                    {parseFloat(row.prezzo).toLocaleString("it-IT", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  <Text>
                    &euro;{" "}
                    {calcolaPercentuale(
                      parseFloat(row.prezzo),
                      parseFloat(row.sconto)
                    )}
                  </Text>
                </View>
              ) : (
                <Text style={styles.row3t}>
                  &euro;{" "}
                  {parseFloat(row.prezzo).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              )}
              {parseFloat(row?.sconto) !== 0 ? (
                <View style={styles.row4tdoppia}>
                  <Text style={styles.textBarrato}>
                    &euro;{" "}
                    {(parseFloat(row.prezzo) * row.qt)
                      .toFixed(2)
                      .replace(".", ",")}
                  </Text>
                  <Text>
                    &euro;{" "}
                    {calcolaPercentuale(
                      parseFloat(row.prezzo) * row.qt,
                      parseFloat(row.sconto)
                    )}
                  </Text>
                </View>
              ) : (
                <Text style={styles.row4t}>
                  &euro;{" "}
                  {(parseFloat(row.prezzo) * row.qt)
                    .toFixed(2)
                    .replace(".", ",")}
                </Text>
              )}
              <Text style={styles.row6t}>
                {row.ivaApplicata.value.split("-")[0]}
              </Text>
            </View>
          ))}
          {/* {fattura?.marca && (
            <View
              style={[
                styles.row,
                fattura?.articoli.length % 2 === 0
                  ? { backgroundColor: "white" }
                  : { backgroundColor: "#f6f6f6" },
              ]}
              wrap={false}
            >
              <Text style={styles.row1t}>1</Text>
              <Text style={styles.row2t}>Marca da bollo</Text>
              <Text style={styles.row5t}></Text>
              <Text style={styles.row3t}>&euro; 2.00</Text>
              <Text style={styles.row4t}>&euro; 2.00</Text>
            </View>
          )} */}
        </View>
        <View style={styles.riepilogo}>
          <View style={styles.riepilogoIvaContainer}>
            <View style={styles.riepilogoIva}>
              <Text style={{ width: "60%" }}>RIEPILOGO IVA</Text>
              <Text
                style={{ padding: "0 5px", width: "20%", textAlign: "right" }}
              >
                IMPONIBILE
              </Text>
              <Text
                style={{ padding: "0 5px", width: "20%", textAlign: "right" }}
              >
                IMPOSTE
              </Text>
            </View>

            {riepilogoIva().map((row, i) => (
              <View style={styles.riepilogoIva} key={i}>
                <Text style={{ width: "60%" }}>{row.value}%</Text>
                <Text
                  style={{
                    padding: "0 5px",
                    textAlign: "right",
                    width: "20%",
                  }}
                >
                  &euro;{" "}
                  {row.imponibile.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
                <Text
                  style={{ padding: "0 5px", textAlign: "right", width: "20%" }}
                >
                  &euro;{" "}
                  {row.imposte.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.riepilogoTotale}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "col",
                  alignItems: "flex-end",
                  flexGrow: "1",
                  paddingRight: "10px",
                }}
              >
                <Text>Imponibile</Text>
                {fattura.rivalsa && <Text>Rivalsa INPS (4%)</Text>}
                <Text>Iva</Text>
                {checkMarca() && fattura.caricoCliente && (
                  <Text>Spese esenti IVA art. 15</Text>
                )}
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "col",
                  alignItems: "flex-end",
                  paddingLeft: "10px",
                }}
              >
                <Text>
                  &euro;{" "}
                  {calcoloImponibile().toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
                {fattura.rivalsa && (
                  <Text>
                    &euro;{" "}
                    {calcolaRivalsa().toLocaleString("it-IT", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                )}
                <Text>
                  &euro;{" "}
                  {calcoloIva().toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
                {checkMarca() && fattura.caricoCliente && (
                  <Text>&euro; 2,00</Text>
                )}
              </View>
            </View>
            <Text
              style={{ fontWeight: 600, fontSize: "14px", marginTop: "5px" }}
            >
              &euro;{" "}
              {calcoloTotale().toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>
        </View>
        {checkMarca() && fattura.marca && !fattura.caricoCliente && (
          <View
            style={{
              margin: "15px 0",
              textAlign: "right",
              width: "93%",
            }}
          >
            <Text style={{ fontSize: "6px" }}>
              {" "}
              Imposta di bollo da 2 euro assolta sull'originale per importi
              maggiori di 77,47 €
            </Text>
          </View>
        )}
        {fattura?.pagamento !== "" && (
          <View
            style={{
              padding: "20px 0",
              borderBottom: "1px solid #cccccc",
              width: "93%",
            }}
          >
            <Text style={{ fontWeight: 600 }}>Modalità di pagamento</Text>
            {fattura?.pagamento.split("\n").map((item, i) => (
              <Text key={i}>{item}</Text>
            ))}
          </View>
        )}
        {fattura?.note !== "" && (
          <View
            style={{ paddingTop: "30px", textAlign: "center", width: "93%" }}
          >
            {fattura?.note.split("\n").map((item, i) => (
              <Text key={i}>{item}</Text>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );
};

export default SalvaFatturaPDF;
