import React, { useState, useEffect } from "react";

import {
  BiChevronLeft,
  BiChevronRight,
  BiTime,
  BiTrashAlt,
  BiPlus,
  BiEdit,
} from "react-icons/bi";

import EventsDataService from "../services/events.services";
import TipologieDataService from "../services/tipologie.services";
import TipologiaForm from "./../components/events/TipologiaForm";
import EventsForm from "./../components/events/EventsForm";

import alertOptions from "../components/alert/alertOptions";
import { Store } from "react-notifications-component";
import { confirmAlert } from "react-confirm-alert";

import AnimateHeight from "react-animate-height";

import { useEvents } from "../context/EventsContext";
import Loading from "../components/loading/Loading";

const daysOfWeek = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];
const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];
const today = new Date();

const daysInView = 42;

const AgendaCopy = () => {
  const [currentMonth, setCurrentMonth] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const [dateSelected, setDateSelected] = useState(today);

  const [events, setEvents] = useState([]);

  const [daysCell, setDaysCell] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const [formOpened, setFormOpened] = useState(false);
  const [tipologie, setTipologie] = useState([]);
  const [formTipologiaOpened, setFormTipologiaOpened] = useState(false);
  const [addedTipologia, setAddedTipologia] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { fetchEventsC } = useEvents();

  useEffect(() => {
    getTipologie();
    componiMese();
    const adjustHeight = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      const calendarBody = document.getElementById("calendar-body");
      const width = calendarBody.offsetWidth;
      const cells = document.getElementsByClassName("cell");
      for (const cell of cells) {
        cell.style.minHeight = `${width / 12}px`;
      }
    };
    window.addEventListener("resize", adjustHeight);
    setTimeout(() => {
      adjustHeight();
    }, 100);
    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, []);

  useEffect(() => {
    if (start && end) {
      fetchEvents();
    }
  }, [end]);

  useEffect(() => {
    componiMese();
  }, [currentMonth]);

  const getTipologie = async () => {
    const data = await TipologieDataService.getTipologie();
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setTipologie(docs);
  };

  const toggleTipologiaForm = (state) => {
    setFormTipologiaOpened(state);
    if (!state) {
      setAddedTipologia(null);
    }
  };

  const toggleForm = (state) => {
    setFormOpened(state);
    if (state) {
      window.scrollTo(0, 0);
    } else {
      setSelectedEvent(null);
    }
  };

  const selectEvent = (event, e) => {
    e.stopPropagation();
    if (formOpened) return;
    setSelectedEvent(event);
    toggleForm(true);
  };

  const addTipologia = async (tipologia) => {
    try {
      await TipologieDataService.addTipologia(tipologia);
      getTipologie();
      setAddedTipologia(tipologia);
    } catch (e) {
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const deleteTipologia = (id) => {
    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await TipologieDataService.deleteTipologia(id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              getTipologie();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  const componiMese = () => {
    setEvents([]);
    const result = [];
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const dayOfWeek =
      firstDayOfMonth.getDay() === 0 ? 7 : firstDayOfMonth.getDay();

    for (let i = 0; i < daysInView; i++) {
      const firstDay = new Date(firstDayOfMonth);
      if (i < dayOfWeek - 1) {
        firstDay.setDate(firstDay.getDate() - (dayOfWeek - 1 - i));
      } else if (i > dayOfWeek - 1) {
        firstDay.setDate(firstDay.getDate() + (i - dayOfWeek + 1));
      }

      const day = {
        date: firstDay,
        inMonth: firstDay.getMonth() !== currentMonth.getMonth() ? false : true,
      };
      result.push(day);
    }
    setStart(result[0].date);
    setEnd(result[daysInView - 1].date);
    setDaysCell(result);
  };

  const fetchEvents = async () => {
    setIsLoading(true);
    const data = await EventsDataService.fetchEvents(start, end);
    const docs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    docs.sort((a, b) =>
      a.data.toDate() > b.data.toDate()
        ? 1
        : b.data.toDate() > a.data.toDate()
        ? -1
        : 0
    );
    setEvents(docs);
    fetchEventsC(docs.filter((obj) => sameDate(obj.data.toDate(), today)));
    setIsLoading(false);
  };

  const goToday = () => {
    setCurrentMonth(new Date(today.getFullYear(), today.getMonth(), 1));
  };

  const nextMonth = () => {
    const date = new Date(currentMonth);
    date.setMonth(date.getMonth() + 1, date.getDate());
    setCurrentMonth(date);
  };

  const prevMonth = () => {
    const date = new Date(currentMonth);
    date.setMonth(date.getMonth() - 1, date.getDate());
    setCurrentMonth(date);
  };

  const sameDate = (d1, d2) => {
    return (
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate() &&
      d1.getFullYear() === d2.getFullYear()
    );
  };

  const eventsInDay = (day) => {
    return events.filter((ev) => sameDate(ev.data.toDate(), day));
  };

  const renderTime = (date) => {
    return `${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
  };

  const renderDate = (date) => {
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  const dayClick = (date) => {
    if (formOpened) return;
    setDateSelected(date);
    if (!isMobile) {
      toggleForm(true);
    }
  };

  const addEvent = async (event) => {
    try {
      if (selectedEvent) {
        await EventsDataService.updateEvent(event.id, event);
      } else {
        await EventsDataService.addEvent(event);
      }
      fetchEvents();
      toggleForm(false);
      Store.addNotification({
        ...alertOptions,
        message: "Operazione eseguita con successo!",
        type: "success",
      });
    } catch (e) {
      console.log(e);
      Store.addNotification({
        ...alertOptions,
        message: "Impossibile completare l'operazione",
        type: "danger",
      });
    }
  };

  const deleteEvent = async (event, e) => {
    e.stopPropagation();
    if (formOpened) return;

    confirmAlert({
      title: "Sei sicuro?",
      message: "Vuoi davvero elimiare l'elemento?",
      buttons: [
        {
          label: "Elimina",
          onClick: async () => {
            try {
              await EventsDataService.deleteEvent(event.id);
              Store.addNotification({
                ...alertOptions,
                message: "Operazione effettuata",
                type: "success",
              });
              fetchEvents();
            } catch (err) {
              Store.addNotification({
                ...alertOptions,
                message: "Impossibile completare l'operazione",
                type: "danger",
              });
            }
          },
        },
        {
          label: "Annulla",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      {formTipologiaOpened && (
        <div className="bg-white/80 fixed top-0 left-0 w-full h-full z-50 flex items-start justify-center">
          <TipologiaForm
            toggleTipologiaForm={toggleTipologiaForm}
            addTipologia={addTipologia}
          />
        </div>
      )}
      <AnimateHeight duration={350} height={formOpened ? "auto" : 0}>
        <EventsForm
          toggleForm={toggleForm}
          toggleTipologiaForm={toggleTipologiaForm}
          tipologie={tipologie}
          addEvent={addEvent}
          dateSelected={dateSelected}
          deleteTipologia={deleteTipologia}
          addedTipologia={addedTipologia}
          selectedEvent={selectedEvent}
        />
      </AnimateHeight>
      <div className="w-full">
        <div className="py-4 md:py-7 rounded-tl-lg rounded-tr-lg">
          <div className="flex items-center justify-between">
            <p className="text-base sm:text-md md:text-lg lg:text-xl font-bold leading-normal text-gray-800">
              Agenda
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-4 md:px-10 pt-4 md:pt-7 pb-9 overflow-y-auto ">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl text-gray-800">
            {months[currentMonth.getMonth()]} {currentMonth.getFullYear()}
          </div>
          <div className="flex">
            <button
              onClick={goToday}
              className="bg-violet-900 hover:bg-violet-800 text-white py-[0.35rem] px-[0.8rem] rounded-[4px] mr-2"
            >
              Oggi
            </button>
            <button
              onClick={prevMonth}
              className="bg-slate-200 flex items-center justify-center p-1 px-[0.35rem] hover:bg-slate-300"
              style={{
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
              }}
            >
              <BiChevronLeft className="text-2xl" />
            </button>
            <button
              onClick={nextMonth}
              className="bg-slate-200 flex items-center justify-center p-1 px-2 hover:bg-slate-300"
              style={{
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <BiChevronRight className="text-2xl" />
            </button>
          </div>
        </div>
        <div className="calendar-header  grid grid-cols-7 w-full bg-slate-200  gap-[1px] px-[1px] pt-[1px]">
          {daysOfWeek.map((day, i) => (
            <div
              key={i}
              className="bg-white flex items-center justify-center py-1 grow text-xs"
            >
              {day}
            </div>
          ))}
        </div>
        <div
          id="calendar-body"
          className="calendar-body grid grid-cols-7 gap-[1px] bg-slate-200 p-[1px] relative"
          style={{ gridAutoRows: "minmax(min-content, max-content)" }}
        >
          {isLoading && <Loading />}
          {daysCell.length > 0 &&
            daysCell.map((obj, i) => (
              <div
                className={`cell relative bg-white ${
                  isMobile && sameDate(obj.date, dateSelected)
                    ? "bg-orange-100"
                    : ""
                } px-2  py-2 lg:py-3 lg:px-1 lg:pt-9 flex items-center flex-col justify-center cursor-pointer`}
                key={i}
                onClick={() => dayClick(obj.date)}
              >
                <div
                  className={`relative lg:absolute top-auto right-auto lg:top-1  lg:right-1 text-xs flex items-center justify-center rounded-[30px] p-2 w-[30px] h-[30px] ${
                    !obj.inMonth ? "text-slate-300" : "text-gray-800 "
                  } ${
                    sameDate(obj.date, today) ? "bg-violet-900 text-white" : ""
                  }`}
                >
                  {obj.date.getDate()}
                </div>
                {!isMobile
                  ? eventsInDay(obj.date).map((ev, i) => (
                      <div
                        key={i}
                        style={{
                          backgroundColor: ev.tipologia.colore.value,
                        }}
                        onClick={(e) => selectEvent(ev, e)}
                        className="w-full rounded p-1 text-white text-xs relative cursor-pointer mb-1"
                      >
                        <div className="event-title">{ev.tipologia.nome}</div>
                        <div className="event-descrizione whitespace-normal leading-normal text-[10px] mt-1">
                          {ev.descrizione}
                        </div>
                        <div className="event-hour mt-2 flex items-center">
                          <BiTime className="mr-1" />
                          <div>{renderTime(ev.data.toDate())}</div>
                        </div>
                        <div className="absolute bottom-0 right-0 flex items-center">
                          {/* <button
                            className="p-[7px]"
                            onClick={() => selectEvent(ev)}
                          >
                            <BiEdit />
                          </button> */}
                          <button
                            className="p-[7px]"
                            onClick={(e) => deleteEvent(ev, e)}
                          >
                            <BiTrashAlt />
                          </button>
                        </div>
                      </div>
                    ))
                  : eventsInDay(obj.date).length > 0 && (
                      <div className="absolute w-[8px] h-[8px] bg-orange-500 bottom-1 right-1 rounded-full"></div>
                    )}
              </div>
            ))}
        </div>
      </div>
      {isMobile && (
        <div className="mt-4">
          <div className="flex items-center justify-between">
            <div className="list-title font-semibold">
              Appuntamenti{" "}
              {dateSelected.getDate() === 1 ||
              dateSelected.getDate() === 8 ||
              dateSelected.getDate() === 11
                ? "dell'"
                : "del"}{" "}
              {renderDate(dateSelected)}
            </div>
            <button
              className="p-2 text-white text-xl bg-violet-900 hover:bg-violet-800 rounded-full"
              onClick={() => toggleForm(true)}
            >
              <BiPlus />
            </button>
          </div>

          <div className="mt-3">
            {eventsInDay(dateSelected).length > 0 ? (
              eventsInDay(dateSelected).map((ev, i) => (
                <div
                  key={i}
                  className="bg-white shadow-lg shadow-violet-800/20 rounded-md px-2 py-1 overflow-y-auto mb-2 text-[13px] cursor-pointer"
                  onClick={(e) => selectEvent(ev, e)}
                >
                  <div className="flex items-center">
                    <div
                      className="min-h-[8px] min-w-[8px] rounded-full"
                      style={{
                        backgroundColor: ev.tipologia.colore.value,
                      }}
                    ></div>
                    <div className="py-1 px-3 border-r border-slate-400 min-w-[58px]">
                      {renderTime(ev.data.toDate())}
                    </div>
                    <div className="flex flex-col justify-center py-1 px-3 pr-8 grow">
                      <div className="font-semibold">{ev.tipologia.nome}</div>
                      <div className="leading-normal whitespace-normal">
                        {ev.descrizione}
                      </div>
                    </div>
                    <div className="group ">
                      <button
                        onClick={(e) => deleteEvent(ev, e)}
                        className="p-1 mx-1 mr-0 group-hover:bg-red-600 disabled:opacity-25 rounded-full transition-all ease-in-out duration-300"
                      >
                        <BiTrashAlt className="text-lg text-red-600 group-hover:text-white transition-all ease-in-out duration-300" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-xs">Nessun evento in programma</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgendaCopy;
