import { createContext, useState, useContext } from "react";

const yearContext = createContext();

export function YearContextProvider({ children }) {
  const now = new Date().getFullYear();
  const [year, setYear] = useState({ value: now, name: now });

  function changeYear(year) {
    setYear(year);
  }

  return (
    <yearContext.Provider value={{ year, changeYear }}>
      {children}
    </yearContext.Provider>
  );
}

export function useYear() {
  return useContext(yearContext);
}
