import { db } from "../firebase";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const tipologieCollectionRef = collection(db, "tipologie");

class TipologieDataService {
  addTipologia = (nuovaTipologia) => {
    const userLogged = localStorage.getItem("userLogged");
    nuovaTipologia.user = userLogged;
    return addDoc(tipologieCollectionRef, nuovaTipologia);
  };

  getTipologie = () => {
    const userLogged = localStorage.getItem("userLogged");
    const q = query(tipologieCollectionRef, where("user", "==", userLogged));
    return getDocs(q);
  };

  deleteTipologia = (id) => {
    const tipologiaDoc = doc(db, "tipologie", id);
    return deleteDoc(tipologiaDoc);
  };
}

export default new TipologieDataService();
